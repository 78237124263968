import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';

import 'src/styles/filters.css';

type CalendarFilterProps = {
    sqlFields: string[];
    onFilterChange: (updates: [string, string[]][]) => void;
    values: { [key: string]: string[] };
};

function CalendarFilter({ sqlFields, onFilterChange, values }: CalendarFilterProps): JSX.Element {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const [isLoading, setIsLoading] = useState(true);

    const dateFrom = values[sqlFields[0]] ? values[sqlFields[0]][0] : null;
    const dateTo = values[sqlFields[1]] ? values[sqlFields[1]][0] : null;

    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);

    useEffect(() => {
        setIsLoading(false);
    }, []);

    useEffect(() => {
        setStartDate(dateFrom ? dayjs(dateFrom) : null);
        setEndDate(dateTo ? dayjs(dateTo) : null);
    }, [values, sqlFields]);

    const handleStartDateChange = (date: Dayjs | null) => {
        setStartDate(date);
        if (date && date.isValid()) { // Check if date is valid
            onFilterChange([[sqlFields[0], [date.format('YYYY-MM-DD')]]]);
        }
    };

    const handleEndDateChange = (date: Dayjs | null) => {
        setEndDate(date);
        if (date && date.isValid()) { // Check if date is valid
            onFilterChange([[sqlFields[1], [date.format('YYYY-MM-DD')]]]);
        }
    };

    return (
        <div>
            {!isLoading && (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLanguage}>
                    <DatePicker 
                        className='date-picker'
                        label={t('elements.tablefetch.filter-labels.start-date')} 
                        value={startDate}
                        reduceAnimations={true}
                        onChange={handleStartDateChange}
                    />
                    <DatePicker 
                        className='date-picker'
                        label={t('elements.tablefetch.filter-labels.end-date')} 
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </LocalizationProvider>
            )}
        </div>
    );
}

export default CalendarFilter;
