// UserMenu.tsx
import React, { useState, useEffect } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import StyleIcon from '@mui/icons-material/EmojiSymbols';
import AccountTree from '@mui/icons-material/AccountTree';
import EditLocation from '@mui/icons-material/EditLocation';
import CalendarViewWeekIcon from '@mui/icons-material/CalendarViewWeek';
import Groups2Icon from '@mui/icons-material/Groups2';
import PersonIcon from '@mui/icons-material/Person';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { isStaff, isStaffValueSet } from 'src/utils/tokenManagement';
import { useUser } from 'src/utils/UserContext';

import 'src/styles/navbar.css';

interface UserMenuProps {
  anchorEl: HTMLElement | null;
  isMenuOpen: boolean;
  handleMenuClose: () => void;
  handleLogoutClick: () => void;
}

const UserMenu: React.FC<UserMenuProps> = ({
  anchorEl,
  isMenuOpen,
  handleMenuClose,
  handleLogoutClick,
}) => {
  const { t } = useTranslation();
  const { userName } = useUser();

  const staffStatus = isStaffValueSet();

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id="primary-account-menu"
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
        <MenuItem component={Link} to="/user_profile" onClick={handleMenuClose}>
          <ListItemIcon>
            <PersonIcon fontSize="small" className='icon'/>
          </ListItemIcon>
          <ListItemText>
          {t('elements.navbar.user-profile')}{' '}
          <Typography component="span" style={{ fontWeight: 'bold' }}>
            {userName}
          </Typography>
        </ListItemText>
        </MenuItem>
        {staffStatus && (
            <div>
              <Divider variant="middle" />
              <MenuItem component={Link} to="/styles" onClick={handleMenuClose}>
                  <ListItemIcon>
                      <StyleIcon fontSize="small" className='icon'/>
                  </ListItemIcon>
                  <ListItemText primary={t('elements.navbar.styles')} />
              </MenuItem>
              <MenuItem component={Link} to="/styles_groups" onClick={handleMenuClose}>
                  <ListItemIcon>
                      <AccountTree fontSize="small" className='icon'/>
                  </ListItemIcon>
                  <ListItemText primary={t('elements.navbar.styles-groups')} />
              </MenuItem>
              <MenuItem component={Link} to="/venue_management" onClick={handleMenuClose}>
                  <ListItemIcon>
                      <EditLocation fontSize="small" className='icon'/>
                  </ListItemIcon>
                  <ListItemText primary={t('elements.navbar.venues')} />
              </MenuItem>
              <MenuItem component={Link} to="/action_management" onClick={handleMenuClose}>
                  <ListItemIcon>
                      <CalendarViewWeekIcon fontSize="small" className='icon'/>
                  </ListItemIcon>
                  <ListItemText primary={t('elements.navbar.actions-admin')} /> 
              </MenuItem>
              <MenuItem component={Link} to="/band_management" onClick={handleMenuClose}>
                  <ListItemIcon>
                      <Groups2Icon fontSize="small" className='icon'/>
                  </ListItemIcon>
                  <ListItemText primary={t('elements.navbar.bands-admin')} /> 
              </MenuItem>
            </div>
        )}
        <Divider variant="middle" />
        <MenuItem onClick={handleLogoutClick}>
            <ListItemIcon>
                <LogoutIcon fontSize="small" className='icon'/>
            </ListItemIcon>
            <ListItemText primary={t('elements.navbar.logout')} />
      </MenuItem>
    </Menu>
  );
};

export default UserMenu;
