import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { TextField, Container, Typography, Button, Grid } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import ConfirmDialog from './page_elements/ConfirmDialog';

import { axiosInstance } from 'src/utils/tokenManagement';

import { useAuth } from 'src/utils/useAuth'; // Import the useAuth hook

import { isValidTime } from 'src/utils/dates';

import 'src/styles/form.css';
import { use } from 'i18next';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export type DropdownEntry = {
    id: string;
    displayName: string;
};

type BandObject = {
    id_kapela: number;
    id_stat: number;
    id_styl: number;
    id_okres: number | null;
    lok_kapela: string | null;
    zkr_kapela: string;
    naz_kapela: string;
    naz_kapela2: string | null;
    vznik_kapela: number | null;
    cd_kapela: number | null;
    poc_kapela: number | null;
    pop_kapela: string | null;
    tel_kapela: string | null;
    email_kapela: string | null;
    web_kapela: string | null;
    web_kapela2: string | null;
    sloz_kapela: string | null;
    akt_kapela: number;
    obs_kapela: number;
};

const initialState: BandObject = {
    id_kapela: 0,
    id_stat: 0,
    id_styl: 0,
    id_okres: 0,
    lok_kapela: '',
    zkr_kapela: '',
    naz_kapela: '',
    naz_kapela2: '',
    vznik_kapela: null,
    cd_kapela: 0,
    poc_kapela: 0,
    pop_kapela: '',
    tel_kapela: null,
    email_kapela: '',
    web_kapela: '',
    web_kapela2: '',
    sloz_kapela: '',
    akt_kapela: 1,
    obs_kapela: 0
};

type ErrorState = {
    id_stat: string;
    id_styl: string;
    id_okres: string;
    zkr_kapela: string;
    naz_kapela: string;
    naz_kapela2: string;
    vznik_kapela: string;
    cd_kapela: string;
    poc_kapela: string;
    pop_kapela: string;
    tel_kapela: string;
    email_kapela: string;
    web_kapela: string;
    web_kapela2: string;
    sloz_kapela: string;
    global: string;
    non_field_errors: string;
};

const initialErrorState = {
    id_stat: '',
    id_styl: '',
    id_okres: '',
    zkr_kapela: '',
    naz_kapela: '',
    naz_kapela2: '',
    vznik_kapela: '',
    cd_kapela: '',
    poc_kapela: '',
    pop_kapela: '',
    tel_kapela: '',
    email_kapela: '',
    web_kapela: '',
    web_kapela2: '',
    sloz_kapela: '',
    global: '',
    non_field_errors: ''
};

type countryEntry = {
    state_id: string;
    state_name: string;
};

type styleEntry = {
    style_id: string;
    style_name: string;
};

type bandEntry = {
    id_kapela: string;
    naz_kapela: string;
    state_code: string;
};

type country = {
    id: string;
    displayName: string;
};

type district = {
    id: string;
    displayName: string;
};

type style = {
    id: string;
    displayName: string;
};

function isInitialState(currentState: BandObject): boolean {
    return Object.keys(initialState).every((key): key is keyof BandObject => {
        return initialState[key as keyof BandObject] === currentState[key as keyof BandObject];
    });
}

function BandManagementContent(): JSX.Element {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);

    const [selectedBand, setSelectedBand] = useState<BandObject | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { isLoggedIn, error, login, logout } = useAuth();
    const [selectedDropdownEntry, setSelectedDropdownEntry] = useState<DropdownEntry | null>(null);
    const [selectedCountry, setSelectedCountry] = useState<country | null>(null);
    const [selectedStyle, setSelectedStyle] = useState<style | null>(null);

    const [isSaveSuccessDialogOpen, setIsSaveSuccessDialogOpen] = useState(false);
    const [isSaveErrorDialogOpen, setIsSaveErrorDialogOpen] = useState(false);
    const [saveDialogMessage, setSaveDialogMessage] = useState('');

    const [isModifyConfirmDialogOpen, setIsModifyConfirmDialogOpen] = useState(false);
    const [isCreateConfirmDialogOpen, setIsCreateConfirmDialogOpen] = useState(false);

    const [countries, setCountries] = useState<country[]>([]);
    const [styles, setStyles] = useState<style[]>([]);

    const [bandsLoading, setBandsLoading] = useState(false);
    const [bandSearchValue, setBandSearchValue] = React.useState('');

    const [formErrors, setFormErrors] = useState(initialErrorState);

    const queryParams = new URLSearchParams(window.location.search);
    const openedFrom = queryParams.get('createNewBand');
    const bandNameSuggestion = queryParams.get('bandName');

    const [isOpenedFromAnotherPage, setIsOpenedFromAnotherPage] = useState(typeof window.opener !== 'undefined' && window.opener !== null && !window.opener.closed && openedFrom !== null);

    useEffect(() => {
        if (isOpenedFromAnotherPage && bandNameSuggestion) {
            // set naz_kapela to capitalized bandNameSuggestion
            formValues.naz_kapela = bandNameSuggestion.charAt(0).toUpperCase() + bandNameSuggestion.slice(1);
        }
    }, [isOpenedFromAnotherPage]);

    function fetchCountries() {
        const fetchCountries = () => {
            fetch(`${serverUrl}states/`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch venue type data from the server');
                    }
                    return response.json();
                })
                .then((data) => {
                    const countriesData = data.map((entry: countryEntry) => {
                        return {
                            id: entry.state_id,
                            displayName: entry.state_name
                        };
                    });
                    setCountries(countriesData);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        fetchCountries();
    }

    function fetchStyles() {
        const fetchStyles = () => {
            fetch(`${serverUrl}styles/`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch location data from the server');
                    }
                    return response.json();
                })
                .then((data) => {
                    const stylesData = data.map((entry: styleEntry) => {
                        return {
                            id: entry.style_id,
                            displayName: entry.style_name
                        };
                    });
                    setStyles(stylesData);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        fetchStyles();
    }

    const fetchBands = (bandSearchValue: string) => {
        const fetchBands = () => {
            const encodedSearchValue = encodeURIComponent(bandSearchValue);
            fetch(`${serverUrl}bands/search/${encodedSearchValue}/`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch bands data from the server');
                    }
                    return response.json();
                })
                .then((data) => {
                    const transformedEntries = data.map((entry: bandEntry) => {
                        return {
                            id: entry.id_kapela,
                            displayName: entry.naz_kapela + ' (' + entry.state_code + ')'
                        };
                    });

                    setDropdownEntries(transformedEntries);
                    fetchCountries();
                    fetchStyles();

                    if (selectedBand) {
                        setSelectedBand(selectedBand);
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 401) {
                        logout();
                    }
                    console.log(error);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        };
        if (bandSearchValue.length >= 3) fetchBands();

    };

    useEffect(() => {
        fetchCountries();
        fetchStyles();
    }, []);

    useEffect(() => {
        fetchBands(bandSearchValue);
    }, [bandSearchValue]);

    const fetchBandById = (bandId: string) => {
        axiosInstance.get(`${serverUrl}band/${bandId}/`)
            .then((response) => {
                setSelectedBand(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching style details:', error);
                }
            });
    };

    const handleBandAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownEntry | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry>
    ) => {
        if (reason === 'clear') {
            setSelectedDropdownEntry(null);
        } else if (value) {
            setSelectedDropdownEntry(value);
            fetchBandById(value.id);
        }
    };

    const handleCountryAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: country | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<country>
    ) => {
        if (reason === 'clear') {
            setSelectedCountry(null);
            setFormValues({
                ...formValues,
                id_stat: 0
            });
        } else if (value) {
            setSelectedCountry(value);
            setFormValues({
                ...formValues,
                id_stat: Number(value.id)
            });
        }
    };

    const handleStyleAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: style | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<style>
    ) => {
        if (reason === 'clear') {
            setSelectedStyle(null);
            setFormValues({
                ...formValues,
                id_styl: 0
            });
        } else if (value) {
            setSelectedStyle(value);
            setFormValues({
                ...formValues,
                id_styl: Number(value.id)
            });
        }
    };

    const [formValues, setFormValues] = useState(initialState);

    const clearForm = () => {
        setFormValues({ ...initialState });
        setFormErrors({ ...initialErrorState });
        setSelectedDropdownEntry(null);
        setSelectedBand(null);
        setSelectedCountry(null);
        setSelectedStyle(null);
    };

    const validateForm = () => {
        // Assume all is initially valid
        const errors = { ...initialErrorState };
        let isValid = true;

        isValid = true;
        
        if (!formValues.naz_kapela) {
            errors.naz_kapela = t('elements.bands-admin.errors.name-required');
            isValid = false;
        }
        
        if (formValues.naz_kapela.length > 100) {
            errors.naz_kapela = t('elements.bands-admin.errors.name-too-long');
            isValid = false;
        }

        if (!formValues.zkr_kapela) {
            errors.zkr_kapela = t('elements.bands-admin.errors.abbreviation-required');
            isValid = false;
        }

        if (formValues.zkr_kapela.length > 100) {
            errors.zkr_kapela = t('elements.bands-admin.errors.abbreviation-too-long');
            isValid = false;
        }

        if (!formValues.id_styl) {
            errors.id_styl = t('elements.bands-admin.errors.style-required');
            isValid = false;
        }

        if (!formValues.id_stat) {
            errors.id_stat = t('elements.bands-admin.errors.country-required');
            isValid = false;
        }

        setFormErrors(errors);

        return isValid;
    };

    const saveRecord = (newRecord: boolean, saveMessage: string) => {
        if (!validateForm()) {
            return;
        }

        formValues.akt_kapela = formValues.akt_kapela ? 1 : 0;
        formValues.obs_kapela = formValues.obs_kapela ? 1 : 0;
        formValues.tel_kapela = formValues.tel_kapela === '' ? null : formValues.tel_kapela;

        const endpoint = newRecord ? 'band/new/' : `band/modify/${formValues.id_kapela}/`;
        const method = newRecord ? 'post' : 'put';
        const errors = { ...initialErrorState };

        axiosInstance[method](`${serverUrl}${endpoint}`, formValues)
        .then(response => {
            // Handle successful submission (e.g., display a message or redirect)
            // Reset errors on success
            const savedRecord = response.data;
            setIsSaveSuccessDialogOpen(true); // Open the dialog on successful save
            setSaveDialogMessage(saveMessage);
            setFormErrors(initialErrorState);
            setSelectedBand(savedRecord);
        })
        .catch(error => {
            if (error.response && error.response.status === 409) {
                // Handle the specific case for 409 Conflict
                // For example, set a custom error message
                errors.naz_kapela = t('elements.venues-admin.errors.unique-name');
                errors.naz_kapela2 = t('elements.venues-admin.errors.unique-name');
                errors.zkr_kapela = t('elements.venues-admin.errors.unique-name');
            } else {
                // Set global error message from response if available, otherwise use a default error message
                const fieldErrors = error.response?.data || {};
                for (const field in fieldErrors) {
                    if (Object.prototype.hasOwnProperty.call(fieldErrors, field)) {
                        // Use a type assertion to tell TypeScript that field is a key of ErrorState
                        const key = field as keyof ErrorState;
                        errors[key] = fieldErrors[field][0];
                    }
                }
                errors.global = errors.non_field_errors;
                if(errors === initialErrorState) {
                    errors.global = t('elements.forms.save-error-title');
                }
            }
            setFormErrors(errors);
            setIsSaveErrorDialogOpen(true); // Open the dialog on save error
        });
    };

    // Handle form submission/creation of a new record
    const handleSubmit = () => {

        formValues.id_kapela = 0; // Delete the band ID to create a new record

        saveRecord(true, t('elements.forms.save-success-title'));
        setIsCreateConfirmDialogOpen(false);
    };

    const handleModify = () => {

        if (!selectedBand) {
            return;
        }

        saveRecord(false, t('elements.forms.modify-success-title'));
        setIsModifyConfirmDialogOpen(false);
    };

    // Handle form field changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
    
        // Assuming formValues.id_stat and formValues.id_styl are set elsewhere optimally
        const updatedValue = type === 'checkbox' ? checked :
                             type === 'number' ? value === '' ? null : Number(value) :
                             value;
    
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            [name]: updatedValue
        }));
    };

    const closeSaveSuccessDialog = () => {
        setIsSaveSuccessDialogOpen(false);
        if(isOpenedFromAnotherPage) {
            const band = selectedBand;
            const message = { type: 'band-saved', band: band };

            window.close();
            window.opener.postMessage(message, '*');
        }
    };

    useEffect(() => {
        if (selectedBand) {
            // Populate form fields with selected style data
            setFormValues({
                id_kapela: selectedBand.id_kapela || 0,
                id_stat: selectedBand.id_stat || 0,
                id_styl: selectedBand.id_styl || 0,
                id_okres: selectedBand.id_okres || 0,
                lok_kapela: selectedBand.lok_kapela || '',
                zkr_kapela: selectedBand.zkr_kapela || '',
                naz_kapela: selectedBand.naz_kapela || '',
                naz_kapela2: selectedBand.naz_kapela2 || '',
                vznik_kapela: selectedBand.vznik_kapela || null,
                cd_kapela: selectedBand.cd_kapela || 0,
                poc_kapela: selectedBand.poc_kapela || 0,
                pop_kapela: selectedBand.pop_kapela || '',
                tel_kapela: selectedBand.tel_kapela || '',
                email_kapela: selectedBand.email_kapela || '',
                web_kapela: selectedBand.web_kapela || '',
                web_kapela2: selectedBand.web_kapela2 || '',
                sloz_kapela: selectedBand.sloz_kapela || '',
                akt_kapela: selectedBand.akt_kapela || 1,
                obs_kapela: selectedBand.obs_kapela || 0
            });
            setSelectedCountry(countries.find(entry => String(entry.id) === selectedBand.id_stat.toString()) || null);
            setSelectedStyle(styles.find(entry => String(entry.id) === selectedBand.id_styl.toString()) || null);
        } else {
            // Reset form fields for creating a new style
            setFormValues(initialState);
        }
    }, [selectedBand]);

    return (
        <Container className="centered">
            <Container className="column-down">
                <Typography variant="h6" className="centered">
                    {t('elements.bands-admin.headline')}
                </Typography>
                <Grid container direction="column">
                    {!isOpenedFromAnotherPage &&
                        <Autocomplete
                            className="dropdown"
                            loading={bandsLoading}
                            size="small"
                            options={dropdownEntries}
                            autoComplete
                            includeInputInList
                            getOptionLabel={(option) => option.displayName}
                            value={selectedDropdownEntry}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onClose={() => {
                                setBandsLoading(false);
                                setDropdownEntries([]);
                            }}
                            onInputChange={(event, newBandSearchValue) => {
                                setBandSearchValue(newBandSearchValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder={t('elements.bands-admin.choose-band') + ' (' + t('elements.forms.type') + ')'}
                                    size="small"
                                    variant="outlined"
                                />
                            )}
                            renderOption={(props, option) => {
                                return (
                                    <li {...props} key={option.id}>
                                        <Typography variant="body1">{option.displayName}</Typography>
                                    </li>
                                );
                            }}
                            noOptionsText={t('elements.bands-admin.no-options')}
                            onChange={handleBandAutocompleteChange}
                        />
                    }
                    <Container className="centered form">
                        {formErrors.global && <div className="form-error-message">{formErrors.global}</div>}
                        <form noValidate>
                            <div className='form-item'>
                                <div className='form-item'>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.name')}
                                        name="naz_kapela"
                                        required
                                        value={formValues.naz_kapela}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.naz_kapela)}
                                        helperText={formErrors.naz_kapela || ' '}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.altname')}
                                        name="naz_kapela2"
                                        value={formValues.naz_kapela2}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.naz_kapela2)}
                                        helperText={formErrors.naz_kapela2 || ' '}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.abbreviation')}
                                        name="zkr_kapela"
                                        required
                                        value={formValues.zkr_kapela}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.zkr_kapela)}
                                        helperText={formErrors.zkr_kapela || ' '}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                    <Autocomplete
                                        className="form-dropdown"
                                        size="small"
                                        options={styles}
                                        getOptionLabel={(option) => option.displayName}
                                        value={selectedStyle}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    <Typography variant="body1">{option.displayName}</Typography>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('elements.bands-admin.choose-style') + ' (' + t('elements.forms.required') + ')'}
                                                size="small"
                                                variant="outlined"
                                            />
                                        )}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                border: formErrors.id_styl ? '1px solid #fe938c' : undefined,
                                            },
                                        }}
                                        noOptionsText={t('elements.bands-admin.no-options')}
                                        onChange={handleStyleAutocompleteChange}
                                    />
                                    {formErrors.id_styl && <div className="form-error-message">{formErrors.id_styl}</div>}
                                    <Autocomplete
                                        className="form-dropdown"
                                        size="small"
                                        options={countries}
                                        getOptionLabel={(option) => option.displayName}
                                        value={selectedCountry}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    <Typography variant="body1">{option.displayName}</Typography>
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                placeholder={t('elements.bands-admin.choose-country') + ' (' + t('elements.forms.required') + ')'}
                                                size="small"
                                                variant="outlined"
                                            />
                                        )}
                                        sx={{
                                            '& .MuiOutlinedInput-root': {
                                                border: formErrors.id_stat ? '1px solid #fe938c' : undefined,
                                            },
                                        }}
                                        noOptionsText={t('elements.bands-admin.no-options')}
                                        onChange={handleCountryAutocompleteChange}
                                    />
                                    {formErrors.id_stat && <div className="form-error-message">{formErrors.id_stat}</div>}
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.foundation')}
                                        name="vznik_kapela"
                                        type="number"
                                        value={formValues.vznik_kapela ? formValues.vznik_kapela : ''}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.vznik_kapela)}
                                        helperText={formErrors.vznik_kapela || ' '}
                                        inputProps={{ maxLength: 4 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.web')}
                                        name="web_kapela"
                                        value={formValues.web_kapela}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.web_kapela)}
                                        helperText={formErrors.web_kapela || ' '}
                                        inputProps={{ maxLength: 200 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.web2')}
                                        name="web_kapela2"
                                        value={formValues.web_kapela2}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.web_kapela2)}
                                        helperText={formErrors.web_kapela2 || ' '}
                                        inputProps={{ maxLength: 200 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.email')}
                                        name="email_kapela"
                                        value={formValues.email_kapela}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.email_kapela)}
                                        helperText={formErrors.email_kapela || ' '}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.phone')}
                                        name="tel_kapela"
                                        value={formValues.tel_kapela ? formValues.tel_kapela : ''}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.tel_kapela)}
                                        helperText={formErrors.tel_kapela || ' '}
                                        inputProps={{ maxLength: 40 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.description')}
                                        name="pop_kapela"
                                        multiline
                                        rows={5}
                                        value={formValues.pop_kapela}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.pop_kapela)}
                                        helperText={formErrors.pop_kapela || ' '}
                                        inputProps={{ maxLength: 500 }}
                                    />
                                    <TextField
                                        fullWidth
                                        size="small"
                                        variant="outlined"
                                        label={t('elements.bands-admin.members')}
                                        name="sloz_kapela"
                                        multiline
                                        rows={5}
                                        value={formValues.sloz_kapela}
                                        onChange={handleInputChange}
                                        error={Boolean(formErrors.sloz_kapela)}
                                        helperText={formErrors.sloz_kapela || ' '}
                                        inputProps={{ maxLength: 400 }}
                                    />
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={Boolean(formValues.akt_kapela)}
                                                    onChange={handleInputChange}
                                                    name="akt_kapela"
                                                    color="primary"
                                                />
                                            }
                                            label={t('elements.bands-admin.active')}
                                        />
                                    </div>
                                    <div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(formValues.obs_kapela)}
                                                onChange={handleInputChange}
                                                name="obs_kapela"
                                                color="primary"
                                            />
                                        }
                                        label={t('elements.bands-admin.sent')}
                                    />
                                    </div>
                                </div>
                            </div>
                            <div>
                                {!isOpenedFromAnotherPage && 
                                    <Button onClick={() => setIsModifyConfirmDialogOpen(true)} type="button" disabled={selectedBand ? false : true} formNoValidate>
                                        {t('elements.forms.save')}
                                    </Button>
                                }
                                <Button onClick={() => setIsCreateConfirmDialogOpen(true)} disabled={isInitialState(formValues) ? true : false} formNoValidate>
                                    {isOpenedFromAnotherPage ? t('elements.forms.create-close') : t('elements.forms.create')}
                                </Button>
                                <Button onClick={clearForm} color="warning">
                                    {t('elements.forms.clear-form')}
                                </Button>
                            </div>
                        </form>
                    </Container>
                </Grid>
                <Dialog
                    open={isSaveSuccessDialogOpen}
                    onClose={() => {
                            closeSaveSuccessDialog();
                        }
                    }
                    aria-labelledby="save-success-dialog-title"
                    aria-describedby="save-success-dialog-description"
                >
                    <DialogTitle id="save-success-dialog-title">
                        {saveDialogMessage}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => {
                                closeSaveSuccessDialog();
                            }
                        }>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={isSaveErrorDialogOpen}
                    onClose={() => setIsSaveErrorDialogOpen(false)}
                    aria-labelledby="error-dialog-title"
                    aria-describedby="error-dialog-description"
                >
                    <DialogTitle id="save-error-dialog-title">
                        <div className="error-message">
                            {t('elements.forms.save-error-title')}
                        </div>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveErrorDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <ConfirmDialog
                    open={isModifyConfirmDialogOpen}
                    message={t('elements.venues-admin.modify-confirm')}
                    onClose={() => setIsModifyConfirmDialogOpen(false)}
                    onConfirmAction={() => handleModify()} />
                <ConfirmDialog
                    open={isCreateConfirmDialogOpen}
                    message={t('elements.venues-admin.create-confirm')}
                    onClose={() => setIsCreateConfirmDialogOpen(false)}
                    onConfirmAction={() => handleSubmit()} />
            </Container>
        </Container>
    );
}

function BandManagement(): JSX.Element {

    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <BandManagementContent />
            <Footer />
        </div>
    );
}

export default BandManagement;
