// LogoutConfirmDialog.tsx
import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ConfirmDialog from './ConfirmDialog';

interface LogoutConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirmLogout: () => void;
}

const LogoutConfirmDialog: React.FC<LogoutConfirmDialogProps> = ({ open, onClose, onConfirmLogout }) => {
  const { t } = useTranslation();

  return (
    <ConfirmDialog open={open} message={t('elements.navbar.logoutConfirmTitle')} onClose={onClose} onConfirmAction={onConfirmLogout} />
  );
};

export default LogoutConfirmDialog;
