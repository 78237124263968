// LocationQuickFilter.tsx
import React, { useState, useEffect } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

import QuickFilterAutocomplete from './QuickFilterAutocomplete';

import dayjs from 'dayjs';

import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

interface CombinedQuickFilterProps {
    sqlFields: string[];
    onFilterChange: (updates: [string, string[]][]) => void;
    values: { [key: string]: string[] };
}

const CombinedQuickFilter: React.FC<CombinedQuickFilterProps> = ({ sqlFields, onFilterChange, values }) => {

    const { t } = useTranslation();

    const startDateValueIndex = 0;
    const endDateValueIndex = 1;
    const locationValueIndex = 2;
    const styleValueIndex = 3;

    const locationValue = values[sqlFields[locationValueIndex]] || [];
    const styleValue = values[sqlFields[styleValueIndex]] || [];

    const initialDateValue = sessionStorage.getItem('dateQuickValue') || '';
    const [selectedDateValue, setSelectedDateValue] = useState<string>(initialDateValue);

    useEffect(() => {
        // This effect runs when the selectedValue changes, and stores it in sessionStorage
        sessionStorage.setItem('dateQuickValue', selectedDateValue);

        // Call the onFilterChange prop with the appropriate format
        if (selectedDateValue) {
            const dateRange = getDateRangeBasedOnValue(selectedDateValue); // Implement this function based on your needs
            onFilterChange([[sqlFields[startDateValueIndex], [dateRange.newStartDate]], [sqlFields[endDateValueIndex], [dateRange.newEndDate]]]);
        } else {
            // Handle the case for no selection (you might want to clear the filter or keep a default value)
            onFilterChange([[sqlFields[startDateValueIndex], []], [sqlFields[endDateValueIndex], []]]);
        }
    }, [selectedDateValue, sqlFields]);

    // Define the options for the Autocomplete component
    const locationOptions = [
        { label: 'Praha', value: '1' },
        { label: 'Zlín', value: '75' },
        { label: 'Ostrava', value: '44' },
        { label: 'Brno', value: '4' },
        { label: t('elements.tablefetch.filter-labels.others'), value: '~1,4,44,75' },
    ];

    // Define the options for the Autocomplete component
    const styleOptions = [
        { label: t('elements.tablefetch.filter-labels.rock'), value: '2' },
        { label: t('elements.tablefetch.filter-labels.metal'), value: '1' },
        { label: t('elements.tablefetch.filter-labels.punk'), value: '4' },
        { label: t('elements.tablefetch.filter-labels.hardcore'), value: '43' },
    ];

    // Define the options for the Autocomplete component
    const datesOptions = [
        { label: t('elements.tablefetch.filter-labels.today'), value: 'today' },
        { label: t('elements.tablefetch.filter-labels.tomorrow'), value: 'tomorrow' },
        { label: t('elements.tablefetch.filter-labels.this-week'), value: 'thisWeek' },
        { label: t('elements.tablefetch.filter-labels.next-week'), value: 'nextWeek' },
        { label: t('elements.tablefetch.filter-labels.this-month'), value: 'thisMonth' },
        { label: t('elements.tablefetch.filter-labels.this-year'), value: 'thisYear' },
        { label: t('elements.tablefetch.filter-labels.next-year'), value: 'nextYear' },
    ];

    const handleDatesFilterChange = (event: React.SyntheticEvent, newValue: { label: string, value: string } | null) => {
        // Check if newValue is not null and then proceed
        if (newValue) {
            const newDateValue = newValue.value;
            setSelectedDateValue(newDateValue);

            const dateRange = getDateRangeBasedOnValue(newDateValue);
            // We're creating the updates in the format that onFilterChange expects
            const updates: [string, string[]][] = [
                [sqlFields[startDateValueIndex], [dateRange.newStartDate]],
                [sqlFields[endDateValueIndex], [dateRange.newEndDate]]
            ];
            onFilterChange(updates);
        } else {
            // If newValue is null, you might want to handle the deselection case
            setSelectedDateValue('');
            onFilterChange([[sqlFields[startDateValueIndex], []], [sqlFields[endDateValueIndex], []]]); // Clearing the filter or handle it as per your requirement
        }
    };

    return (
        <>
            <Typography className="table-filter-quick" variant="h6" component="h6" gutterBottom>
                {t('elements.tablefetch.filter.show')}
            </Typography>
            <QuickFilterAutocomplete
                options={styleOptions}
                sqlField={sqlFields[styleValueIndex]}
                onFilterChange={onFilterChange}
                value={styleValue}
                defaultString={t('elements.tablefetch.filter-labels.all')}
            />
            <Typography className="table-filter-quick" variant="h6" component="h6" gutterBottom>
                {t('elements.tablefetch.filter-labels.concerts_in')}
            </Typography>
            <QuickFilterAutocomplete
                options={locationOptions}
                sqlField={sqlFields[locationValueIndex]}
                onFilterChange={onFilterChange}
                value={locationValue}
                defaultString={t('elements.tablefetch.filter-labels.wherever')}
            />
            <Typography className="table-filter-quick" variant="h6" component="h6" gutterBottom>
                {t('elements.tablefetch.filter-labels.during')}
            </Typography>
            <Autocomplete
                className="table-filter-quick"
                size="small"
                sx={{ width: 150 }}
                options={datesOptions}
                getOptionLabel={(option) => option.label}
                value={datesOptions.find(option => option.value === selectedDateValue) || null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={t('elements.tablefetch.filter-labels.whenever')}
                        size="small"
                        variant="standard"
                    />
                )}
                noOptionsText={t('elements.tablefetch.filter-labels.no-options')}
                onChange={handleDatesFilterChange}
            />
        </>
    );

};

function getDateRangeBasedOnValue(value: string): { newStartDate: string; newEndDate: string } {
    // Implement the logic to return the date range based on the selected value
    // For example, for 'today', it should return today's start and end date
    // Here's a simplified version:
    let newStartDate = '';
    let newEndDate = '';

    switch (value) {
        case 'today':
            newStartDate = dayjs().format('YYYY-MM-DD');
            newEndDate = newStartDate;
            break;
        case 'tomorrow':
            newStartDate = dayjs().add(1, 'day').format('YYYY-MM-DD');
            newEndDate = newStartDate;
            break;
        case 'thisWeek':
            newStartDate = dayjs().format('YYYY-MM-DD');
            newEndDate = dayjs().endOf('week').format('YYYY-MM-DD');
            break;
        case 'nextWeek':
            newStartDate = dayjs().add(1, 'week').startOf('week').format('YYYY-MM-DD');
            newEndDate = dayjs().add(1, 'week').endOf('week').format('YYYY-MM-DD');
            break;
        case 'thisMonth':
            newStartDate = dayjs().format('YYYY-MM-DD');
            newEndDate = dayjs().endOf('month').format('YYYY-MM-DD');
            break;
        case 'thisYear':
            newStartDate = dayjs().format('YYYY-MM-DD');
            newEndDate = dayjs().endOf('year').format('YYYY-MM-DD');
            break;
        case 'nextYear':
            newStartDate = dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD');
            newEndDate = dayjs().add(1, 'year').endOf('year').format('YYYY-MM-DD');
            break;
        default:
            break;
    }

    return { newStartDate, newEndDate };
}

export default CombinedQuickFilter;
