import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import 'flag-icons/css/flag-icons.min.css';
import { Button, IconButton } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

type Language = 'en' | 'cs';

function ChangeLanguage(): JSX.Element {
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState<Language>(i18n.language.split('-')[0] as Language);

    // Map each language to its corresponding flag icon class
    const languageFlags: Record<Language, string> = {
        en: 'fi fi-us',  // United States flag for English
        cs: 'fi fi-cz',  // Czech Republic flag for Czech
    };

    const toggleLanguage = () => {
        const newLanguage: Language = currentLanguage === 'en' ? 'cs' : 'en';
        setCurrentLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    return (
        <IconButton
            edge="end"
            color="inherit"
            className="navbar-item"
            size="medium"
            onClick={toggleLanguage}
        >
            <div
                className={`navbar-item ${languageFlags[currentLanguage]}`}
                style={{
                    fontSize: '10px',
                    textAlign: 'left',
                    marginRight: '10px', // Added margin right
                    filter: 'grayscale(100%)'
                }}>
            </div>
        </IconButton>
    );
}

export default ChangeLanguage;
