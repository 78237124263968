import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { mobileWindowWidth } from 'src/utils/constants';
import DataTable, {TableColumnConfig} from 'src/pages/page_elements/DataTable';

import { useNavigate } from 'react-router-dom';

type OpenairsTableProps = {
    showControls?: boolean;
    urlParams?: { [key: string ]: string | boolean| null }; // URL parameters
    emptyMessage?: string;
    tableTitle?: string; // Optional title to display above the table
    tableType?: string | null;
};
function OpenairsTable({ 
    showControls, 
    urlParams, 
    emptyMessage, 
    tableTitle, 
    tableType = 'openairs' 
}: OpenairsTableProps): JSX.Element {
    const { t } = useTranslation();

    const navigate = useNavigate();

    // State to hold the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Effect to update the state on window resize
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const isMobileView = windowWidth <= mobileWindowWidth;

    // Inside your component, before the component function.
    const openairsTableColumns: TableColumnConfig[] = [
        {
            title: t('elements.openairstable.table-titles.name'), // These keys are to be translated
            contentKey: 'naz_akce',
            sortableKey: 'naz_akce',
        },
        {
            title: t('elements.openairstable.table-titles.location'),
            contentKey: 'location',
            sortableKey: 'id_misto__naz_misto',
            className: isMobileView ? 'hide-on-mobile' : '',
        },
        {
            title: t('elements.openairstable.table-titles.start-date'),
            contentKey: 'dat_zac',
            sortableKey: 'dat_zac',
        },
        {
            title: t('elements.openairstable.table-titles.end-date'),
            contentKey: 'dat_kon',
            sortableKey: 'dat_kon',
            className: isMobileView ? 'hide-on-mobile' : '',
        },
        {
            title: t('elements.openairstable.table-titles.price'),
            contentKey: 'cena',
            sortableKey: 'cena',
            className: isMobileView ? 'hide-on-mobile' : '',
        },
    ];

    const apiPath = 'openairs/';
    const idKey = 'id_akce';

    return (
        <DataTable
            defaultSort='dat_zac'
            apiPath={apiPath}
            tableType={tableType}
            idKey={idKey}
            onRowClick={(actionId) => {
                navigate(`/action/?actionId=${actionId}`);
            }}
            containerClassName='component-container-fixed-width'
            showControls={showControls}
            urlParams={urlParams}
            emptyMessage={emptyMessage}
            tableTitle={tableTitle}
            tableColumns={openairsTableColumns}
            tableFilters={[
                ['dropdown', ['style_ids'], 'StylesFilter'],
                ['dropdown', ['region_ids'], 'RegionFilter'],
                ['dropdown', ['district_ids'], 'DistrictFilter'],
                ['dropdown', ['venue_ids'], 'VenuesFilter'],
                ['calendar', ['date_from', 'date_to'], 'CalendarFilter'],
            ]}
        />
    );
}

export default OpenairsTable;
