import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import the hook

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import { Container, Button, Typography } from '@mui/material';

import countryCodesMap from 'src/pages/page_elements/data/countrycodesmap';
import 'flag-icons/css/flag-icons.min.css';
import 'src/styles/centeredelement.css';
import ActionsTable from './page_elements/ActionsTable'; 

const serverUrl = process.env.REACT_APP_SERVER_URL;

function BandContent(): JSX.Element {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [bandData, setBandData] = useState<{
        [key: string]: string | number;
    } | null>(null);

    const [error, setError] = useState<string | null>(null);

    const query = new URLSearchParams(location.search);
    const bandId = query.get('bandId');

    const fetchBandData = (bandId: number) => {
        fetch(`${serverUrl}band/${bandId}/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data from the server');
                }
                return response.json();
            })
            .then((data) => {
                setBandData(data);
            })
            .catch((error) => {
                setError(error.message);
                console.log(error);
            });
    };

    useEffect(() => {
        if (bandId !== null) {
            const bandIdNumber = parseInt(bandId, 10);
            if (!isNaN(bandIdNumber)) {
                fetchBandData(bandIdNumber);
            } else {
                setError('Invalid bandId');
            }
        } else {
            setError('No bandId provided');
        }
    }, []);

    return (
        <Container className="centered">
            <Container className="column-down component-container">
                <Button className="button" size="small" onClick={() => navigate(-1)}> {t('backButton')}</Button>        
                    <Typography variant="h5" paragraph>
                        {t(
                            'elements.banddetail.band_information',
                        )}
                    </Typography>
                    {bandData && (
                        <>
                            <div className="data-row">
                                <strong>
                                    {t(
                                        'elements.banddetail.band_name',
                                    )}
                                    :
                                </strong>{' '}
                                <span>{bandData.naz_kapela}</span>
                            </div>
                            {bandData.naz_kapela !== bandData.naz_kapela2 && (
                                <div className="data-row">
                                    <strong>
                                        {t(
                                            'elements.banddetail.band_altname',
                                        )}
                                        :
                                    </strong>{' '}
                                    <span>{bandData.naz_kapela2}</span>
                                </div>
                            )}
                            <div className="data-row">
                                <strong>
                                    {t(
                                        'elements.banddetail.year',
                                    )}
                                    :
                                </strong>{' '}
                                <span>{bandData.vznik_kapela}</span>
                            </div>
                            <div className="data-row">
                                <strong>
                                    {t(
                                        'elements.banddetail.web',
                                    )}
                                    :
                                </strong>{' '}
                                <span>
                                {bandData.web_kapela ? (
                                        <a
                                            href={
                                                bandData.web_kapela.toString().startsWith('http://') || bandData.web_kapela.toString().startsWith('https://')
                                                    ? bandData.web_kapela.toString()
                                                    : `http://${bandData.web_kapela.toString()}`
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {bandData.web_kapela}
                                        </a>
                                    ) : (
                                        t('N/A')
                                    )}
                                </span>
                            </div>
                            <div className="data-row">
                                <strong>
                                    {t(
                                        'elements.banddetail.state',
                                    )}
                                    :
                                </strong>{' '}
                                <span>
                                    {bandData.state}&nbsp;
                                    <div
                                        className={`fi fi-${
                                            countryCodesMap[bandData.state_code]
                                        }`}
                                    />
                                </span>
                            </div>
                            <div className="data-row">
                                <strong>
                                    {t(
                                        'elements.banddetail.style',
                                    )}
                                    :
                                </strong>{' '}
                                <span>{bandData.style}</span>
                            </div>
                       </>
                    )}
                    {error && (
                        <p>
                            {t('Error')}: {error}
                        </p>
                    )}          
            </Container>
            <ActionsTable
                tableTitle={t('elements.banddetail.actions')}
                showControls={false}
                urlParams={{ 'band_id': bandId, 'return_all': true }}
                emptyMessage={t('elements.banddetail.no_actions')}
                tableType={`bands_${bandId}`}
                showFilters={false}
            />
        </Container>
    );
}

function Band(): JSX.Element {
    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <BandContent />
            <Footer />
        </div>
    );
}

export default Band;
