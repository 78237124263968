import React from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface LoginDialogProps {
  open: boolean;
  onClose: () => void;
  onLogin: (username: string, password: string) => void;
  error: string;
  resetError: () => void;
}

const LoginDialog: React.FC<LoginDialogProps> = ({ open, onClose, onLogin, error, resetError }) => {
  const { t } = useTranslation();
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  const handleLoginClick = () => {
    onLogin(username, password);
  };

  const handleClose = () => {
    setUsername('');
    setPassword('');
    resetError();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="login-dialog-title">
      <DialogTitle id="login-dialog-title">{t('elements.navbar.login')}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="username"
          label={t('elements.login.username')}
          type="text"
          className='login-dialog-textfield'
          fullWidth
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          margin="dense"
          id="password"
          label={t('elements.login.password')}
          className='login-dialog-textfield'
          type="password"
          fullWidth
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {t('elements.login.cancel')}
        </Button>
        <Button onClick={handleLoginClick} color="primary">
          {t('elements.login.submit')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoginDialog;
