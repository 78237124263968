import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DataTable, {TableColumnConfig} from 'src/pages/page_elements/DataTable';
import { FilterTuple } from 'src/pages/page_elements/RenderFilters';
import { mobileWindowWidth } from 'src/utils/constants';

type ActionsTableProps = {
    showControls?: boolean;
    urlParams?: { [key: string ]: string | boolean| null }; // URL parameters
    emptyMessage?: string;
    tableTitle?: string; // Optional title to display above the table
    tableType?: string | null;
    showFilters?: boolean;
};
function ActionsTable({ 
    showControls, 
    urlParams, 
    emptyMessage, 
    tableTitle, 
    tableType = 'actions',
    showFilters = true 
}: ActionsTableProps): JSX.Element {
    const { t } = useTranslation();

    const navigate = useNavigate(); // Call the hook here
    
    // State to hold the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Effect to update the state on window resize
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Determine if it's a mobile view
    const isMobileView = windowWidth <= mobileWindowWidth;

    const actionsTableColumns: TableColumnConfig[] = [
        {
            title: t('elements.actionstable.table-titles.name'),
            contentKey: 'naz_akce',
            sortableKey: 'naz_akce',
        },
        {
            title: t('elements.actionstable.table-titles.location'),
            contentKey: 'location',
            sortableKey: 'id_misto__naz_misto',
            className: isMobileView ? 'hide-on-mobile' : '',
        },
        {
            title: t('elements.actionstable.table-titles.type'),
            contentKey: 'type_of_action',
            sortableKey: 'id_typ__naz_typ',
            className: isMobileView ? 'hide-on-mobile' : '',
        },
        {
            title: t('elements.actionstable.table-titles.start-date'),
            contentKey: 'dat_zac',
            sortableKey: 'dat_zac', 
        },
        {
            title: t('elements.actionstable.table-titles.end-date'),
            contentKey: 'dat_kon',
            sortableKey: 'dat_kon',
            className: isMobileView ? 'hide-on-mobile' : '',
        },
        {
            title: t('elements.actionstable.table-titles.price'),
            contentKey: 'cena',
            sortableKey: 'cena',
            className: isMobileView ? 'hide-on-mobile' : '',
        }
    ];
    
    const apiPath = 'actions/';
    const idKey = 'id_akce';

    const defaultTableFilters: FilterTuple[] = [
        ['dropdown', ['style_ids'], 'StylesFilter'],
        ['dropdown', ['region_ids'], 'RegionFilter'],
        ['dropdown', ['district_ids'], 'DistrictFilter'],
        ['dropdown', ['venue_ids'], 'VenuesFilter'],
        ['calendar', ['date_from','date_to'], 'CalendarFilter'],
    ];

    const quickTableFilters: FilterTuple[] = [
        ['toggle', ['date_from','date_to', 'district_ids', 'style_group'], 'CombinedQuickFilter'],
    ];

    // Conditionally set table filters based on tableType
    const tableFilters = tableType === 'actions_home' ? quickTableFilters : defaultTableFilters;

    return (
        <DataTable
            tableType={tableType}
            defaultSort='dat_zac'
            apiPath={apiPath}
            idKey={idKey}
            onRowClick={(actionId) => {
                navigate(`/action/?actionId=${actionId}`);
            }}
            containerClassName='component-container-fixed-width'
            showControls={showControls}
            urlParams={urlParams}
            emptyMessage={emptyMessage}
            tableTitle={tableTitle}
            tableFilters={tableFilters}
            showFilters={showFilters}
            tableColumns={actionsTableColumns}
        />
    );
}

export default ActionsTable;
