import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container } from '@mui/material';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import ActionsTable from 'src/pages/page_elements/ActionsTable';

import 'src/styles/centeredelement.css';

function ActionsContent(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Container className="centered">
            <Container className="column-down">
                <ActionsTable tableTitle={t('pages.actions.title')}/>
            </Container>
        </Container>
    );
}

function Actions(): JSX.Element {
    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <ActionsContent />
            <Footer />
        </div>
    );
}

export default Actions;
