import React from 'react';
import ReactDOM from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { UserProvider } from './utils/UserContext';

import App from 'src/App';
import { languages } from 'src/locales/languages';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: languages,
        fallbackLng: 'cs',
        returnEmptyString: false, // if the value is an empty string, return the key
        detection: {
            order: ['navigator'],
        },
        interpolation: {
            escapeValue: false,
        },
    });

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement,
);

root.render(
    <React.StrictMode>
        <UserProvider>
            <App />
        </UserProvider>
    </React.StrictMode>,
);

// index.tsx gets loaded for each page, App.tsx determines what to render based on url
// home.tsx is the homepage
