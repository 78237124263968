import React from 'react';
import { useTranslation } from 'react-i18next';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import BandsTable from 'src/pages/page_elements/BandsTable';

import 'src/styles/centeredelement.css';
import { Container } from '@mui/material';

function BandsContent(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Container className="centered">
            <Container className="column-down">
                <BandsTable tableTitle={t('pages.bands.title')}/>
            </Container>
        </Container>
    );
}

function Bands(): JSX.Element {
    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <BandsContent />
            <Footer />
        </div>
    );
}

export default Bands;
