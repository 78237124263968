import React, { useState, useEffect } from 'react';
import { Navigate, useLocation, RouteProps } from 'react-router-dom';
import { isAuthenticated as checkIsAuthenticated } from 'src/utils/tokenManagement'; // Rename imported to avoid naming conflict
import { useAuth } from 'src/utils/useAuth'; // Import the useAuth hook
import { isStaff } from 'src/utils/tokenManagement';

interface PrivateRouteProps extends Omit<RouteProps, 'element' | 'children'> {
  forStaff?: boolean;
  component: React.ComponentType;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ forStaff, component: Component, ...rest }) => {
  const location = useLocation();
  const { isLoggedIn, error, login, logout } = useAuth(); // Use the custom hook
  const [isUserStaff, setIsUserStaff] = useState(false);

  const [isAuth, setIsAuth] = useState<boolean | null>(null);
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  const checkAuth = async () => {
    const authenticated = await checkIsAuthenticated();
    const staffStatus = await isStaff();

    setIsAuth(authenticated);
    setIsCheckingAuth(false);
    setIsUserStaff(staffStatus);

    if (!authenticated) {
      logout();
    }
  };

  useEffect(() => {
    checkAuth();
  }, [logout]);

  useEffect(() => {
    checkAuth();
  }, []);

  if (isCheckingAuth) {
    // Render nothing or a loading spinner while checking authentication status
    return <div>Loading...</div>;
  }

  if (!isAuth) {
    // Navigate to home if not authenticated
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  if (forStaff && !isUserStaff) {
    // If user must be staff and they're not, navigate away
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Component />;
};

export default PrivateRoute;
