import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface UserContextProps {
    userName: string;
    setUserName: (prezdivka: string) => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
    const [userName, setUserName] = useState<string>(() => {
        const savedPrezdivka = localStorage.getItem('prezdivka');
        return savedPrezdivka || '';
    });

    useEffect(() => {
        localStorage.setItem('prezdivka', userName);
    }, [userName]);

    return (
        <UserContext.Provider value={{ userName, setUserName }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error('useUser must be used within a UserProvider');
    }
    return context;
};
