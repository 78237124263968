// authService.ts
import { useNavigate } from 'react-router-dom';
import { removeAllTokens } from './tokenManagement';

// authService.ts
export const useLogout = (onLogout: () => void) => {
    const navigate = useNavigate();
  
    const logout = () => {
        removeAllTokens();
        onLogout(); // Call the callback to update the state in the parent component
        navigate('/'); // Or wherever you want to redirect after logging out
    };
  
    return logout;
  };
  
