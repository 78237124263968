import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { TextField, Container, Typography, Button, Grid, Divider } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { languages } from 'src/locales/languages';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';

import { axiosInstance } from 'src/utils/tokenManagement';

import { useAuth } from 'src/utils/useAuth'; // Import the useAuth hook

import 'src/styles/form.css';

const serverUrl = process.env.REACT_APP_SERVER_URL;

const initialState = {
    id_styl: '',
    zkr_styl: '',
    naz_styl: '',
    naz_styl2: '',
    naz3_styl: '',
    web_styl: '',
    pop_styl: '',
    kap_styl: '',
    id_tvrdost: 1,
    akt_styl: 1
};

const initialErrorState = {
    zkr_styl: '',
    naz_styl: '',
    id_tvrdost: '',
    global: ''
};

type StyleEntry = {
    style_id: string;
    style_name: string;
    style_group: string;
};

export type DropdownEntry = {
    id: string;
    displayName: string;
    group: string;
};

type StyleObject = {
    id_styl: number;
    zkr_styl: string;
    naz_styl: string;
    naz_styl2: string | null;
    naz3_styl: string | null;
    web_styl: string | null;
    pop_styl: string | null;
    kap_styl: string | null;
    id_tvrdost: number;
    akt_styl: number | null;
};

function StylesContent(): JSX.Element {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);

    const [selectedStyle, setSelectedStyle] = useState<StyleObject | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { isLoggedIn, error, login, logout } = useAuth();
    const [selectedDropdownEntry, setSelectedDropdownEntry] = useState<DropdownEntry | null>(null);
    const [styleExists, setStyleExists] = useState(false);

    const [isSaveSuccessDialogOpen, setIsSaveSuccessDialogOpen] = useState(false);

    const fetchStyles = () => {
        fetch(`${serverUrl}styles/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data from the server');
                }
                return response.json();
            })
            .then((data) => {
                const transformedEntries = data.map((entry: StyleEntry) => {
                    return {
                        id: entry.style_id,
                        displayName: entry.style_name,
                        group: entry.style_group
                    };
                });
                // Sort the transformedEntries by group
                const sortedEntries = transformedEntries.sort((a: DropdownEntry, b: DropdownEntry) => {
                    if (a.group && b.group) {
                        return a.group.localeCompare(b.group);
                    }
                    return 0;
                });

                setDropdownEntries(sortedEntries);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchStyles();
    }, []);

    const fetchStyleById = (styleId: string) => {
        axiosInstance.get(`${serverUrl}style/id/${styleId}/`)
            .then((response) => {
                setSelectedStyle(response.data);
                console.log(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching style details:', error);
                }
            });
    };

    const handleAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownEntry | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry>
    ) => {
        if (reason === 'clear') {
            setSelectedDropdownEntry(null);
            //setSelectedStyle(null);
        } else if (value) {
            setSelectedDropdownEntry(value);
            fetchStyleById(value.id);
        }
    };

    const [formValues, setFormValues] = useState({
        id_styl: '',
        zkr_styl: '',
        naz_styl: '',
        naz_styl2: '',
        naz3_styl: '',
        web_styl: '',
        pop_styl: '',
        kap_styl: '',
        id_tvrdost: 1,
        akt_styl: 1
    });

    const [formErrors, setFormErrors] = useState(initialErrorState);

    const validateForm = () => {
        // Assume all is initially valid
        const errors = { ...initialErrorState };
        let isValid = true;

        if (!formValues.naz_styl) {
            errors.naz_styl = t('elements.forms.required');
            isValid = false;
        }

        if (!formValues.zkr_styl) {
            errors.naz_styl = t('elements.forms.required');
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const clearForm = () => {
        setFormValues({ ...initialState });
        setFormErrors({ ...initialErrorState });
        setSelectedDropdownEntry(null);
        setSelectedStyle(null);
        setStyleExists(false);
    };

    // Handle form field changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
    
        // Assuming formValues.id_stat and formValues.id_styl are set elsewhere optimally
        const updatedValue = type === 'checkbox' ? checked :
                             type === 'number' ? value === '' ? null : Number(value) :
                             value;
    
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            [name]: updatedValue
        }));
    }; 

    // Handle form submission
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        if (!validateForm()) {
            return;
        }

        const endpoint = formValues.id_styl ? `style/id/${formValues.id_styl}/` : 'style/';
        const method = formValues.id_styl ? 'put' : 'post';

        axiosInstance[method](`${serverUrl}${endpoint}`, formValues)
            .then(response => {
                // Handle successful submission (e.g., display a message or redirect)
                // Reset errors on success
                setIsSaveSuccessDialogOpen(true); // Open the dialog on successful save
                setFormErrors(initialErrorState);
                fetchStyles();
            })
            .catch(error => {
                // Set global error message from response if available, otherwise use a default error message
                if (error.response?.data.naz_styl) {
                    const errorMessage = error.response.data.naz_styl[0];
                    if (errorMessage === 'style with this naz styl already exists.') {
                        setFormErrors(prevErrors => ({
                            ...prevErrors,
                            naz_styl: t('elements.styles-admin.style-exists')
                        }));
                        setStyleExists(true);
                    } else {
                        setFormErrors(prevErrors => ({
                            ...prevErrors,
                            global: errorMessage
                        }));
                    }
                }
                if (error.response?.data.zkr_styl) {
                    const errorMessage = error.response.data.zkr_styl[0];
                    if (errorMessage === 'style with this zkr styl already exists.') {
                        setFormErrors(prevErrors => ({
                            ...prevErrors,
                            zkr_styl: t('elements.styles-admin.style-abbr-exists')
                        }));
                    } else {
                        setFormErrors(prevErrors => ({
                            ...prevErrors,
                            global: errorMessage
                        }));
                    }
                }
                console.log(error.response);
            });
    };

    useEffect(() => {
        if (selectedStyle) {
            // Populate form fields with selected style data
            setFormValues({
                id_styl: selectedStyle.id_styl.toString(),
                zkr_styl: selectedStyle.zkr_styl || '',
                naz_styl: selectedStyle.naz_styl || '',
                naz_styl2: selectedStyle.naz_styl2 || '',
                naz3_styl: selectedStyle.naz3_styl || '',
                web_styl: selectedStyle.web_styl || '',
                pop_styl: selectedStyle.pop_styl || '',
                kap_styl: selectedStyle.kap_styl || '',
                id_tvrdost: selectedStyle.id_tvrdost || 1,
                akt_styl: selectedStyle.akt_styl || 1
            });
        } else {
            // Reset form fields for creating a new style
            setFormValues({
                id_styl: '',
                zkr_styl: '',
                naz_styl: '',
                naz_styl2: '',
                naz3_styl: '',
                web_styl: '',
                pop_styl: '',
                kap_styl: '',
                id_tvrdost: 1,
                akt_styl: 1
            });
        }
    }, [selectedStyle]);

    const handleMerge = () => {
        // Assuming you need to send the ID of the style to be merged
        // and the new name for the style
        const styleIdToMerge = formValues.id_styl;
        const newStyleName = formValues.naz_styl;
    
        if (styleIdToMerge && newStyleName) {
            axiosInstance.post(`${serverUrl}style/rename/`, { style_id: styleIdToMerge, new_naz_styl: newStyleName })
                .then(response => {
                    // Handle successful merge
                    fetchStyles(); // Refresh the styles list
                    clearForm(); // Clear the form
                    setIsSaveSuccessDialogOpen(true); // Optionally, show a success dialog
                })
                .catch(error => {
                    // Handle errors
                    console.error('Error during renaming:', error);
                    // Optionally, set an error state to display the error message
                });
        } else {
            console.error('Missing style ID or new style name for merging');
            // Optionally, set an error state to notify the user of missing information
        }
    };
    
    return (
        <Container className="centered">
            <Container className="column-down">
                <Typography variant="h6" className="centered">
                    {t('elements.styles-admin.headline')}
                </Typography>
                <Grid container direction="column">
                    <Autocomplete
                        className="dropdown"
                        size="small"
                        options={dropdownEntries}
                        getOptionLabel={(option) => option.displayName}
                        value={selectedDropdownEntry}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('elements.styles-admin.choose-style')}
                                size="small"
                                variant="outlined"
                            />
                        )}
                        noOptionsText={t('elements.styles-admin.no-options')}
                        onChange={handleAutocompleteChange}
                    />
                    <Container className="centered form">
                        {formErrors.global && <div className="error-message">{formErrors.global}</div>}
                        <form onSubmit={handleSubmit} noValidate>
                            <div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.styles-admin.style-name')}
                                    name="naz_styl"
                                    required
                                    value={formValues.naz_styl}
                                    onChange={handleInputChange}
                                    error={formErrors.naz_styl === '' ? false : true}
                                    helperText={formErrors.naz_styl || ' '}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </div>
                            {styleExists && selectedStyle &&
                                <div>
                                    Styl s tímto názvem již existuje. Chcete starý styl vymazat a všechny kapely přesměrovat na nový styl. Pozor, změna je nevratná.<br/>
                                    <Button id="merge-button" onClick={handleMerge}>
                                        Ano, pokračovat?
                                    </Button>
                                    <br/>
                                </div>
                            }
                            <div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    required
                                    label={t('elements.styles-admin.style-short')}
                                    name="zkr_styl"
                                    value={formValues.zkr_styl}
                                    onChange={handleInputChange}
                                    error={formErrors.zkr_styl === '' ? false : true}
                                    helperText={formErrors.zkr_styl || ' '}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </div>
                            <div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.styles-admin.style-name2')}
                                    name="naz_styl2"
                                    value={formValues.naz_styl2}
                                    onChange={handleInputChange}
                                    error={false}
                                    helperText=' '
                                    inputProps={{ maxLength: 100 }}
                                />
                            </div>
                            <div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.styles-admin.style-name3')}
                                    name="naz3_styl"
                                    value={formValues.naz3_styl}
                                    onChange={handleInputChange}
                                    error={false}
                                    helperText=' '
                                    inputProps={{ maxLength: 100 }}
                                />
                            </div>
                            <div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.styles-admin.web-style')}
                                    name="web_styl"
                                    value={formValues.web_styl}
                                    onChange={handleInputChange}
                                    error={false}
                                    helperText=' '
                                    inputProps={{ maxLength: 60 }}
                                />
                            </div>
                            <div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.styles-admin.style-description')}
                                    name="pop_styl"
                                    value={formValues.pop_styl}
                                    onChange={handleInputChange}
                                    multiline
                                    error={false}
                                    helperText=' '
                                    rows={3}

                                    inputProps={{ maxLength: 1200 }}
                                />
                            </div>
                            <div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.styles-admin.style-bands')}
                                    name="kap_styl"
                                    value={formValues.kap_styl}
                                    onChange={handleInputChange}
                                    error={false}
                                    helperText=' '
                                    inputProps={{ maxLength: 250 }}
                                />
                            </div>
                            {/*<div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    required
                                    label={t('elements.styles-admin.hardness')}
                                    name="id_tvrdost"
                                    type="number"
                                    value={formValues.id_tvrdost}
                                    onChange={handleInputChange}
                                    error={formErrors.id_tvrdost === '' ? false : true}
                                    helperText={formErrors.id_tvrdost ? t('elements.forms.required') : ''}
                                />
                            </div>*/}
                            <div className='form-item'>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Boolean(formValues.akt_styl)}
                                            onChange={handleInputChange}
                                            name="akt_styl"
                                            color="primary"
                                        />
                                    }
                                    label={t('elements.styles-admin.active-style')}
                                />
                            </div>
                            <div>
                                <Button type="submit" formNoValidate>
                                    {selectedStyle ? t('elements.styles-admin.style-edit') : t('elements.styles-admin.style-create')}
                                </Button>
                                <Button onClick={clearForm} color="warning">
                                    {t('elements.forms.clear-form')}
                                </Button>
                            </div>
                        </form>
                    </Container>
                </Grid>
                <Dialog
                    open={isSaveSuccessDialogOpen}
                    onClose={() => setIsSaveSuccessDialogOpen(false)}
                    aria-labelledby="save-success-dialog-title"
                    aria-describedby="save-success-dialog-description"
                >
                    <DialogTitle id="save-success-dialog-title">
                        {t('elements.forms.save-success-title')}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveSuccessDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Container>
    );
}

function Styles(): JSX.Element {

    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <StylesContent />
            <Footer />
        </div>
    );
}

export default Styles;
