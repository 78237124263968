import { useState, useEffect, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { axiosInstance, isAuthenticated } from 'src/utils/tokenManagement'; // Make sure isAuthenticated is exported from 'src/utils/auth'
import { useLogout } from './useLogout';
import { getAccessToken, setAccessToken, setRefreshToken, setStaffValue } from 'src/utils/tokenManagement';
import { useUser } from './UserContext'; // Import the useUser hook
import ConcertTranslationManagement from 'src/pages/ConcertTranslationManagement';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isCheckingLoginStatus, setIsCheckingLoginStatus] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const { setUserName } = useUser(); // Get setPrezdivka from the context

  // Define the logout function
  const logout = useLogout(() => {
    setIsLoggedIn(false);
    setUserName(''); // Clear prezdivka on logout
  });

  if(!isLoggedIn && getAccessToken()) setIsLoggedIn(true);

  // Function to check authentication status and logout if not authenticated
  const checkAuthenticationStatus = useCallback(async () => {
    console.log('Checking authentication status');
    const authenticated = await isAuthenticated(); // This will attempt to refresh the token if it's expired
    console.log('authenticated', authenticated);
    if (!authenticated && isLoggedIn) {
      console.log('Logging out due to authentication status check');
      logout();
    }
    setIsLoggedIn(authenticated);
    setIsCheckingLoginStatus(false);
  }, [isLoggedIn, logout]);

  useEffect(() => {
    if (!isLoggedIn) return;

    // Check authentication status on mount
    checkAuthenticationStatus();

    // Set up an interval to check the authentication status regularly
    const interval = setInterval(() => {
      checkAuthenticationStatus();
    }, 5 * 60 * 1000); // checks every 5 minutes

    // Clear the interval when the component is unmounted or if the user logs out
    return () => clearInterval(interval);
  }, [isLoggedIn, checkAuthenticationStatus]);

  // Function to fetch user details
  const fetchUserDetails = async () => {
    try {
      const response = await axiosInstance.get(`${serverUrl}/user/details/`);
      const { is_staff, prezdivka } = response.data; // Assuming the response has an is_staff property and prezdivka
      setStaffValue(is_staff);
      setUserName(prezdivka); // Set prezdivka in context
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        setError('Failed to fetch user details.');
      } else {
        setError('Something went wrong while fetching user details!');
      }
    }
  };

  // Login function
  const login = async (username: string, password: string) => {
    try {
      const response = await axios.post(`${serverUrl}/login/`, {
        username,
        password,
      });

      const { refresh, access } = response.data;
      setRefreshToken(refresh);
      setAccessToken(access);
      setIsLoggedIn(true);
      await fetchUserDetails(); // Fetch user details upon successful login
    } catch (err) {
      const error = err as AxiosError;
      if (error.response) {
        const responseData = error.response.data as { error: string };
        setError(responseData.error);
      } else {
        setError('Something went wrong during login!');
      }
    }
  };

  return {
    isLoggedIn,
    error,
    login,
    logout,
    resetError: () => setError(''),
  };
};
