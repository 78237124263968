import React from 'react';
import { useTranslation } from 'react-i18next';

import { Typography, Container } from '@mui/material';

import { languages } from 'src/locales/languages';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import ChangeLanguage from './page_elements/ChangeLanguage';

function SettingsContent(): JSX.Element {
    const { t, i18n } = useTranslation();

    return (
        <Container className="centered">
            <Container className="column-down">
                <h1>{t('pages.settings.language')}</h1>
                <div>
                    <ChangeLanguage />
                </div>
            </Container>
        </Container>
    );
}

function Settings(): JSX.Element {
    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <SettingsContent />
            <Footer />
        </div>
    );
}

export default Settings;
