import React from 'react';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { t } from 'i18next';

export type DropdownEntry = {
    id: string;
    displayName: string;
    group?: string;
};

type FilterAutocompleteMultiProps = {
    options: DropdownEntry[];
    value: string[];
    onChange: (
        event: React.SyntheticEvent<Element, Event>,
        newValue: DropdownEntry[],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry> | undefined
    ) => void;
    placeholder: string;
    group?: boolean;
};

function FilterAutocompleteMulti({ options, value, onChange, placeholder, group }: FilterAutocompleteMultiProps): JSX.Element {
    return (
        <Autocomplete
            multiple
            className="table-filter"
            size="small"
            options={options}
            limitTags={2}
            filterSelectedOptions
            getOptionLabel={(option) => option.displayName}
            value={value.map(v => options.find(entry => entry.id === v) || { id: v, displayName: '' })}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    size="small"
                    variant="outlined"
                />
            )}
            noOptionsText={t('elements.tablefetch.filter-labels.no-options')}
            onChange={onChange}
            groupBy={group ? (option) => option.group || '' : undefined}
        />
    );
}

type FilterAutocompleteProps = {
    options: DropdownEntry[];
    value: DropdownEntry | null;
    onChange: (
        event: React.SyntheticEvent<Element, Event>,
        newValue: DropdownEntry | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry> | undefined
    ) => void;
    placeholder: string;
    group?: boolean;
};

function FilterAutocomplete({ options, value, onChange, placeholder, group }: FilterAutocompleteProps): JSX.Element {
    return (
        <Autocomplete
            className="table-filter"
            size="small"
            options={options}
            getOptionLabel={(option) => option.displayName}
            value={options.find(entry => entry.id === value?.id) || null}
            renderInput={(params) => (
                <TextField
                    {...params}
                    placeholder={placeholder}
                    size="small"
                    variant="outlined"
                />
            )}
            noOptionsText={t('elements.tablefetch.filter-labels.no-options')}
            onChange={onChange}
            groupBy={group ? (option) => option.group || '' : undefined}
        />
    );
}

export { FilterAutocompleteMulti, FilterAutocomplete };
