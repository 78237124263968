import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Container } from '@mui/material';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import ActionsTable from 'src/pages/page_elements/ActionsTable';

import 'src/styles/homepage.css';
import 'src/styles/centeredelement.css';
import 'src/styles/banner.css';

function HomeContent(): JSX.Element {
    const { t } = useTranslation();

    return (
        <Container className="centered">
            <Container className="column-down">

                <ActionsTable 
                    urlParams={{ 'return_all': true }} 
                    tableTitle={t('pages.homepage.upcoming-actions')} 
                    tableType='actions_home'
                    showControls={false}
                />
            </Container>
        </Container>
    );
}

function Home(): JSX.Element {
    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <HomeContent />
            <Footer />
        </div>
    );
}

export default Home;
