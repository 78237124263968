import React, { useEffect } from 'react';

const BackgroundHandler: React.FC = () => {
    useEffect(() => {
        const scriptContent = `
      (function() {
        function generateSpeckles(amountMultiplier) {
          //get window dimensions for the size
          let xSize = window.innerWidth;
          let ySize = window.innerHeight;
          const speckleCount = xSize*ySize*0.0001*amountMultiplier;
          let svg = '<svg xmlns="http://www.w3.org/2000/svg" width="' + xSize + '" height="' + ySize + '" viewBox="0 0 ' + xSize + ' ' + ySize + '">';
          for (let i = 0; i < speckleCount; i++) {
            const x = Math.random() * xSize;
            const y = Math.random() * ySize;
            const size = Math.random() * 2;
            const fill = String(Math.floor(Math.random()*4+2)).repeat(3);
            svg += '<circle cx="' + x + '" cy="' + y + '" r="' + size + '" fill="#' + fill + '"/>';
          }
          svg += '</svg>';
          return svg;
        }
        
        function updateBackground() {
          const svgImage = generateSpeckles(2);
          const body = document.querySelector('body');
          if (body) {
            body.style.backgroundImage = 'url("data:image/svg+xml;base64,' + btoa(svgImage) + '")';
          } else {
            console.error('Could not find the body element.');
          }
        }  
        
        // window.addEventListener('beforeunload', function(event) {
        //   document.body.style.opacity = "0";
        //   // updateBackground();
        // });
        
        updateBackground();
        
        setTimeout(() => {
          window.addEventListener('resize', updateBackground);
          document.body.style.opacity = "1";
        }, 10);
      })();
    `;

        const script = document.createElement('script');
        script.innerHTML = scriptContent;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return null;
};

export default BackgroundHandler;
