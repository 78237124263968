import React, { useState, useEffect } from 'react';
import { t } from 'i18next';

import {FilterAutocompleteMulti, DropdownEntry} from './FilterAutocomplete';

import 'src/styles/filters.css';

const serverUrl = process.env.REACT_APP_SERVER_URL;

type StylesFilterProps = {
    sqlFields: string[];
    onFilterChange: (updates: [string, string[]][]) => void;
    values: { [key: string]: string[] };
};

type StyleEntry = {
    style_id: string;
    style_name: string;
};

function StylesFilter({ sqlFields, onFilterChange, values }: StylesFilterProps): JSX.Element {
    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const sqlField = sqlFields[0];

    const value = values[sqlField] || [];

    const fetchStyles = () => {
        fetch(`${serverUrl}styles/?filter_active=true`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data from the server');
                }
                return response.json();
            })
            .then((data) => {
                const transformedEntries = data.map((entry: StyleEntry) => {
                    return {
                        id: entry.style_id,
                        displayName: entry.style_name,
                    };
                });

                setDropdownEntries(transformedEntries);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchStyles();
    }, []);

    const handleFilterChange = (item: DropdownEntry[] | null) => {
        const selectedStyles = item?.map(entry => entry.id) || [];
        onFilterChange([[sqlField, selectedStyles]]);
    };

    return (
        <div>
            {!isLoading && (
                <FilterAutocompleteMulti
                    options={dropdownEntries}
                    value={value}
                    onChange={(event, newValue) => handleFilterChange(newValue)}
                    placeholder={t('elements.tablefetch.filter-labels.style')}
                />
            )}
        </div>
    );
}

export default StylesFilter;
