import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TextField, Container, Typography, Button, Grid } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import ConfirmDialog from './page_elements/ConfirmDialog';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { axiosInstance } from 'src/utils/tokenManagement';

import { useAuth } from 'src/utils/useAuth'; // Import the useAuth hook

import { isValidTime, isISODate, formatDate } from 'src/utils/dates';

import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';

import 'src/styles/form.css';
import { use } from 'i18next';
import { CheckBox } from '@mui/icons-material';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export type DropdownEntry = {
    id: string;
    displayName: string;
};

type ActionEntry = {
    id_akce: string;
    naz_akce: string;
    location: string;
    dat_zac: string;
};

type ActionObject = {
    id_akce: number;
    id_misto: number;
    id_zamer: number;
    id_typ: number;
    naz_akce: string;
    dat_zac: string;
    cas_zac: string;
    dat_kon: string;
    cas_kon: string | null;
    cena: number | null;
    id_mena: number;
    id_prod: number;
    web_akce: string | null;
    dalkap_akce: string | null;
    popis_akce: string | null;
    pockap_akce: number | null;
    pockap2_akce: number | null;
    bands_ids: number[];
    status: number;
};

const initialState: ActionObject = {
    id_akce: 0,
    id_misto: 0,
    id_zamer: 0,
    id_typ: 0,
    naz_akce: '',
    dat_zac: '',
    cas_zac: '',
    dat_kon: '',
    cas_kon: '',
    cena: null,
    id_mena: 0,
    id_prod: 0,
    web_akce: '',
    dalkap_akce: '',
    popis_akce: '',
    pockap_akce: 0,
    pockap2_akce: 0,
    bands_ids: [0],
    status: 0
};

type ErrorState = {
    naz_akce: string;
    id_misto: string;
    id_zamer: string;
    id_typ: string;
    dat_zac: string;
    cas_zac: string;
    dat_kon: string;
    cas_kon: string;
    cena: string;
    id_mena: string;
    id_prod: string;
    web_akce: string;
    dalkap_akce: string;
    popis_akce: string;
    pockap_akce: 0;
    pockap2_akce: 0;
    non_field_errors: string;
    status: 0;
    global: string;
};

const initialErrorState = {
    naz_akce: '',
    id_misto: '',
    id_zamer: '',
    id_typ: '',
    dat_zac: '',
    cas_zac: '',
    dat_kon: '',
    cas_kon: '',
    cena: '',
    id_mena: '',
    id_prod: '',
    web_akce: '',
    dalkap_akce: '',
    popis_akce: '',
    pockap_akce: '',
    pockap2_akce: '',
    status: '',
    non_field_errors: '',
    global: ''
};

type venueEntry = {
    venue_id: string;
    venue_name: string;
};

type venue = {
    id: string;
    displayName: string;
};

let venues: venue[] = [];

type focusEntry = {
    focus_id: string;
    focus_name: string;
};

type focus = {
    id: string;
    displayName: string;
};

let focuses: focus[] = [];

type currencyEntry = {
    currency_id: string;
    currency_short: string;
};

type currency = {
    id: string;
    displayName: string;
};

let currencies: currency[] = [];

type productionEntry = {
    production_id: string;
    production_name: string;
};

type production = {
    id: string;
    displayName: string;
};

let productions: production[] = [];

type typeEntry = {
    type_id: string;
    type_name: string;
};

type type = {
    id: string;
    displayName: string;
};

let types: type[] = [];

type bandEntry = {
    id_kapela: string;
    naz_kapela: string;
    state_code: string;
};

type band = {
    id: string;
    displayName: string;
};

function fetchVenues() {
    const fetchVenues = () => {
        fetch(`${serverUrl}venues/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch venues data from the server');
                }
                return response.json();
            })
            .then((data) => {
                 venues = data.map((entry: venueEntry) => {
                    return {
                        id: entry.venue_id,
                        displayName: entry.venue_name
                    };
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    fetchVenues();
}

function fetchFocuses() {
    const fetchFocuses = () => {
        fetch(`${serverUrl}focuses/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch focuses data from the server');
                }
                return response.json();
            })
            .then((data) => {
                focuses = data.map((entry: focusEntry) => {
                    return {
                        id: entry.focus_id,
                        displayName: entry.focus_name
                    };
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    fetchFocuses();
}

function fetchCurrencies() {
    const fetchCurrencies = () => {
        fetch(`${serverUrl}currencies/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch currencies data from the server');
                }
                return response.json();
            })
            .then((data) => {
                currencies = data.map((entry: currencyEntry) => {
                    return {
                        id: entry.currency_id,
                        displayName: entry.currency_short
                    };
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    fetchCurrencies();
}

function fetchProductions() {
    const fetchProductions = () => {
        fetch(`${serverUrl}productions/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch productions data from the server');
                }
                return response.json();
            })
            .then((data) => {
                productions = data.map((entry: productionEntry) => {
                    return {
                        id: entry.production_id,
                        displayName: entry.production_name
                    };
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    fetchProductions();
}

function fetchTypes() {
    const fetchTypes = () => {
        fetch(`${serverUrl}types/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch types data from the server');
                }
                return response.json();
            })
            .then((data) => {
                types = data.map((entry: typeEntry) => {
                    return {
                        id: entry.type_id,
                        displayName: entry.type_name
                    };
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    fetchTypes();
}

function isInitialState(currentState: ActionObject): boolean {
    return Object.keys(initialState).every((key): key is keyof ActionObject => {
        return initialState[key as keyof ActionObject] === currentState[key as keyof ActionObject];
    });
}

function ActionManagementContent(): JSX.Element {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const navigate = useNavigate();

    const [pastEventsChecked, setPastEventsChecked] = useState(false);

    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);

    const [selectedAction, setSelectedAction] = useState<ActionObject | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { isLoggedIn, error, login, logout } = useAuth();
    const [selectedDropdownEntry, setSelectedDropdownEntry] = useState<DropdownEntry | null>(null);
    const [selectedFocus, setSelectedFocus] = useState<focus | null>(null);
    const [selectedVenue, setSelectedVenue] = useState<venue | null>(null);
    const [selectedCurrency, setSelectedCurrency] = useState<currency | null>(null);
    const [selectedProduction, setSelectedProduction] = useState<production | null>(null);
    const [selectedType, setSelectedType] = useState<type | null>(null);
    const [selectedBands, setSelectedBands] = useState<band[]>([]);

    const [bandSearchValue, setBandSearchValue] = React.useState('');

    const [bands, setBands] = useState<band[]>([]);

    const [bandsIds, setBandsIds] = useState<number[]>([]);
    
    const [isSaveSuccessDialogOpen, setIsSaveSuccessDialogOpen] = useState(false);
    const [isSaveErrorDialogOpen, setIsSaveErrorDialogOpen] = useState(false);
    const [saveDialogMessage, setSaveDialogMessage] = useState('');

    const [isModifyConfirmDialogOpen, setIsModifyConfirmDialogOpen] = useState(false);
    const [isCreateConfirmDialogOpen, setIsCreateConfirmDialogOpen] = useState(false);

    const [formErrors, setFormErrors] = useState(initialErrorState);
    const [formValues, setFormValues] = useState(initialState);

    const [startDate, setStartDate] = useState<Dayjs | null>(null);
    const [endDate, setEndDate] = useState<Dayjs | null>(null);

    const [bandsLoading, setBandsLoading] = useState(false);

    const [savedBand, setSavedBand] = useState<band | null>(null);

    function fetchBands(bandSearchValue: string) {
        const fetchBands = () => {
            const encodedSearchValue = encodeURIComponent(bandSearchValue);
            fetch(`${serverUrl}bands/search/${encodedSearchValue}/`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to fetch bands data from the server');
                    }
                    return response.json();
                })
                .then((data) => {
                    let fetchBands: band[] = [];
                    fetchBands = data.map((entry: bandEntry) => {
                        return {
                            id: entry.id_kapela,
                            displayName: entry.naz_kapela + ' (' + entry.state_code + ')'
                        };
                    });
                    setBands(fetchBands);
                })
                .catch((error) => {
                    console.log(error);
                });
        };
        if (bandSearchValue.length >= 3) fetchBands();
    }
    
    const fetchActions = (selectedAction?: ActionObject) => {
        // Read value of checkbox
        const pastEvents = pastEventsChecked ? 'true' : 'false';
        fetch(`${serverUrl}actions/?return_all=true&past_events=${pastEvents}&paginate=false`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch action data from the server');
                }
                return response.json();
            })
            .then((data) => {
                const transformedEntries = data.map((entry: ActionEntry) => {
                    return {
                        id: entry.id_akce,
                        displayName: entry.naz_akce + ' (' + entry.location + ')' + ' ' + formatDate(entry.dat_zac)
                    };
                });

                setDropdownEntries(transformedEntries);
                fetchFocuses();
                fetchVenues();
                fetchCurrencies();
                fetchProductions();
                fetchTypes();
                fetchBands(bandSearchValue);
                if (selectedAction) {
                    setSelectedAction(selectedAction);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                }
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchActions();
    }, []);

    const fetchActionBands = (actionId: string) => {
        setBandsLoading(true);
        axiosInstance.get(`${serverUrl}bands/?action_id=${actionId}`)
            .then((response) => {
                const mappedBands: band[] = response.data.results.map((band: bandEntry) => ({
                    id: band.id_kapela, // TypeScript now knows band has an 'id' of type string
                    displayName: band.naz_kapela // and a 'name' (mapped to displayName) of type string
                }));
                setSelectedBands(mappedBands);
                setBands(mappedBands);
                setBandsIds(mappedBands.map((band) => Number(band.id)));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching action bands:', error);
                }
            })
            .finally(() => {
                setBandsLoading(false);
            });
    };

    const fetchActionById = (actionId: string) => {
        axiosInstance.get(`${serverUrl}action/${actionId}/`)
            .then((response) => {
                setSelectedAction(response.data);
                fetchActionBands(actionId);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching action details:', error);
                }
            });
    };

    const handleActionAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownEntry | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry>
    ) => {
        if (reason === 'clear') {
            setSelectedDropdownEntry(null);
        } else if (value) {
            setSelectedDropdownEntry(value);
            fetchActionById(value.id);
        }
    };

    const handleVenueAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: venue | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<venue>
    ) => {
        if (reason === 'clear') {
            setSelectedVenue(null);
            setFormValues({
                ...formValues,
                id_misto: 0
            });
        } else if (value) {
            setSelectedVenue(value);
            setFormValues({
                ...formValues,
                id_misto: Number(value.id)
            });
        }
    };

    const handleFocusAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: focus | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<focus>
    ) => {
        if (reason === 'clear') {
            setSelectedFocus(null);
            setFormValues({
                ...formValues,
                id_zamer: 0
            });
        } else if (value) {
            setSelectedFocus(value);
            setFormValues({
                ...formValues,
                id_zamer: Number(value.id)
            });
        }
    };

    const handleTypeAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: focus | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<focus>
    ) => {
        if (reason === 'clear') {
            setSelectedType(null);
            setFormValues({
                ...formValues,
                id_typ: 0
            });
        } else if (value) {
            setSelectedType(value);
            setFormValues({
                ...formValues,
                id_typ: Number(value.id)
            });
        }
    };

    const handleCurrencyAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: currency | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<currency>
    ) => {
        if (reason === 'clear') {
            setSelectedCurrency(null);
            setFormValues({
                ...formValues,
                id_mena: 0
            });
        } else if (value) {
            setSelectedCurrency(value);
            setFormValues({
                ...formValues,
                id_mena: Number(value.id)
            });
        }
    };

    const handleProductionAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: production | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<production>
    ) => {
        if (reason === 'clear') {
            setSelectedProduction(null);
            setFormValues({
                ...formValues,
                id_prod: 0
            });
        } else if (value) {
            setSelectedProduction(value);
            setFormValues({
                ...formValues,
                id_prod: Number(value.id)
            });
        }
    };

    const handleBandAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: band[] | [],
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<band>
    ) => {
        if (reason === 'clear') {
            setSelectedBands([]);
            setFormValues({
                ...formValues,
                bands_ids: []
            });
        } else if (value) {
            setSelectedBands(value);
            setFormValues({
                ...formValues,
                bands_ids: value.map((band) => Number(band.id))
            });
        }
    };

    const clearForm = () => {
        setFormValues({ ...initialState });
        setFormErrors({ ...initialErrorState });
        setSelectedDropdownEntry(null);
        setSelectedVenue(null);
        setSelectedFocus(null);
        setSelectedCurrency(null);
        setSelectedProduction(null);
        setSelectedType(null);
        setSelectedBands([]);
    };

    const validateForm = () => {
        // Assume all is initially valid
        const errors = { ...initialErrorState };
        let isValid = true;

        // Validate name
        if (!formValues.naz_akce) {
            errors.naz_akce = t('elements.actions-admin.errors.name-required');
            isValid = false;
        }
        
        if (formValues.naz_akce.length > 200) {
            errors.naz_akce = t('elements.actions-admin.errors.name-too-long');
            isValid = false;
        }

        if (!formValues.id_misto) {
            errors.id_misto = t('elements.actions-admin.errors.venue-required');
            isValid = false;
        }

        if (!formValues.id_zamer) {
            errors.id_zamer = t('elements.actions-admin.errors.focus-required');
            isValid = false;
        }

        if (!formValues.id_typ) {
            errors.id_zamer = t('elements.actions-admin.errors.type-required');
            isValid = false;
        }

        if (formValues.dat_zac && !isISODate(formValues.dat_zac)) {
            errors.dat_zac = t('elements.actions-admin.errors.date-invalid');
            isValid = false;
        }

        if (formValues.cas_zac && !isValidTime(formValues.cas_zac)) {
            errors.cas_zac = t('elements.actions-admin.errors.time-invalid');
            isValid = false;
        }

        if (formValues.dat_kon && !isISODate(formValues.dat_kon)) {
            errors.dat_kon = t('elements.actions-admin.errors.date-invalid');
            isValid = false;
        }

        if (formValues.cas_kon && !isValidTime(formValues.cas_kon)) {
            errors.cas_kon = t('elements.actions-admin.errors.time-invalid');
            isValid = false;
        }

        if (formValues.cena && formValues.cena < 0) {
            errors.cena = t('elements.actions-admin.errors.price-invalid');
            isValid = false;
        } else if (formValues.cena && isNaN(formValues.cena)) {
            errors.pockap_akce = t('elements.actions-admin.errors.price-invalid');
            isValid = false;
        }

        if (!formValues.id_prod) {
            errors.id_prod = t('elements.actions-admin.errors.production-required');
            isValid = false;
        }

        if (formValues.web_akce && formValues.web_akce.length > 255) {
            errors.web_akce = t('elements.actions-admin.errors.web-too-long');
            isValid = false;
        }

        if (formValues.popis_akce && formValues.popis_akce.length > 255) {
            errors.popis_akce = t('elements.actions-admin.errors.description-too-long');
            isValid = false;
        }

        if (formValues.dalkap_akce && formValues.dalkap_akce.length > 255) {
            errors.dalkap_akce = t('elements.actions-admin.errors.dalkap-too-long');
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const saveRecord = (newRecord: boolean, saveMessage: string) => {
        if (!validateForm()) {
            console.log('Form is invalid');
            return;
        }
    
        const endpoint = newRecord ? 'action/' : `action/id/${formValues.id_akce}/`;
        const method = newRecord ? 'post' : 'put';
        const errors = { ...initialErrorState };

        if (!formValues.id_mena) 
            formValues.id_mena = 1;

        axiosInstance[method](`${serverUrl}${endpoint}`, formValues)
        .then(response => {
            // Handle successful submission (e.g., display a message or redirect)
            // Reset errors on success
            const savedRecord = response.data;
            setIsSaveSuccessDialogOpen(true); // Open the dialog on successful save
            setSaveDialogMessage(saveMessage);
            setFormErrors(initialErrorState);
            fetchActions(savedRecord);
        })
        .catch(error => {
            if (error.response && error.response.status === 409) {
                // Handle the specific case for 409 Conflict
                // For example, set a custom error message
                //id_misto', 'dat_zac', 'cas_zac'
                errors.id_misto = t('elements.actions-admin.errors.unique-action');
                errors.dat_zac = t('elements.actions-admin.errors.unique-action');
                errors.cas_zac = t('elements.actions-admin.errors.unique-action');
            } else {
                // Set global error message from response if available, otherwise use a default error message
                const fieldErrors = error.response?.data || {};
                for (const field in fieldErrors) {
                    if (Object.prototype.hasOwnProperty.call(fieldErrors, field)) {
                        // Use a type assertion to tell TypeScript that field is a key of ErrorState
                        const key = field as keyof ErrorState;
                        errors[key] = fieldErrors[field][0];
                    }
                }
                errors.global = errors.non_field_errors;
                if(errors === initialErrorState) {
                    errors.global = t('elements.forms.save-error-title');
                }
            }
            setFormErrors(errors);
            setIsSaveErrorDialogOpen(true); // Open the dialog on save error
        });
    };

    // Handle form submission/creation of a new record
    const handleSubmit = () => {

        formValues.id_akce = 0;

        saveRecord(true, t('elements.forms.save-success-title'));
        setIsCreateConfirmDialogOpen(false);
    };

    const handleModify = () => {

        if (!selectedVenue) {
            return;
        }

        saveRecord(false, t('elements.forms.modify-success-title'));
        setIsModifyConfirmDialogOpen(false);
    };

    // Handle form field changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
    
        // Assuming formValues.id_stat and formValues.id_styl are set elsewhere optimally
        const updatedValue = type === 'checkbox' ? checked :
                             type === 'number' ? value === '' ? null : Number(value) :
                             value;
    
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            [name]: updatedValue
        }));
    };

    const handleStartDateChange = (date: Dayjs | null) => {
        setStartDate(date);
        if (date && date.isValid()) { // Check if date is valid
            setFormValues({
                ...formValues,
                dat_zac: date.format('YYYY-MM-DD'),
                cas_zac: date.format('HH:mm:ss')
            });
        }
    };

    const handleEndDateChange = (date: Dayjs | null) => {
        setEndDate(date);
        if (date && date.isValid()) { // Check if date is valid
            setFormValues({
                ...formValues,
                dat_kon: date.format('YYYY-MM-DD'),
                cas_kon: date.format('HH:mm:ss')
            });
        }
    };

    const openNewTab = () => {
        // Using window.open to open a new tab and keep a reference to it

        const newTab = window.open('/band_management?createNewBand', '_blank');
    };

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        setPastEventsChecked(event.target.checked);
    };

    useEffect(() => {
        if (savedBand) {
            const band_id = Number(savedBand.id);
            setSelectedBands([...selectedBands, savedBand]);
            setBands([...bands, savedBand]);
            setFormValues({
                ...formValues,
                bands_ids: [...formValues.bands_ids, band_id]
            });
            setSavedBand(null);
        }
    }, [savedBand]);

    useEffect(() => {
        fetchActions();
    }, [pastEventsChecked]);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            // Check for a specific message to ensure it's the correct event
            if (event.data.type === 'band-saved') {
                setSavedBand({id: event.data.band.id_kapela, displayName: event.data.band.naz_kapela});
            }
        };
      
        // Add event listener
        window.addEventListener('message', handleMessage);
      
        // Cleanup
        return () => window.removeEventListener('message', handleMessage);
    }, []); 

    useEffect(() => {
        if (selectedAction) {
            // Populate form fields with selected style data
            setFormValues({
                id_akce: selectedAction?.id_akce || 0,
                id_misto: selectedAction?.id_misto || 0,
                id_zamer: selectedAction?.id_zamer || 0,
                id_typ: selectedAction?.id_typ || 0,
                naz_akce: selectedAction?.naz_akce || '',
                dat_zac: selectedAction?.dat_zac || '',
                cas_zac: selectedAction?.cas_zac || '',
                dat_kon: selectedAction?.dat_kon || '',
                cas_kon: selectedAction?.cas_kon || '',
                cena: selectedAction?.cena || 0,
                id_mena: selectedAction?.id_mena || 0,
                id_prod: selectedAction?.id_prod || 0,
                web_akce: selectedAction?.web_akce || '',
                dalkap_akce: selectedAction?.dalkap_akce || '',
                popis_akce: selectedAction?.popis_akce || '',
                pockap_akce: selectedAction?.pockap_akce || 0,
                pockap2_akce: selectedAction?.pockap2_akce || 0,
                status: selectedAction?.status || 0,
                bands_ids: bandsIds || []
            });

            setSelectedFocus(focuses.find(entry => String(entry.id) === selectedAction?.id_zamer.toString()) || null);
            setSelectedVenue(venues.find(entry => String(entry.id) === selectedAction?.id_misto.toString()) || null);
            setSelectedCurrency(currencies.find(entry => String(entry.id) === selectedAction?.id_mena.toString()) || null);
            setSelectedProduction(productions.find(entry => String(entry.id) === selectedAction?.id_prod.toString()) || null);
            setSelectedType(types.find(entry => String(entry.id) === selectedAction?.id_typ.toString()) || null);
            setSelectedBands(selectedBands);
            setBands(selectedBands);
        } else {
            // Reset form fields for creating a new style
            setFormValues(initialState);
        }
    }, [selectedAction, bandsIds]);

    useEffect(() => {
        if (formValues.dat_zac && formValues.cas_zac) {
            const date = dayjs(formValues.dat_zac);
            const [hours, minutes, seconds] = formValues.cas_zac.split(':').map(time => parseInt(time, 10));
            setStartDate(date.hour(hours).minute(minutes).second(seconds));
        } else {
            setStartDate(null);
        }
    
        if (formValues.dat_kon && formValues.cas_kon) {
            const date = dayjs(formValues.dat_kon);
            const [hours, minutes, seconds] = formValues.cas_kon.split(':').map(time => parseInt(time, 10));
            setEndDate(date.hour(hours).minute(minutes).second(seconds));
        } else {
            setEndDate(null);
        }
    }, [formValues.dat_zac, formValues.cas_zac, formValues.dat_kon, formValues.cas_kon]);

    useEffect(() => {
        fetchBands(bandSearchValue);
    }, [selectedBands, bandSearchValue]);

    return (
        <Container className="centered">
        <Container className="column-down">
            <Typography variant="h6" className="centered">
                {t('elements.actions-admin.headline')}
            </Typography>
            <Grid container direction="column">
                <Autocomplete
                    className="dropdown"
                    size="small"
                    options={dropdownEntries}
                    getOptionLabel={(option) => option.displayName}
                    value={selectedDropdownEntry}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={t('elements.actions-admin.choose-action')}
                            size="small"
                            variant="outlined"
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                <Typography variant="body1">{option.displayName}</Typography>
                            </li>
                        );
                    }}
                    noOptionsText={t('elements.actions-admin.no-options')}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={handleActionAutocompleteChange}
                />
                    <FormControlLabel
                        className='title-form-checkbox'
                        control={
                            <Checkbox
                                onChange={handleCheckboxChange}
                                name="past-events"
                                checked={pastEventsChecked}
                                sx={{
                                    '& .MuiSvgIcon-root': {
                                        color: '#fe938c'
                                    }
                                }}
                            />
                        }
                        label={t('elements.actions-admin.past-events')}
                    />
                <Container className="centered form">
                    {formErrors.global && <div className="form-error-message">{formErrors.global}</div>}
                    <form noValidate>
                        <div className='form-item'>
                            <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.actions-admin.name')}
                                    name="naz_akce"
                                    required
                                    value={formValues.naz_akce}
                                    onChange={handleInputChange}
                                    error={formErrors.naz_akce === '' ? false : true}
                                    helperText={formErrors.naz_akce || ' '}
                                    inputProps={{ maxLength: 200 }}
                            />
                            <Autocomplete
                                    className="form-dropdown"
                                    size="small"
                                    options={venues}
                                    getOptionLabel={(option) => option.displayName}
                                    value={selectedVenue}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Typography variant="body1">{option.displayName}</Typography>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('elements.actions-admin.choose-venue') + ' (' + t('elements.forms.required') + ')'}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: formErrors.id_misto ? '1px solid #fe938c' : undefined,
                                        },
                                    }}
                                    noOptionsText={t('elements.actions-admin.no-options')}
                                    onChange={handleVenueAutocompleteChange}
                                />
                                {formErrors.id_misto && <div className="form-error-message">{formErrors.id_misto}</div>}
                                <Autocomplete
                                    className="form-dropdown"
                                    size="small"
                                    options={focuses}
                                    getOptionLabel={(option) => option.displayName}
                                    value={selectedFocus}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Typography variant="body1">{option.displayName}</Typography>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('elements.actions-admin.choose-focus') + ' (' + t('elements.forms.required') + ')'}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: formErrors.id_zamer ? '1px solid #fe938c' : undefined,
                                        },
                                    }}
                                    noOptionsText={t('elements.actions-admin.no-options')}
                                    onChange={handleFocusAutocompleteChange}
                                />
                                {formErrors.id_zamer && <div className="form-error-message">{formErrors.id_zamer}</div>}
                                <Autocomplete
                                    className="form-dropdown"
                                    size="small"
                                    options={types}
                                    getOptionLabel={(option) => option.displayName}
                                    value={selectedType}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Typography variant="body1">{option.displayName}</Typography>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('elements.actions-admin.choose-type') + ' (' + t('elements.forms.required') + ')'}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: formErrors.id_typ ? '1px solid #fe938c' : undefined,
                                        },
                                    }}
                                    noOptionsText={t('elements.actions-admin.no-options')}
                                    onChange={handleTypeAutocompleteChange}
                                />
                                {formErrors.id_typ && <div className="form-error-message">{formErrors.id_typ}</div>}
                                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={currentLanguage}>
                                    <DateTimePicker 
                                        className='form-date-picker'
                                        label={t('elements.actions-admin.date-start') + ' (' + t('elements.forms.required') + ')'} 
                                        value={startDate}
                                        reduceAnimations={true}
                                        onChange={handleStartDateChange}
                                        ampm={false}
                                    />
                                    {formErrors.dat_zac && <div className="form-error-message">{formErrors.dat_zac}</div>}
                                    <DateTimePicker 
                                        className='form-date-picker'
                                        label={t('elements.actions-admin.date-end') + ' (' + t('elements.forms.required') + ')'} 
                                        value={endDate}
                                        reduceAnimations={true}
                                        onChange={handleEndDateChange}
                                        ampm={false}
                                    />
                                    {formErrors.dat_kon && <div className="form-error-message">{formErrors.dat_kon}</div>}
                                </LocalizationProvider>
                                <Autocomplete
                                    className="form-dropdown"
                                    size="small"
                                    options={productions}
                                    getOptionLabel={(option) => option.displayName}
                                    value={selectedProduction}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Typography variant="body1">{option.displayName}</Typography>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('elements.actions-admin.choose-production') + ' (' + t('elements.forms.required') + ')'}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: formErrors.id_prod ? '1px solid #fe938c' : undefined,
                                        },
                                    }}
                                    noOptionsText={t('elements.actions-admin.no-options')}
                                    onChange={handleProductionAutocompleteChange}
                                />
                                {formErrors.id_prod && <div className="form-error-message">{formErrors.id_prod}</div>}
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.actions-admin.price')}
                                    name="cena"
                                    type="number"
                                    value={formValues.cena ? formValues.cena : ''}
                                    onChange={handleInputChange}
                                    error={formErrors.cena === '' ? false : true}
                                    helperText={formErrors.cena || ' '}
                                    inputProps={{ maxLength: 10 }}
                                />
                                <Autocomplete
                                    className="form-dropdown"
                                    size="small"
                                    options={currencies}
                                    getOptionLabel={(option) => option.displayName}
                                    value={selectedCurrency}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Typography variant="body1">{option.displayName}</Typography>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('elements.actions-admin.choose-currency')}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: formErrors.id_mena ? '1px solid #fe938c' : undefined,
                                        },
                                    }}
                                    noOptionsText={t('elements.actions-admin.no-options')}
                                    onChange={handleCurrencyAutocompleteChange}
                                />
                                {formErrors.id_mena && <div className="form-error-message">{formErrors.id_mena}</div>}
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.actions-admin.web')}
                                    name="web_akce"
                                    value={formValues.web_akce}
                                    onChange={handleInputChange}
                                    error={formErrors.web_akce === '' ? false : true}
                                    helperText={formErrors.web_akce || ' '}
                                    inputProps={{ maxLength: 255 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.actions-admin.other-bands')}
                                    name="dalkap_akce"
                                    value={formValues.dalkap_akce}
                                    onChange={handleInputChange}
                                    error={formErrors.dalkap_akce === '' ? false : true}
                                    helperText={formErrors.dalkap_akce || ' '}
                                    inputProps={{ maxLength: 1000 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.actions-admin.description')}
                                    name="popis_akce"
                                    value={formValues.popis_akce}
                                    onChange={handleInputChange}
                                    error={formErrors.popis_akce === '' ? false : true}
                                    helperText={formErrors.popis_akce || ' '}
                                    inputProps={{ maxLength: 1000 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.actions-admin.status')}
                                    name="status"
                                    type="number"
                                    value={formValues.status}
                                    onChange={handleInputChange}
                                    error={formErrors.status === '' ? false : true}
                                    helperText={formErrors.status || ' '}
                                    inputProps={{ maxLength: 11 }}
                                />
                                <Autocomplete
                                    className="form-dropdown"
                                    loading={bandsLoading}
                                    size="small"
                                    options={bands}
                                    autoComplete
                                    includeInputInList
                                    getOptionLabel={(option) => option.displayName}
                                    value={selectedBands}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    onChange={handleBandAutocompleteChange}
                                    onClose={() => {
                                        setBandsLoading(false);
                                        setBands([]);
                                    }}
                                    onInputChange={(event, newBandSearchValue) => {
                                        setBandSearchValue(newBandSearchValue);
                                    }}
                                    renderOption={(props, option) => (
                                        <li {...props} key={option.id}>
                                            <Typography variant="body1">{option.displayName}</Typography>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <>
                                            <TextField
                                                {...params}
                                                placeholder={t('elements.actions-admin.choose-bands') + ' (' + t('elements.forms.type') + ')'}
                                                size="small"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                    <>
                                                        {bandsLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                                        {params.InputProps.endAdornment}
                                                    </>
                                                    ),
                                                }}
                                            />
                                            <a className='link' onClick={openNewTab}>{t('elements.actions-admin.add_band')}</a>
                                        </>
                                    )}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: formErrors.id_mena ? '1px solid #fe938c' : undefined,
                                        },
                                    }}
                                    noOptionsText={t('elements.actions-admin.no-options')}
                                    multiple
                                />

                        </div>
                        <div>
                                <Button onClick={() => setIsModifyConfirmDialogOpen(true)} type="button" disabled={selectedAction ? false : true } formNoValidate>
                                    {t('elements.forms.save')}
                                </Button>
                                <Button onClick={() => setIsCreateConfirmDialogOpen(true)} disabled={isInitialState(formValues) ? true : false } formNoValidate>
                                    {t('elements.forms.create')}
                                </Button>
                                <Button onClick={clearForm} color="warning">
                                    {t('elements.forms.clear-form')}
                                </Button>
                        </div>
                    </form>
                </Container>
            </Grid>
            <Dialog
                open={isSaveSuccessDialogOpen}
                    onClose={() => setIsSaveSuccessDialogOpen(false)}
                    aria-labelledby="save-success-dialog-title"
                    aria-describedby="save-success-dialog-description"
                >
                    <DialogTitle id="save-success-dialog-title">
                        {saveDialogMessage}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveSuccessDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
            </Dialog>
            <Dialog
                open={isSaveErrorDialogOpen}
                onClose={() => setIsSaveErrorDialogOpen(false)}
                aria-labelledby="error-dialog-title"
                aria-describedby="error-dialog-description"
            >
                    <DialogTitle id="save-error-dialog-title">
                        <div className="error-message">
                        { t('elements.forms.save-error-title')  }
                        </div>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveErrorDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={isModifyConfirmDialogOpen}
                message={t('elements.venues-admin.modify-confirm')}
                onClose={() => setIsModifyConfirmDialogOpen(false)}
                onConfirmAction={() => handleModify()} />
            <ConfirmDialog
                open={isCreateConfirmDialogOpen}
                message={t('elements.venues-admin.create-confirm')}
                onClose={() => setIsCreateConfirmDialogOpen(false)}
                onConfirmAction={() => handleSubmit()} />
        </Container>
    </Container>
    );
}

function ActionManagement(): JSX.Element {

    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <ActionManagementContent />
            <Footer />
        </div>
    );
}

export default ActionManagement;
