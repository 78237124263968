import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isISODate, formatDate } from 'src/utils/dates';
import useSessionStorage from 'src/utils/sessionStorage';
import { ModalWindowContainerProps } from 'src/pages/page_elements/modalwindows/modalwindowprops';

import { RenderFilters, FilterTuple } from 'src/pages/page_elements/RenderFilters';

import countryCodesMap from 'src/pages/page_elements/data/countrycodesmap';

import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Button,
    Typography,
    TextField,
} from '@mui/material';

import 'flag-icons/css/flag-icons.min.css';
import 'src/styles/datatable.css';

const serverUrl = process.env.REACT_APP_SERVER_URL;
const itemsPerPage = 100;

export type TableColumnConfig = {
    title: string; // Display title of the column
    contentKey: string | string[]; // Key or keys from the data to display in this column
    sortableKey: string | null; // Key for sorting, or null if the column is not sortable
    className?: string; // Optional class name for the column
};

type TableTitlesProps = {
    columns: TableColumnConfig[];
    sortField: string | string[] | null; // Current sort field
    onSortChange: (newSortKey: string) => void; // Callback for when sorting changes
};

function TableTitles({
    columns,
    sortField,
    onSortChange,
}: TableTitlesProps): JSX.Element {
    return (
        <TableRow>
            {columns.map((column, index) => {
                const isSortable = !!column.sortableKey;
                let sortArrow = null;
                let className = column.className || '';

                if (sortField && sortField === column.sortableKey) {
                    sortArrow = ' ↓'; // or an appropriate down arrow icon
                } else if (sortField && sortField === `-${column.sortableKey}`) {
                    sortArrow = ' ↑'; // or an appropriate up arrow icon
                }

                if (isSortable) {
                    className += ' table-header-sort'; // This class can be used to show a visual cue for sortable columns
                }

                return (
                    <TableCell
                        className={className}
                        variant='head'
                        key={index}
                        onClick={() => {
                            if (isSortable) {
                                onSortChange(column.sortableKey!); // The '!' is used to assert non-nullability
                            }
                        }}
                    >
                        {column.title}
                        {sortArrow}
                    </TableCell>
                );
            })}
        </TableRow>
    );
}

type TableRowsProps = {
    columns: TableColumnConfig[];
    apiData: Array<{ [key: string]: string | number }>; // Data fetched from the API
    onRowClick: (entry: { [key: string]: string | number }) => void; // Callback for row click
    idKey: string; // Key for unique identifier of each row
};

function TableRows({
    columns,
    apiData,
    onRowClick,
    idKey,
}: TableRowsProps): JSX.Element {
    return (
        <>
            {apiData.map((entry) => (
                <TableRow key={entry[idKey]} onClick={() => onRowClick(entry)}>
                    {columns.map((column, index) => {
                        let className = column.className || '';
                        if (index === 0) {
                            className += ' table-underlined';
                        }

                        return (
                            <TableCells
                                key={index}
                                className={className}
                                cellContent={column.contentKey}
                                entry={entry}
                            />
                        );
                    })}
                </TableRow>
            ))}
        </>
    );
}

type TableCellsProps = {
    cellContent: string | Array<string>;
    entry: { [key: string]: string | number };
    className: string;
};

function TableCells({
    cellContent,
    entry,
    className,
}: TableCellsProps): JSX.Element {
    switch (typeof cellContent) {
        case 'string': {
            let content = entry[cellContent];
            // If the content is a date, format it
            if (typeof content === 'string' && isISODate(content)) {
                content = formatDate(content);
            }

            return <TableCell className={className}>{content}</TableCell>;
        }
        case 'object': // an array is technically an object
            return (
                <TableCell className={className}>
                    {cellContent.map((subKeyForData: string, subId: number) => {
                        const value = entry[subKeyForData];
                        if (value !== undefined) {
                            if (subKeyForData === 'state_code') {
                                return (
                                    <React.Fragment key={subId}>
                                        <div
                                            className={`fi fi-${countryCodesMap[value]}`}
                                        />
                                    </React.Fragment>
                                );
                            } else {
                                return (
                                    <React.Fragment key={subId}>
                                        <span>{value}</span>
                                    </React.Fragment>
                                );
                            }
                        } else {
                            return (
                                <React.Fragment key={subId}>
                                    {subKeyForData}
                                </React.Fragment>
                            );
                        }
                    })}
                </TableCell>
            );
        default:
            console.log(cellContent);
            return (
                <TableCell>
                    Unknown error with cell: {cellContent}, logging to
                    console...
                </TableCell>
            );
    }
}

type TableNavigatorProps = {
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
};

function TableNavigator({
    currentPage,
    totalPages,
    onPageChange,
}: TableNavigatorProps): JSX.Element {
    const { t } = useTranslation();

    const [pageInput, setPageInput] = useState<string>('');

    const handlePageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPageInput(e.target.value);
    };

    const goToPage = () => {
        const pageNumber = parseInt(pageInput);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            onPageChange(pageNumber);
            setPageInput('');
        }
    };

    const handleKeyPressForInput = (
        event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            goToPage();
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const getPageButtons = (): Array<JSX.Element> => {
        const pageButtons: Array<JSX.Element> = [];

        if (totalPages <= 10) {
            // If there are 10 or fewer pages, display all page buttons
            for (let i = 1; i <= totalPages; i++) {
                pageButtons.push(
                    <Button
                        key={i}
                        className={i === currentPage ? 'active' : ''}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </Button>,
                );
            }
        } else {
            // If there are more than 10 pages, display dynamic page buttons
            let startPage: number;
            let endPage: number;

            if (currentPage <= 6) {
                startPage = 1;
                endPage = 10;
            } else if (currentPage >= totalPages - 5) {
                startPage = totalPages - 9;
                endPage = totalPages;
            } else {
                startPage = currentPage - 4;
                endPage = currentPage + 4;
            }

            if (startPage > 1) {
                pageButtons.push(
                    <Button key={1} onClick={() => onPageChange(1)}>
                        1...
                    </Button>,
                );
            }

            for (let i = startPage; i <= endPage; i++) {
                pageButtons.push(
                    <Button
                        key={i}
                        className={i === currentPage ? 'active' : ''}
                        onClick={() => onPageChange(i)}
                    >
                        {i}
                    </Button>,
                );
            }

            if (endPage < totalPages) {
                pageButtons.push(
                    <Button
                        key={totalPages}
                        onClick={() => onPageChange(totalPages)}
                    >
                        ...{totalPages}
                    </Button>,
                );
            }
        }

        return pageButtons;
    };

    return (
        <div
            className="table-navigator"
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
        >
            <Typography variant="body1" className="table-navigator-text">
                {t('elements.tablefetch.page-counter', {
                    currentPage,
                    totalPages,
                })}
            </Typography>
            <div className="table-navigator-buttons">{getPageButtons()}</div>
            <div className="table-navigator-leftright-input">
                <Button onClick={goToPreviousPage} disabled={currentPage === 1}>
                    {'<'}
                </Button>
                <TextField
                    className="table-navigator-page"
                    value={pageInput}
                    onChange={handlePageChange}
                    onKeyPress={handleKeyPressForInput}
                    placeholder={t('elements.tablefetch.page-input')}
                />
                <Button onClick={goToPage}>
                    {t('elements.tablefetch.page-go')}
                </Button>
                <Button
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                >
                    {'>'}
                </Button>
            </div>
        </div>
    );
}

type TableSearchProps = {
    onSearch: (query: string) => void;
    initialQuery?: string;
};

function TableSearch({
    onSearch,
    initialQuery = '',
}: TableSearchProps): JSX.Element {
    const { t } = useTranslation();

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onSearch(e.target.value);
    };

    return (
        <div className='search-bar-container'>
            <TextField
                className="search-bar"
                type="text"
                value={initialQuery}
                placeholder={t('elements.tablefetch.search-bar')}
                variant="outlined"
                fullWidth
                onChange={handleChange}
            />
        </div>
    );
}

type TableFiltersProps = {
    showControls: boolean;
    handleSearch: (query: string) => void;
    searchQuery?: string;
    onResetClick: () => void;
    tableFilters?: Array<FilterTuple>; // Array of tuples of filter type and SQL field
    handleFilterChange: (updates: [string, string[]][]) => void; // Function to handle filter changes
    values: { [key: string]: string[] }; // Values of the filters
};

function TableFilters({
    showControls,
    handleSearch,
    searchQuery,
    onResetClick,
    tableFilters,
    handleFilterChange,
    values, 
}: TableFiltersProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <div className='filter-table-section'>
            {showControls &&
                <>
                    <Typography className="filter-selector">
                        {t('elements.tablefetch.filters')}
                    </Typography>
                    <TableSearch onSearch={handleSearch} initialQuery={searchQuery} />
                </>
            }
            <RenderFilters tableFilters={tableFilters} handleFilterChange={handleFilterChange} values={values} />
            {showControls &&
                <TableReset onClick={onResetClick} />
            }
            {!showControls && 
                <>
                    <div className="right-aligned">
                        {t('elements.tablefetch.detailed-more')}
                        <a href="/actions/">
                            {t('elements.tablefetch.detailed-actions')}
                        </a>
                         {t('elements.tablefetch.detailed-and')}
                        <a href="/openairs/">
                            {t('elements.tablefetch.detailed-openairs')}
                        </a>
                    </div>
                </>
            }
        </div>
    );
}

type TableResetProps = {
    onClick: () => void;
};

function TableReset({ onClick }: TableResetProps): JSX.Element {
    const { t } = useTranslation();

    return (
        <Button className='button' size='small' onClick={onClick}>
            {t('elements.tablefetch.reset')}
        </Button>
    );
}

type TableProps = {
    defaultSort?: string | null; // Default sort field
    apiPath: string;
    idKey: string;
    tableType: string | null;
    OnClickComponent?: React.ComponentType<ModalWindowContainerProps>; // Modal window
    onRowClick?: (entry: string) => void; // On row click. Mutually exclusive with OnClickComponent
    showControls?: boolean; // Whether to show the search bar and filters
    urlParams?: { [key: string ]: string | boolean| null }; // URL parameters
    emptyMessage?: string; // Optional message to display when no rows are available
    tableTitle?: string; // Optional title to display above the table
    containerClassName?: string; // Optional class name for the container
    tableFilters?: Array<FilterTuple>; // Array of tuples of filter type and SQL field
    showFilters?: boolean; // Wether to show filters
    tableColumns: TableColumnConfig[];
};

function DataTable({
    defaultSort: defaultSort = null,
    apiPath,
    idKey,
    tableType,
    OnClickComponent,
    onRowClick,
    showControls = true, // Whether to show the search bar and filters, default true
    urlParams, // URL parameters
    emptyMessage, // Default message if none is provided
    tableTitle,
    containerClassName = 'component-container',
    tableFilters,
    showFilters = true,
    tableColumns
}: TableProps): JSX.Element {
    const { t } = useTranslation();

    const [filters, setFilters, removeFilters] = useSessionStorage<{ [key: string]: string[] }>(
        'filters' + tableType,
        {},
    );
    const [data, setData] = useState<Array<{ [key: string]: string | number }>>(
        [],
    );
    const [error, setError] = useState<string | null>(null);

    const [searchQuery, setSearchQuery, removeSearchQuery]  = useSessionStorage(
        'searchQuery' + tableType,
        '',
    );

    const [currentPage, setCurrentPage, removeCurrentPage] = useSessionStorage(
        'currentPage' + tableType,
        1,
    );
    const [totalPages, setTotalPages] = useState<number>(0);

    const [sortField, setSortField, removeSortField] = useSessionStorage<string | string[] | null>(
        'sortField' + tableType,
        null,
    );

    useEffect(() => {
        // If there is no column chosen for sorting, use the first sortable one
        if (!sortField) {
            const firstSortableColumn = tableColumns.find(column => column.sortableKey);
            if (firstSortableColumn) {
                setSortField(defaultSort || firstSortableColumn.sortableKey);
            }
        }
    }, []);

    const [selectedEntry, setSelectedEntry] = useState<{
        [key: string]: string | number;
    } | null>(null); // for modal window click

    useEffect(() => {
        if (onRowClick && selectedEntry) {
            onRowClick(selectedEntry[idKey]?.toString());
        }
    }, [selectedEntry]);

    const fetchData = (page: number) => {
        let url = `${serverUrl}${apiPath}?page=${page}`;
        if (sortField) {
            url += `&sort_by=${sortField}`;
        }
        if (searchQuery) {
            url += `&search_term=${searchQuery}`;
        }
        if (urlParams) { // Check if urlParams is provided and append them to the URL
            for (const [key, value] of Object.entries(urlParams)) {
                url += `&${key}=${value}`;
            }
        }
        if (filters) {
            for (const [key, value] of Object.entries(filters)) {
                if (Array.isArray(value)) {
                    url += `&${key}=${value.join(',')}`; // If the filter value is an array, join it with commas
                } else {
                    url += `&${key}=${value}`;
                }
            }
        }
        fetch(url)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data from the server');
                }
                return response.json();
            })
            .then((data) => {
                setData(data.results);
                setTotalPages(Math.ceil(data.count / itemsPerPage));
            })
            .catch((error) => {
                setError(error.message);
                console.log(error);
            });
    };

    const handleSortChange = (field: string) => {
        let newSortField = field;
        if (sortField === field || !sortField) {
            newSortField = `-${field}`;
        } else if (sortField === `-${field}`) {
            newSortField = field;
        }
        setSortField(newSortField);
    };

    const handleRetry = () => {
        setError(null);
        fetchData(currentPage);
    };

    const handleSearch = (query: string) => {
        setCurrentPage(1);
        setSearchQuery(query);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Function to handle row clicks
    const handleRowClick = (entry: { [key: string]: string | number }) => {
        setSelectedEntry(entry);
    };

    // Function to handle modal window close
    const handleCloseModal = () => {
        setSelectedEntry(null);
    };

    const handleReset = () => {
        setSearchQuery('');
        setCurrentPage(1);
        setSortField(defaultSort || null);
        removeSearchQuery();
        removeCurrentPage();
        removeFilters(); // Clear filters from SessionStorage
    };

    useEffect(() => {
        fetchData(currentPage);
    }, [filters]);

    const handleFilterChange = (updates: [string, string[]][]) => {
        setFilters(prevFilters => {
            const newFilters = {...prevFilters};
            updates.forEach(([field, value]) => {
                newFilters[field] = value;
            });
            return newFilters;
        });
        removeCurrentPage();
    };    

    useEffect(() => {
        fetchData(currentPage);
    }, [currentPage, sortField, searchQuery]);

    let tableContent;
    if (error) {
        tableContent = (
            <div className="error">
                <div className="error-message">{error}</div>
                <Button className="retry-button" onClick={handleRetry}>
                    { t('elements.tablefetch.retry') }
                </Button>
            </div>
        );
    } else if (data.length === 0) {
        tableContent = (
            <div className="no-data-message">
                {emptyMessage || t('elements.tablefetch.norows')}
            </div>
        );
    } else {
        tableContent = (
            <>
                <TableContainer className='table'>
                <Table size="small">
                    <TableHead>
                        <TableTitles
                            columns={tableColumns} // Pass the new structure here
                            sortField={sortField}
                            onSortChange={handleSortChange}
                        />
                    </TableHead>
                    <TableBody>
                        <TableRows
                            columns={tableColumns} // Pass the new structure here
                            apiData={data}
                            onRowClick={handleRowClick}
                            idKey={idKey}
                        />
                    </TableBody>
                </Table>
                </TableContainer>
            </>
        );
    }

    return (
        <div className={containerClassName}>
            {tableTitle && <div className='space-after table-title'>{tableTitle}</div>}
            {showFilters &&
                <TableFilters 
                    tableFilters={tableFilters}
                    handleSearch={handleSearch}
                    searchQuery={searchQuery}
                    onResetClick={handleReset}
                    handleFilterChange={handleFilterChange}
                    values={filters}
                    showControls={showControls}
                />
            }
            {tableContent}
            {OnClickComponent && (
                <OnClickComponent
                    isOpen={!!selectedEntry}
                    onClose={handleCloseModal}
                    rowData={selectedEntry}
                />
            )}
            <TableNavigator
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
}

export default DataTable;
