import { useState } from 'react';

function useSessionStorage<T>(key: string, initialValue: T) {
  // Get from sessionStorage or use the provided initialValue
  const storedValue = sessionStorage.getItem(key);
  const initial = storedValue ? JSON.parse(storedValue) : initialValue;

  // State to store our value
  const [value, setValue] = useState<T>(initial);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setStoredValue = (newValue: T | ((val: T) => T)) => {
    try {
      // Allow newValue to be a function so we have the same API as useState
      const valueToStore =
        newValue instanceof Function ? newValue(value) : newValue;

      // Save to state
      setValue(valueToStore);

      // Save to sessionStorage
      sessionStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(`Error setting sessionStorage for key "${key}":`, error);
    }
  };

  // Function to remove the value from sessionStorage
  const removeStoredValue = () => {
    try {
      // Remove the key from sessionStorage
      sessionStorage.removeItem(key);

      // Reset the state
      setValue(initialValue);
    } catch (error) {
      console.error(`Error removing sessionStorage key "${key}":`, error);
    }
  };

  return [value, setStoredValue, removeStoredValue] as const;
}

export default useSessionStorage;
