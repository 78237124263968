import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';

import { TextField, Container, Typography, Button, Grid } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';

import { axiosInstance } from 'src/utils/tokenManagement';

import 'src/styles/form.css';

const serverUrl = process.env.REACT_APP_SERVER_URL;

type StyleEntry = {
    style_id: string;
    style_name: string;
    style_group: string;
};

export type DropdownEntry = {
    id: string;
    displayName: string;
    //group: string;
};

type MultiChoiceEntry = {
    id_styl: string;
    id_styl_name: string;
};

type StyleGroupResponseItem = {
    id_styl: string;
    id_styl_name: string;
};

interface BackendError {
    non_field_errors: string[];
}

interface GraphNode extends d3.SimulationNodeDatum {
    id: string;
    name: string;
}

interface GraphLink {
    source: GraphNode;
    target: GraphNode;
}

interface FetchStyleChildrenProps {
    styleId: string;
    setPreSelectedOptions?: React.Dispatch<React.SetStateAction<DropdownEntry[]>>;
}
async function fetchStyleChildren({ styleId, setPreSelectedOptions }: FetchStyleChildrenProps) {
    try {
        const response = await axiosInstance.get(`${serverUrl}styles_group/${styleId}/`);
        const fetchedOptions = response.data.map((item: StyleGroupResponseItem) => ({
            id: item.id_styl,
            displayName: item.id_styl_name,
        }));
        if (setPreSelectedOptions)
            setPreSelectedOptions(fetchedOptions);

        return fetchedOptions;
    } catch (error) {
        console.error('Failed to fetch style groups:', error);
    }
}

interface Node {
    id: string;
    name: string;
    x: number;
    y: number;
}

interface Link {
    source: Node;
    target: Node;
}

async function processGrandchildren(
    childStyles: DropdownEntry[], // All styles that are direct children of the parent node
    nodes: Node[], 
    links: Link[], 
    parentNode: Node, // Parent node of the subgraph
    depth: number = 0, // Depth of the recursion
    iteration: number = 0 // Iteration of the parent node (kolikaty je rodic v poradi)
): Promise<void> {
    for (const childStyle of childStyles) {
        const grandchildrenStyles = await fetchStyleChildren({ styleId: childStyle.id });
        let vertical = 1;
        grandchildrenStyles.forEach((grandchild: DropdownEntry) => {
            const grandChildNode: Node = {
                id: grandchild.id,
                name: grandchild.displayName,
                x: (depth+1)*150,
                y: (vertical++)*50+iteration*150//+depth*50,
            };
            nodes.push(grandChildNode);
            links.push({
                source: nodes.find(node => node.id === childStyle.id) || parentNode,
                target: grandChildNode
            });
        });
        await processGrandchildren(grandchildrenStyles, nodes, links, parentNode, depth + 1, iteration++);
    }
}

interface D3GraphProps {
    childStyles: DropdownEntry[]; // Direct children of the selected style
    groupStyle: DropdownEntry | null; // The selected style
}
function D3Graph({ childStyles: childStyles, groupStyle: groupStyle }: D3GraphProps) {
    const d3Container = useRef(null);

    useEffect(() => {
        if (d3Container.current && groupStyle) {
            const svg = d3.select(d3Container.current);
            svg.selectAll('*').remove();

            // Node for the selected entry
            const groupStyleNode = {
                id: groupStyle.id,
                name: groupStyle.displayName,
                x: 30, // Center of the svg
                y: 300  // Center of the svg
            };

            let vertical = 1;

            // Nodes for children
            const childNodes = childStyles.map(option => ({
                id: option.id,
                name: option.displayName,
                x: 200,
                y: (vertical++)*150
            }));

            const nodes = [groupStyleNode, ...childNodes];

            // Create links between selected node and each pre-selected option
            const links = childNodes.map(option => ({
                source: groupStyleNode,
                target: option
            }));

            processGrandchildren(childStyles, nodes, links, groupStyleNode, 1, 0)
            .then(() => {
                for (const childStyle of childStyles) {
                    // Define the arrow marker
                    svg.append('defs').append('marker')
                        .attr('id', 'arrow')
                        .attr('viewBox', '0 -5 10 10')
                        .attr('refX', 15) // Adjust this to position the arrowhead
                        .attr('refY', 0)
                        .attr('markerWidth', 6)
                        .attr('markerHeight', 6)
                        .attr('orient', 'auto')
                        .append('path')
                        .attr('d', 'M0,-5L10,0L0,5')
                        .attr('fill', '#999');

                    // Draw links with arrowheads
                    svg.append('g')
                        .attr('stroke', '#999')
                        .attr('stroke-opacity', 0.6)
                        .selectAll('line')
                        .data(links)
                        .join('line')
                        .attr('stroke-width', 2)
                        .attr('marker-end', 'url(#arrow)') // Use the arrow marker
                        .attr('x1', d => d.source.x)
                        .attr('y1', d => d.source.y)
                        .attr('x2', d => d.target.x)
                        .attr('y2', d => d.target.y);

                    // Draw circles for the nodes
                    svg.append('g')
                        .attr('stroke', '#fff')
                        .attr('stroke-width', 1.5)
                        .selectAll('circle')
                        .data(nodes)
                        .join('circle')
                        .attr('r', 5)
                        .attr('fill', d => d.id === groupStyle.id ? 'red' : 'blue')
                        .attr('cx', d => d.x)
                        .attr('cy', d => d.y);

                    // Add labels to the nodes
                    svg.append('g')
                        .attr('class', 'labels')
                        .selectAll('text')
                        .data(nodes)
                        .enter()
                        .append('text')
                        .attr('dx', 12)
                        .attr('dy', '.35em')
                        .text(d => d.name)
                        .attr('x', d => d.x)
                        .attr('y', d => d.y)
                        .attr('fill', '#fff');

                }
            });
        }
    }, [childStyles, groupStyle]);

    return (
        <svg
            className="d3-component"
            width={1200}
            height={2000}
            ref={d3Container}
        />
    );
}

function StylesGroupsContent(): JSX.Element {
    const { t, i18n } = useTranslation();
    const [allStyles, setAllStyles] = useState<DropdownEntry[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [groupStyle, setGroupStyle] = useState<DropdownEntry | null>(null);

    const [multiChoiceOptions, setMultiChoiceOptions] = useState<MultiChoiceEntry[]>([]);
    const [selectedMultiChoiceOptions, setSelectedMultiChoiceOptions] = useState<MultiChoiceEntry[]>([]);
    const [childStyles, setChildStyles] = useState<DropdownEntry[]>([]);

    const [isSaveSuccessDialogOpen, setIsSaveSuccessDialogOpen] = useState(false);
    const [saveError, setSaveError] = useState('');

    const fetchStyles = () => {
        fetch(`${serverUrl}styles/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data from the server');
                }
                return response.json();
            })
            .then((data) => {
                const transformedEntries = data.map((entry: StyleEntry) => {
                    return {
                        id: entry.style_id,
                        displayName: entry.style_name,
                        //group: entry.style_group
                    };
                });
                // Sort the transformedEntries by group
                /*const sortedEntries = transformedEntries.sort((a: DropdownEntry, b: DropdownEntry) => {
                    if (a.group && b.group) {
                        return a.group.localeCompare(b.group);
                    }
                    return 0;
                });*/

                //setAllStyles(sortedEntries);
                setAllStyles(transformedEntries);
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchStyles();
    }, []);

    const handleAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownEntry | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry>
    ) => {
        if (reason === 'clear') {
            setChildStyles([]);
            setGroupStyle(null);
        } else if (value) {
            fetchStyleChildren({ styleId: value.id, setPreSelectedOptions: setChildStyles });
            setGroupStyle(value);
        }
    };

    const saveStylesGroups = () => {
        if (groupStyle) {
            const payload = childStyles.map(option => {
                return {
                    id_styl: option.id,
                    id_styl_group: groupStyle.id
                };
            });

            axiosInstance.post(`${serverUrl}styles_group/`, payload)
                .then(response => {
                    // Handle successful response
                    setIsSaveSuccessDialogOpen(true); // Open the dialog on successful save
                })
                .catch(error => {
                    // Handle error
                    console.error('Error saving styles groups:', error);
                    // Extract the error message
                    const errorMessage = error.response.data?.map((err: BackendError) => err.non_field_errors).join(', ') || 'Unknown error';
                    setSaveError(errorMessage);
                });
        } else {
            // Handle case where no dropdown entry is selected
            console.error('No style group selected');
        }
    };

    const [graphKey, setGraphKey] = useState(0);

    useEffect(() => {
        // Update the key when childStyles changes
        setGraphKey(prevKey => prevKey + 1);
    }, [childStyles]);

    return (
        <Container className="centered">
            <Container className="column-down">
                <Typography variant="h6" className="centered">
                    {t('elements.styles-groups-admin.headline')}
                </Typography>
                <Grid container direction="column">
                    <Autocomplete
                        className="dropdown"
                        size="small"
                        options={allStyles}
                        getOptionLabel={(option) => option.displayName}
                        value={groupStyle}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('elements.styles-groups-admin.choose-style')}
                                size="small"
                                variant="outlined"
                            />
                        )}
                        noOptionsText={t('elements.styles-groups-admin.no-options')}
                        onChange={handleAutocompleteChange}
                    />
                    <Container className="centered form">
                        {groupStyle && (
                            <>
                                <Autocomplete
                                    multiple
                                    className="dropdown"
                                    size="small"
                                    options={allStyles}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    getOptionLabel={(option: DropdownEntry) => option.displayName}
                                    value={childStyles}
                                    onChange={(event, newValue: DropdownEntry[]) => {setChildStyles(newValue);}}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('elements.styles-groups-admin.choose-multiple-styles')}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    noOptionsText={t('elements.styles-groups-admin.no-options')}
                                />
                                <Button onClick={saveStylesGroups}>
                                    {t('elements.styles-groups-admin.save-button')}
                                </Button>
                            </>
                        )}
                    </Container>
                    <D3Graph
                        key={graphKey}
                        childStyles={childStyles}
                        groupStyle={groupStyle}
                    />
                </Grid>
                <Dialog
                    open={isSaveSuccessDialogOpen}
                    onClose={() => setIsSaveSuccessDialogOpen(false)}
                    aria-labelledby="save-success-dialog-title"
                    aria-describedby="save-success-dialog-description"
                >
                    <DialogTitle id="save-success-dialog-title">
                        {t('elements.forms.save-success-title')}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveSuccessDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={!!saveError}
                    onClose={() => setSaveError('')}
                    aria-labelledby="save-error-dialog-title"
                    aria-describedby="save-error-dialog-description"
                >
                    <DialogTitle id="save-error-dialog-title">
                        {t('elements.forms.save-error-title')}
                    </DialogTitle>
                    <Typography id="save-error-dialog-description">
                        {saveError}
                    </Typography>
                    <DialogActions>
                        <Button onClick={() => setSaveError('')}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Container>
    );
}

function StylesGroups(): JSX.Element {

    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <StylesGroupsContent />
            <Footer />
        </div>
    );
}

export default StylesGroups;
