// QuickFilterAutocomplete.tsx
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { AutocompleteChangeDetails, AutocompleteChangeReason } from '@mui/material/Autocomplete';

import { useTranslation } from 'react-i18next';

interface OptionType {
  label: string;
  value: string;
}

interface QuickFilterAutocompleteProps {
  options: OptionType[];
  sqlField: string;
  onFilterChange: (updates: [string, string[]][]) => void;
  value: string[];
  defaultString: string;
}

const QuickFilterAutocomplete: React.FC<QuickFilterAutocompleteProps> = ({ options: options, sqlField, onFilterChange, value, defaultString }) => {
    const { t } = useTranslation();

    const handleFilterChange = (
        event: React.SyntheticEvent, 
        newValue: OptionType | null, 
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<OptionType>
    ) => {
        onFilterChange([[sqlField, newValue ? [newValue.value] : []]]);
    };

    return (
            <Autocomplete
                className="table-filter-quick"
                size="small"
                options={options}
                sx={{ width: 150 }}
                getOptionLabel={(option) => option.label}
                value={options.find(entry => entry.value === value[0]) || null}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={defaultString}
                        size="small"
                        variant="standard"
                    />
                )}
                noOptionsText={t('elements.tablefetch.filter-labels.no-options')}
                onChange={handleFilterChange}
            />
    );
};

export default QuickFilterAutocomplete;
