import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import DataTable, {TableColumnConfig} from 'src/pages/page_elements/DataTable';

import { mobileWindowWidth } from 'src/utils/constants';

type BandsTableProps = {
    showControls?: boolean;
    urlParams?: { [key: string ]: string | boolean| null }; // URL parameters
    emptyMessage?: string;
    tableTitle?: string; // Optional title to display above the table
    tableType?: string | null;
    showFilters?: boolean;
};
function BandsTable({ 
    showControls, 
    urlParams, 
    emptyMessage, 
    tableTitle, 
    tableType = 'bands',
    showFilters = true
}: BandsTableProps): JSX.Element {
    const { t } = useTranslation();

    const navigate = useNavigate(); // Call the hook here

    // State to hold the window width
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // Effect to update the state on window resize
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Determine if it's a mobile view
    const isMobileView = windowWidth <= mobileWindowWidth;

    const bandsTableColumns: TableColumnConfig[] = [
        {
            title: t('elements.bandstable.table-titles.name'),
            contentKey: 'naz_kapela',
            sortableKey: 'naz_kapela',
        },
        {
            title: t('elements.bandstable.table-titles.style'),
            contentKey: 'style',
            sortableKey: 'id_styl__naz_styl',
            className: isMobileView ? 'hide-on-mobile' : '',
        },
        {
            title: t('elements.bandstable.table-titles.year'),
            contentKey: 'foundation',
            sortableKey: 'vznik_kapela',
        },
        {
            title: t('elements.bandstable.table-titles.state'),
            contentKey: ['state_code', ' ', 'state'], // if an array instead of a string is passed, it will put all the values in one cell
            sortableKey: 'id_stat__naz_stat',
            className: isMobileView ? 'hide-on-mobile' : '',
        },
    ];

    const apiPath = 'bands/';
    const idKey = 'id_kapela';

    return (
        <DataTable
            apiPath={apiPath}
            tableType={tableType}
            idKey={idKey}
            onRowClick={(bandId) => {
                navigate(`/band/?bandId=${bandId}`);
            }}
            containerClassName='component-container-fixed-width'
            showControls={showControls}
            urlParams={urlParams}
            emptyMessage={emptyMessage}
            tableTitle={tableTitle}
            tableFilters={[
                ['dropdown', ['starts_with'], 'StartsWithFilter'],
                ['dropdown', ['state_ids'], 'StatesFilter'],
                ['dropdown', ['style_ids'], 'StylesFilter'],
                ['dropdown', ['year'], 'YearFilter']
            ]}
            showFilters={showFilters}
            tableColumns={bandsTableColumns}
        />
    );
}

export default BandsTable;
