import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // Import the hook
import { formatDate } from 'src/utils/dates';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import BandsTable from './page_elements/BandsTable'; 
import { Container, Button, Typography, Divider } from '@mui/material';

import 'flag-icons/css/flag-icons.min.css';
import 'src/styles/centeredelement.css';

const serverUrl = process.env.REACT_APP_SERVER_URL;

type formatTimeProps = {
    timeString: string;
};
function formatTime({timeString}: formatTimeProps) {
    return timeString.slice(0, 5);
}

function ActionContent(): JSX.Element {
    const { t } = useTranslation();

    const navigate = useNavigate();

    const [actionData, setActionData] = useState<{
        [key: string]: string | number;
    } | null>(null);

    const [error, setError] = useState<string | null>(null);

    const query = new URLSearchParams(location.search);
    const actionId = query.get('actionId');

    const fetchactionData = (actionId: number) => {
        fetch(`${serverUrl}action/${actionId}/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data from the server');
                }
                return response.json();
            })
            .then((data) => {
                setActionData(data);
            })
            .catch((error) => {
                setError(error.message);
                console.log(error);
            });
    };

    useEffect(() => {
        if (actionId !== null) {
            const actionIdNumber = parseInt(actionId, 10);
            if (!isNaN(actionIdNumber)) {
                fetchactionData(actionIdNumber);
            } else {
                setError('Invalid actionId');
            }
        } else {
            setError('No actionId provided');
        }
    }, []);

    let actionName;
    if (actionData && actionData.naz_akce) {
        actionName = actionData.naz_akce;
    }

    return (
        <Container className="centered">
            <Container className="column-down component-container">
                <Button
                    className="button"
                    size="small"
                    onClick={() => navigate(-1)}
                >
                    {' '}
                    {t('backButton')}
                </Button>
                <Typography variant="h5" paragraph>
                    <span>
                        {t('elements.actiondetail.name')}: {actionName}
                    </span>
                </Typography>
                {actionData && (
                    <>
                        <div className="data-row">
                            <strong>{t('elements.actiondetail.type')}:</strong>{' '}
                            <span>{actionData.typ}</span>
                        </div>
                        <div className="data-row">
                            <strong>{t('elements.actiondetail.focus')}:</strong>{' '}
                            <span>{actionData.focus}</span>
                        </div>
                        <div className="data-row">
                            <strong>
                                {t('elements.actiondetail.location')}:
                            </strong>{' '}
                            <span>{actionData.location}</span>
                        </div>
                        <div className="data-row">
                            <strong>{t('elements.actiondetail.start')}:</strong>{' '}
                            <span>
                                {formatDate(actionData.dat_zac.toString())} -{' '}
                                {formatTime({
                                    timeString: actionData.cas_zac.toString(),
                                })}
                            </span>
                        </div>
                        <div className="data-row">
                            <strong>{t('elements.actiondetail.end')}:</strong>{' '}
                            <span>
                                {formatDate(actionData.dat_kon.toString())} -{' '}
                                {formatTime({
                                    timeString: actionData.cas_kon.toString(),
                                })}
                            </span>
                        </div>
                        <div className="data-row">
                            <strong>{t('elements.actiondetail.price')}:</strong>{' '}
                            <span>
                                {actionData.cena} {actionData.currency}
                            </span>
                        </div>
                        <div className="data-row">
                            <strong>
                                {t('elements.actiondetail.website')}:
                            </strong>{' '}
                            {actionData.web_akce ? (
                                <a
                                    href={String(actionData.web_akce)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {String(actionData.web_akce).length > 35
                                        ? `${String(
                                              actionData.web_akce,
                                          ).substring(0, 35)}...`
                                        : actionData.web_akce}
                                </a>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                        <div className="data-row">
                            <strong>
                                {t('elements.actiondetail.others')}:
                            </strong>{' '}
                            <span>{actionData.other_bands}</span>
                        </div>
                        <div>
                            <strong>
                                {t('elements.actiondetail.description')}:
                            </strong>{' '}
                            <span>
                                {actionData.popis_akce
                                    ? actionData.popis_akce
                                    : '-'}
                            </span>
                        </div>
                    </>
                )}
                {error && (
                    <p>
                        {t('Error')}: {error}
                    </p>
                )}
            </Container>
            <BandsTable
                tableTitle={t('elements.actiondetail.bands')}
                showControls={false}
                urlParams={{ action_id: actionId }}
                emptyMessage={t('elements.actiondetail.no_bands')}
                tableType={`actions_${actionId}`}
                showFilters={false}
            />
        </Container>
    );
}

function Action(): JSX.Element {
    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <ActionContent />
            <Footer />
        </div>
    );
}

export default Action;
