import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import { TextField, Container, Typography, Button, Grid } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import ConfirmDialog from './page_elements/ConfirmDialog';

import { axiosInstance } from 'src/utils/tokenManagement';

import { useAuth } from 'src/utils/useAuth'; // Import the useAuth hook

import { isValidTime } from 'src/utils/dates';

import 'src/styles/form.css';
import { use } from 'i18next';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export type DropdownEntry = {
    id: string;
    displayName: string;
};

type VenueEntry = {
    venue_id: string;
    venue_name: string;
};

type VenueObject = {
    id_misto: number | null;
    naz_misto: string;
    id_lokace: number;
    id_zarizeni: number;
    adr_misto: string | null;
    tel_misto: string | null;
    email_misto: string | null;
    web_misto: string | null;
    gps_misto: string | null;
    cas_def: string | null;
    cas_def2: string | null;
    bus_spoj: string | null;
    tram_spoj: string | null;
    trol_spoj: string | null;
    metro_spoj: string | null;
    vlak_spoj: number | null;
    pop_misto: string | null;
    akt_misto: number | null;
};

const initialState = {
    id_misto: 0,
    naz_misto: '',
    id_lokace: 0,
    id_zarizeni: 0,
    adr_misto: '',
    tel_misto: '',
    email_misto: '',
    web_misto: '',
    gps_misto: '',
    cas_def: '',
    cas_def2: '',
    bus_spoj: '',
    tram_spoj: '',
    trol_spoj: '',
    metro_spoj: '',
    vlak_spoj: 0,
    pop_misto: '',
    akt_misto: 1,
};

type ErrorState = {
    naz_misto: string;
    id_lokace: string;
    id_zarizeni: string;
    adr_misto: string;
    tel_misto: string;
    email_misto: string;
    web_misto: string;
    gps_misto: string;
    bus_spoj: string;
    tram_spoj: string;
    trol_spoj: string;
    metro_spoj: string;
    vlak_spoj: string;
    pop_misto: string;
    cas_def: string;
    cas_def2: string;
    global: string;
};

const initialErrorState = {
    naz_misto: '',
    id_lokace: '',
    id_zarizeni: '',
    adr_misto: '',
    tel_misto: '',
    email_misto: '',
    web_misto: '',
    gps_misto: '',
    bus_spoj: '',
    tram_spoj: '',
    trol_spoj: '',
    metro_spoj: '',
    vlak_spoj: '',
    pop_misto: '',
    cas_def: '',
    cas_def2: '',
    non_field_errors: '',
    global: ''
};

type locationEntry = {
    location_id: string;
    location_name: string;
};

type location = {
    id: string;
    displayName: string;
};

let locations: location[] = [];

type venueTypeEntry = {
    venue_type_id: string;
    venue_type_name: string;
};

type venueType = {
    id: string;
    displayName: string;
};

let venueTypes: venueType[] = [];

function fetchLocations() {
    const fetchLocations = () => {
        fetch(`${serverUrl}locations/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch location data from the server');
                }
                return response.json();
            })
            .then((data) => {
                locations = data.map((entry: locationEntry) => {
                    return {
                        id: entry.location_id,
                        displayName: entry.location_name
                    };
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    fetchLocations();
}

function fetchVenueTypes() {
    const fetchVenueTypes = () => {
        fetch(`${serverUrl}venuetypes/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch venue type data from the server');
                }
                return response.json();
            })
            .then((data) => {
                venueTypes = data.map((entry: venueTypeEntry) => {
                    return {
                        id: entry.venue_type_id,
                        displayName: entry.venue_type_name
                    };
                });
            })
            .catch((error) => {
                console.log(error);
            });
    };
    fetchVenueTypes();
}

function isInitialState(currentState: VenueObject): boolean {
    return Object.keys(initialState).every((key): key is keyof VenueObject => {
        return initialState[key as keyof VenueObject] === currentState[key as keyof VenueObject];
    });
}

function VenueManagementContent(): JSX.Element {
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();

    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);

    const [selectedVenue, setSelectedVenue] = useState<VenueObject | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const { isLoggedIn, error, login, logout } = useAuth();
    const [selectedDropdownEntry, setSelectedDropdownEntry] = useState<DropdownEntry | null>(null);
    const [selectedLocation, setSelectedLocation] = useState<location | null>(null);
    const [selectedVenueType, setSelectedVenueType] = useState<venueType | null>(null);

    const [isSaveSuccessDialogOpen, setIsSaveSuccessDialogOpen] = useState(false);
    const [isSaveErrorDialogOpen, setIsSaveErrorDialogOpen] = useState(false);
    const [saveDialogMessage, setSaveDialogMessage] = useState('');

    const [isModifyConfirmDialogOpen, setIsModifyConfirmDialogOpen] = useState(false);
    const [isCreateConfirmDialogOpen, setIsCreateConfirmDialogOpen] = useState(false);

    const [formErrors, setFormErrors] = useState(initialErrorState);
    
    const fetchVenues = (selectedVenue?: VenueObject) => {
        fetch(`${serverUrl}venues/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch venue data from the server');
                }
                return response.json();
            })
            .then((data) => {
                const transformedEntries = data.map((entry: VenueEntry) => {
                    return {
                        id: entry.venue_id,
                        displayName: entry.venue_name,
                    };
                });

                setDropdownEntries(transformedEntries);
                fetchLocations();
                fetchVenueTypes();
                if (selectedVenue) {
                    setSelectedVenue(selectedVenue);
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                }
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchVenues();
    }, []);

    const fetchVenueById = (venueId: string) => {
        axiosInstance.get(`${serverUrl}venue/id/${venueId}/`)
            .then((response) => {
                setSelectedVenue(response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching style details:', error);
                }
            });
    };

    const handleVenueAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownEntry | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry>
    ) => {
        if (reason === 'clear') {
            setSelectedDropdownEntry(null);
        } else if (value) {
            setSelectedDropdownEntry(value);
            fetchVenueById(value.id);
        }
    };

    const handleLocationAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: location | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<location>
    ) => {
        if (reason === 'clear') {
            setSelectedLocation(null);
            setFormValues({
                ...formValues,
                id_lokace: 0
            });
        } else if (value) {
            setSelectedLocation(value);
            setFormValues({
                ...formValues,
                id_lokace: Number(value.id)
            });
        }
    };

    const handleVenueTypeAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: venueType | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<venueType>
    ) => {
        if (reason === 'clear') {
            setSelectedVenueType(null);
            setFormValues({
                ...formValues,
                id_zarizeni: 0
            });
        } else if (value) {
            setSelectedVenueType(value);
            setFormValues({
                ...formValues,
                id_zarizeni: Number(value.id)
            });
        }
    };

    const [formValues, setFormValues] = useState(initialState);

    const clearForm = () => {
        setFormValues({ ...initialState });
        setFormErrors({ ...initialErrorState });
        setSelectedDropdownEntry(null);
        setSelectedVenue(null);
        setSelectedLocation(null);
        setSelectedVenueType(null);
    };

    const validateForm = () => {
        // Assume all is initially valid
        const errors = { ...initialErrorState };
        let isValid = true;

        // Validate name
        if (!formValues.naz_misto) {
            errors.naz_misto = t('elements.venues-admin.errors.name-required');
            isValid = false;
        }
        
        if (formValues.naz_misto.length > 35) {
            errors.naz_misto = t('elements.venues-admin.errors.name-too-long');
            isValid = false;
        }

        if (!formValues.id_zarizeni) {
            errors.id_zarizeni = t('elements.venues-admin.errors.venue-type-required');
            isValid = false;
        }

        if (!formValues.id_lokace) {
            errors.id_lokace = t('elements.venues-admin.errors.location-required');
            isValid = false;
        }

        if (formValues.cas_def && !isValidTime(formValues.cas_def)) {
            errors.cas_def = t('elements.venues-admin.errors.time-invalid');
            isValid = false;
        }

        if (formValues.cas_def2 && !isValidTime(formValues.cas_def2)) {
            errors.cas_def2 = t('elements.venues-admin.errors.time-invalid');
            isValid = false;
        }

        setFormErrors(errors);

        return isValid;
    };

    const saveRecord = (newRecord: boolean, saveMessage: string) => {
        if (!validateForm()) {
            return;
        }

        formValues.vlak_spoj = formValues.vlak_spoj ? 1 : 0;
        formValues.akt_misto = formValues.akt_misto ? 1 : 0;
    
        const endpoint = newRecord ? 'venue/' : `venue/id/${formValues.id_misto}/`;
        const method = newRecord ? 'post' : 'put';
        const errors = { ...initialErrorState };

        axiosInstance[method](`${serverUrl}${endpoint}`, formValues)
        .then(response => {
            // Handle successful submission (e.g., display a message or redirect)
            // Reset errors on success
            const savedRecord = response.data;
            setIsSaveSuccessDialogOpen(true); // Open the dialog on successful save
            setSaveDialogMessage(saveMessage);
            setFormErrors(initialErrorState);
            fetchVenues(savedRecord);
        })
        .catch(error => {
            if (error.response && error.response.status === 409) {
                // Handle the specific case for 409 Conflict
                // For example, set a custom error message
                errors.naz_misto = t('elements.venues-admin.errors.unique-name');
                errors.id_lokace = t('elements.venues-admin.errors.unique-name');
            } else {
                // Set global error message from response if available, otherwise use a default error message
                const fieldErrors = error.response?.data || {};
                for (const field in fieldErrors) {
                    if (Object.prototype.hasOwnProperty.call(fieldErrors, field)) {
                        // Use a type assertion to tell TypeScript that field is a key of ErrorState
                        const key = field as keyof ErrorState;
                        errors[key] = fieldErrors[field][0];
                    }
                }
                errors.global = errors.non_field_errors;
                if(errors === initialErrorState) {
                    errors.global = t('elements.forms.save-error-title');
                }
            }
            setFormErrors(errors);
            setIsSaveErrorDialogOpen(true); // Open the dialog on save error
        });
    };

    // Handle form submission/creation of a new record
    const handleSubmit = () => {

        formValues.id_misto = 0;

        saveRecord(true, t('elements.forms.save-success-title'));
        setIsCreateConfirmDialogOpen(false);
    };

    const handleModify = () => {

        if (!selectedVenue) {
            return;
        }

        saveRecord(false, t('elements.forms.modify-success-title'));
        setIsModifyConfirmDialogOpen(false);
    };

    // Handle form field changes
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, type, checked } = event.target;
    
        // Assuming formValues.id_stat and formValues.id_styl are set elsewhere optimally
        const updatedValue = type === 'checkbox' ? checked :
                             type === 'number' ? value === '' ? null : Number(value) :
                             value;
    
        setFormValues(prevFormValues => ({
            ...prevFormValues,
            [name]: updatedValue
        }));
    };

    useEffect(() => {
        if (selectedVenue) {
            // Populate form fields with selected style data
            setFormValues({
                id_misto: selectedVenue.id_misto || 0,
                naz_misto: selectedVenue.naz_misto || '',
                id_lokace: selectedVenue.id_lokace || 0,
                id_zarizeni: selectedVenue.id_zarizeni || 0,
                adr_misto: selectedVenue.adr_misto || '',
                tel_misto: selectedVenue.tel_misto || '',
                email_misto: selectedVenue.email_misto || '',
                web_misto: selectedVenue.web_misto || '',
                gps_misto: selectedVenue.gps_misto || '',
                cas_def: selectedVenue.cas_def || '',
                cas_def2: selectedVenue.cas_def2 || '',
                bus_spoj: selectedVenue.bus_spoj || '',
                tram_spoj: selectedVenue.tram_spoj || '',
                trol_spoj: selectedVenue.trol_spoj || '',
                metro_spoj: selectedVenue.metro_spoj || '',
                vlak_spoj: selectedVenue.vlak_spoj || 1,
                pop_misto: selectedVenue.pop_misto || '',
                akt_misto: selectedVenue.akt_misto || 1
            });
            setSelectedLocation(locations.find(entry => String(entry.id) === selectedVenue.id_lokace.toString()) || null);
            setSelectedVenueType(venueTypes.find(entry => String(entry.id) === selectedVenue.id_zarizeni.toString()) || null);
        } else {
            // Reset form fields for creating a new style
            setFormValues(initialState);
        }
    }, [selectedVenue]);

    return (        
    <Container className="centered">
        <Container className="column-down">
            <Typography variant="h6" className="centered">
                {t('elements.venues-admin.headline')}
            </Typography>
            <Grid container direction="column">
                <Autocomplete
                    className="dropdown"
                    size="small"
                    options={dropdownEntries}
                    getOptionLabel={(option) => option.displayName}
                    value={selectedDropdownEntry}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder={t('elements.venues-admin.choose-venue')}
                            size="small"
                            variant="outlined"
                        />
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li {...props} key={option.id}>
                                <Typography variant="body1">{option.displayName}</Typography>
                            </li>
                        );
                    }}
                    noOptionsText={t('elements.venues-admin.no-options')}
                    onChange={handleVenueAutocompleteChange}
                />
                <Container className="centered form">
                    {formErrors.global && <div className="form-error-message">{formErrors.global}</div>}
                    <form noValidate>
                            <div className='form-item'>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.venue-name')}
                                    name="naz_misto"
                                    required
                                    value={formValues.naz_misto}
                                    onChange={handleInputChange}
                                    error={formErrors.naz_misto === '' ? false : true}
                                    helperText={formErrors.naz_misto || ' '}
                                    inputProps={{ maxLength: 35 }}
                                />
                                <Autocomplete
                                    className="form-dropdown"
                                    size="small"
                                    options={locations}
                                    getOptionLabel={(option) => option.displayName}
                                    value={selectedLocation}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Typography variant="body1">{option.displayName}</Typography>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('elements.venues-admin.choose-location')}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            border: formErrors.id_lokace ? '1px solid #fe938c' : undefined,
                                        },
                                    }}
                                    noOptionsText={t('elements.venues-admin.no-options')}
                                    onChange={handleLocationAutocompleteChange}
                                />
                                {formErrors.id_lokace && <div className="form-error-message">{formErrors.id_lokace}</div>}
                                <Autocomplete
                                    className='form-dropdown'
                                    size="small"
                                    options={venueTypes}
                                    getOptionLabel={(option) => option.displayName}
                                    value={selectedVenueType}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderOption={(props, option) => {
                                        return (
                                            <li {...props} key={option.id}>
                                                <Typography variant="body1">{option.displayName}</Typography>
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder={t('elements.venues-admin.choose-venue-type')}
                                            size="small"
                                            variant="outlined"
                                        />
                                    )}
                                    noOptionsText={t('elements.venues-admin.no-options')}
                                    onChange={handleVenueTypeAutocompleteChange}
                                />
                                {formErrors.id_zarizeni && <div className="form-error-message">{formErrors.id_zarizeni}</div>}
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.address')}
                                    name="adr_misto"
                                    value={formValues.adr_misto}
                                    onChange={handleInputChange}
                                    error={formErrors.adr_misto === '' ? false : true}
                                    helperText={formErrors.adr_misto || ' '}
                                    inputProps={{ maxLength: 50 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.phone')}
                                    name="tel_misto"
                                    value={formValues.tel_misto}
                                    onChange={handleInputChange}
                                    error={formErrors.tel_misto === '' ? false : true}
                                    helperText={formErrors.tel_misto || ' '}
                                    inputProps={{ maxLength: 12 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.email')}
                                    name="email_misto"
                                    value={formValues.email_misto}
                                    onChange={handleInputChange}
                                    error={formErrors.email_misto === '' ? false : true}
                                    helperText={formErrors.email_misto || ' '}
                                    inputProps={{ maxLength: 40 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.website')}
                                    name="web_misto"
                                    value={formValues.web_misto}
                                    onChange={handleInputChange}
                                    error={formErrors.web_misto === '' ? false : true}
                                    helperText={formErrors.web_misto || ' '}
                                    inputProps={{ maxLength: 60 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.gps')}
                                    name="gps_misto"
                                    value={formValues.gps_misto}
                                    onChange={handleInputChange}
                                    error={formErrors.gps_misto === '' ? false : true}
                                    helperText={formErrors.gps_misto || ' '}
                                    inputProps={{ maxLength: 35 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.default-time')}
                                    name="cas_def"
                                    value={formValues.cas_def}
                                    onChange={handleInputChange}
                                    error={formErrors.cas_def === '' ? false : true}
                                    helperText={formErrors.cas_def || ' '}
                                    inputProps={{ maxLength: 8 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.default-time2')}
                                    name="cas_def2"
                                    value={formValues.cas_def2}
                                    onChange={handleInputChange}
                                    error={formErrors.cas_def2 === '' ? false : true}
                                    helperText={formErrors.cas_def2 || ' '}
                                    inputProps={{ maxLength: 8 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.bus')}
                                    name="bus_spoj"
                                    value={formValues.bus_spoj}
                                    onChange={handleInputChange}
                                    error={formErrors.bus_spoj === '' ? false : true}
                                    helperText={formErrors.bus_spoj || ' '}
                                    inputProps={{ maxLength: 130 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.tram')}
                                    name="tram_spoj"
                                    value={formValues.tram_spoj}
                                    onChange={handleInputChange}
                                    error={formErrors.tram_spoj === '' ? false : true}
                                    helperText={formErrors.tram_spoj || ' '}
                                    inputProps={{ maxLength: 100 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.trolley')}
                                    name="trol_spoj"
                                    value={formValues.trol_spoj}
                                    onChange={handleInputChange}
                                    error={formErrors.trol_spoj === '' ? false : true}
                                    helperText={formErrors.trol_spoj || ' '}
                                    inputProps={{ maxLength: 100 }}
                                />
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.metro')}
                                    name="metro_spoj"
                                    value={formValues.metro_spoj}
                                    onChange={handleInputChange}
                                    error={formErrors.metro_spoj === '' ? false : true}
                                    helperText={formErrors.metro_spoj || ' '}
                                    inputProps={{ maxLength: 50 }}
                                />
                                <div className='form-item'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(formValues.vlak_spoj)}
                                                onChange={handleInputChange}
                                                name="vlak_spoj"
                                                color="primary"
                                            />
                                        }
                                        label={t('elements.venues-admin.train-provided')}
                                    />
                                    {formErrors.vlak_spoj && <div className="form-error-message">{formErrors.vlak_spoj}</div>}
                                </div>
                                <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.venues-admin.description')}
                                    name="pop_misto"
                                    value={formValues.pop_misto}
                                    onChange={handleInputChange}
                                    error={formErrors.pop_misto === '' ? false : true}
                                    helperText={formErrors.pop_misto || ' '}
                                    inputProps={{ maxLength: 300 }}
                                />
                                <div className='form-item'>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={Boolean(formValues.akt_misto)}
                                                onChange={handleInputChange}
                                                name="akt_misto"
                                                color="primary"
                                            />
                                        }
                                        label={t('elements.venues-admin.active-venue')}
                                    />
                                </div>  
                            </div>
                            <div>
                                <Button onClick={() => setIsModifyConfirmDialogOpen(true)} type="button" disabled={selectedVenue ? false : true } formNoValidate>
                                    {t('elements.forms.save')}
                                </Button>
                                <Button onClick={() => setIsCreateConfirmDialogOpen(true)} disabled={isInitialState(formValues) ? true : false } formNoValidate>
                                    {t('elements.forms.create')}
                                </Button>
                                <Button onClick={clearForm} color="warning">
                                    {t('elements.forms.clear-form')}
                                </Button>
                            </div>
                    </form>
                </Container>
            </Grid>
            <Dialog
                open={isSaveSuccessDialogOpen}
                    onClose={() => setIsSaveSuccessDialogOpen(false)}
                    aria-labelledby="save-success-dialog-title"
                    aria-describedby="save-success-dialog-description"
                >
                    <DialogTitle id="save-success-dialog-title">
                        {saveDialogMessage}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveSuccessDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
            </Dialog>
            <Dialog
                open={isSaveErrorDialogOpen}
                onClose={() => setIsSaveErrorDialogOpen(false)}
                aria-labelledby="error-dialog-title"
                aria-describedby="error-dialog-description"
            >
                    <DialogTitle id="save-error-dialog-title">
                        <div className="error-message">
                        { t('elements.forms.save-error-title')  }
                        </div>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveErrorDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={isModifyConfirmDialogOpen}
                message={t('elements.venues-admin.modify-confirm')}
                onClose={() => setIsModifyConfirmDialogOpen(false)}
                onConfirmAction={() => handleModify()} />
            <ConfirmDialog
                open={isCreateConfirmDialogOpen}
                message={t('elements.venues-admin.create-confirm')}
                onClose={() => setIsCreateConfirmDialogOpen(false)}
                onConfirmAction={() => handleSubmit()} />
        </Container>
    </Container>
    );
}

function VenueManagement(): JSX.Element {

    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <VenueManagementContent />
            <Footer />
        </div>
    );
}

export default VenueManagement;
