import React from 'react';
import StatesFilter from './tablefilters/StatesFilter';
import StylesFilter from './tablefilters/StylesFilter';
import StartsWithFilter from './tablefilters/StartsWithFilter';
import RegionFilter from './tablefilters/RegionFilter';
import DistrictFilter from './tablefilters/DistrictFilter';
import YearFilter from './tablefilters/YearFilter';
import CalendarFilter from './tablefilters/CalendarFilter';
import VenuesFilter from './tablefilters/VenueFilter';
import CombinedQuickFilter from './tablefilters/CombinedQuickFilter';

type FilterType = 'dropdown' | 'toggle' | 'searchbox' | 'calendar';

export type FilterTuple = [FilterType, string[], string];

type RenderFiltersProps = {
    tableFilters?: Array<FilterTuple>;
    handleFilterChange: (updates: [string, string[]][]) => void;
    values: { [key: string]: string[] };
};

const RenderFilters: React.FC<RenderFiltersProps> = ({ tableFilters, handleFilterChange, values }) => {
    if (!tableFilters) return null;

    return (
        <>
            {tableFilters.map(([filterType, sqlField, filterClass], index) => {
                switch (filterType) {
                    case 'dropdown':
                        switch (filterClass) {
                            case 'StatesFilter':
                                return <StatesFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />;
                            case 'StylesFilter':
                                return <StylesFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />;
                            case 'StartsWithFilter':
                                return <StartsWithFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />;
                            case 'YearFilter':
                                return <YearFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />;
                            case 'RegionFilter':
                                return <RegionFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />;
                            case 'DistrictFilter':
                                return <DistrictFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />;
                            case 'VenuesFilter':
                                return <VenuesFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />;
                            default:
                                return null;
                        }
                    case 'calendar':
                        switch (filterClass) {
                            case 'CalendarFilter':
                                return <CalendarFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />; 
                            default:
                                return null;
                        }
                    case 'toggle':
                        switch (filterClass) {
                            case 'CombinedQuickFilter':
                                return <CombinedQuickFilter key={index} values={values} sqlFields={sqlField} onFilterChange={handleFilterChange} />;
                            default:
                                return null;
                        }
                    case 'searchbox':
                        return <div key={index}>Search Box for {sqlField}</div>;
                    default:
                        return null;
                }
            })}
        </>
    );
};

export { RenderFilters };
