import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete, TextField, CircularProgress, Typography } from '@mui/material';
import { use } from 'i18next';

export interface Band {
    id: string;
    displayName: string;
}

type bandEntry = {
    id_kapela: string;
    naz_kapela: string;
    state_code: string;
};

interface BandAutocompleteProps {
    bands: Band[];
    selectedBands: Band[];
    bandIds: number[];
    onSelectedBandsChange: (newBands: Band[]) => void;
    onClearBands: () => void;
    updateBands: (newBands: Band[]) => void;
    updateBandIds: (newBandIds: number[]) => void;
}

const BandAutocomplete: React.FC<BandAutocompleteProps> = ({
    bands,
    selectedBands,
    bandIds,
    onSelectedBandsChange,
    updateBands,
    updateBandIds,
    onClearBands,
}) => {
    const { t, i18n } = useTranslation();

    const [bandSearchValue, setBandSearchValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [savedBand, setSavedBand] = useState<Band | null>(null);

    useEffect(() => {
        const fetchBands = () => {
            if (bandSearchValue.length >= 3) {
                setLoading(true);
                fetch(`${process.env.REACT_APP_SERVER_URL}bands/search/${encodeURIComponent(bandSearchValue)}/`)
                    .then(response => response.json())
                    .then(data => {
                        const fetchedBands: Band[] = data.map((band: bandEntry) => ({
                            id: band.id_kapela,
                            displayName: `${band.naz_kapela} (${band.state_code})`
                        }));
                        // Add fetched bands to the existing bands list
                        updateBands([...bands, ...fetchedBands]);
                    })
                    .catch(console.error)
                    .finally(() => setLoading(false));
            }
        };

        fetchBands();
    }, [bandSearchValue]);

    useEffect(() => {
        if (savedBand) {
            const band_id = Number(savedBand.id);

            //add band to selected bands
            onSelectedBandsChange([...selectedBands, savedBand]);
            bandIds.push(band_id);
            updateBands([...bands, savedBand]);
            updateBandIds(bandIds);
            setSavedBand(null);
        }
    }, [savedBand]);

    const openNewTab = () => {
        // Using window.open to open a new tab and keep a reference to it

        const newTab = window.open('/band_management?createNewBand', '_blank');
    };

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            // Check for a specific message to ensure it's the correct event
            if (event.data.type === 'band-saved') {
                setSavedBand({id: event.data.band.id_kapela, displayName: event.data.band.naz_kapela});
            }
        };
      
        // Add event listener
        window.addEventListener('message', handleMessage);
      
        // Cleanup
        return () => window.removeEventListener('message', handleMessage);
    }, []); 

    return (
        <Autocomplete
            className="form-dropdown"
            size="small"
            options={bands}
            autoComplete
            includeInputInList
            getOptionLabel={(option) => option.displayName}
            value={selectedBands}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(event, newValue) => onSelectedBandsChange(newValue)}
            onClose={() => {
                onClearBands(); // This will now correctly clear the bands in the parent component
            }}
            onInputChange={(event, newInputValue) => setBandSearchValue(newInputValue)}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    <Typography variant="body1">{option.displayName}</Typography>
                </li>
            )}
            renderInput={(params) => (
                <>
                <TextField
                    {...params}
                    placeholder={t('elements.actions-admin.choose-bands') + ' (' + t('elements.forms.type') + ')'}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
                <a className='link' onClick={openNewTab}>{t('elements.actions-admin.add_band')}</a>
                </>
            )}
            noOptionsText={t('elements.actions-admin.no-options')}
            multiple
        />
    );
};

export default BandAutocomplete;
