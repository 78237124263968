import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

const serverUrl = process.env.REACT_APP_SERVER_URL;

interface DecodedToken {
  exp: number;
  iat: number;
  // ... other token properties
}

// Function to check if the token is expired
export const tokenIsExpired = (token: string): boolean => {
    if (!token) {
        // If the token is missing or empty, return true (indicating it is expired or invalid)
        return true;
      }
    
      try {
        const decodedToken: DecodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decodedToken.exp < currentTime;
      } catch (error) {
        // If an error occurs during decoding, assume the token is invalid or expired
        console.error('Error decoding token:', error);
        return true;
      }
};

export const removeAllTokens = () => {
  Cookies.remove('refreshToken');
  Cookies.remove('accessToken');
  Cookies.remove('isStaff');
};

export const getAccessToken = () => {
  return Cookies.get('accessToken');
};

const getRefreshToken = () => {
  return Cookies.get('refreshToken');
};

const getStaffValue = () => {
  return Cookies.get('isStaff');
};

export const setAccessToken = (accessToken: string) => {
  Cookies.set('accessToken', accessToken, { expires: 7, secure: true, sameSite: 'Lax'  }); // Expires in 7 days
};

export const setRefreshToken = (refreshToken: string) => {
  Cookies.set('refreshToken', refreshToken, { expires: 7, secure: true, sameSite: 'Lax'  }); // Expires in 7 days
};

export const setStaffValue = (isStaff: string) => {
  Cookies.set('isStaff', isStaff, { expires: 7, secure: true, sameSite: 'Lax'  }); // Expires in 7 days
};

export const isStaffValueSet = () => {
  const isStaffValue = getStaffValue();
  return isStaffValue !== null && isStaffValue !== 'false';
};

// Function to refresh the access token
const refreshToken = async (): Promise<string | undefined> => {
  const refreshTokenValue = getRefreshToken();
  if (!refreshTokenValue) return undefined;
  try {
    const response = await axios.post(`${serverUrl}/token/refresh/`, {
      refresh: refreshTokenValue,
    });

    const { access } = response.data;
    setAccessToken(access);
    return access;
  } catch (error) {
    console.error('Unable to refresh token', error);
    removeAllTokens();
    return undefined;
  }
};

export const isAuthenticated = async (): Promise<boolean> => {
  let accessToken = getAccessToken();
  const refreshTokenValue = getRefreshToken();
  if (refreshTokenValue && (!accessToken || tokenIsExpired(accessToken))) {
    accessToken = await refreshToken();
  }
  return accessToken !== undefined;
};

export const isStaff = async (): Promise<boolean> => {
  const isStaffValue = isStaffValueSet();
  const authenticated = await isAuthenticated(); // Await the asynchronous call
  return authenticated && isStaffValue;
};

// Create an Axios instance
const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  async (config) => {
    let accessToken = getAccessToken();
    const refreshTokenValue = getRefreshToken();

    if (accessToken && tokenIsExpired(accessToken) && refreshTokenValue) {
      accessToken = await refreshToken();
    }

    if (accessToken) {
      // Ensure headers object exists
      config.headers = config.headers || {};
      // Set the Authorization header
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { axiosInstance, refreshToken };
