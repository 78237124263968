import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { FilterAutocomplete, DropdownEntry } from './FilterAutocomplete';

import 'src/styles/filters.css';

type YearFilterProps = {
    sqlFields: string[];
    onFilterChange: (updates: [string, string[]][]) => void;
    values: { [key: string]: string[] };
};

function YearFilter({ sqlFields, onFilterChange, values }: YearFilterProps): JSX.Element {
    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const sqlField = sqlFields[0];

    const valueArray = values[sqlField];
    const value = valueArray ? valueArray[0] : null;

    useEffect(() => {
        const currentYear = new Date().getFullYear(); // Get the current year
        const years = [];

        // Generate an array of years from 1900 to current year
        for (let i = 1960; i <= currentYear; i++) {
            years.push(i);
        }

        const transformedEntries = years.map(year => ({
            id: year.toString(),
            displayName: year.toString()
        }));

        setDropdownEntries(transformedEntries);
        setIsLoading(false);
    }, []);

    const handleFilterChange = (item: DropdownEntry | null) => {
        const selectedYear = item ? [item.id] : [];
        onFilterChange([[sqlField, selectedYear]]);
    };

    return (
        <div>
            {!isLoading && (
                <FilterAutocomplete
                    options={dropdownEntries}
                    value={dropdownEntries.find(entry => entry.id === value) || null}
                    onChange={(event, newValue) => handleFilterChange(newValue)}
                    placeholder={t('elements.tablefetch.filter-labels.year')}
                />
            )}
        </div>
    );
}

export default YearFilter;
