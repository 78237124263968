// ConfirmDialog.tsx
import React from 'react';
import { Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ConfirmDialogProps {
  open: boolean;
  message: string;
  onClose: () => void;
  onConfirmAction: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ open, message, onClose, onConfirmAction }) => {
  const { t } = useTranslation();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{message}</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          {t('elements.navbar.cancel')}
        </Button>
        <Button onClick={onConfirmAction} color="primary" autoFocus>
          {t('elements.navbar.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
