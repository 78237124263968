import React, { useState, useEffect } from 'react';
import { t } from 'i18next';

import {FilterAutocompleteMulti, DropdownEntry} from './FilterAutocomplete';

import 'src/styles/filters.css';

const serverUrl = process.env.REACT_APP_SERVER_URL;

type DistrictFilterProps = {
    sqlFields: string[];
    onFilterChange: (updates: [string, string[]][]) => void;
    values: { [key: string]: string[] };
};

type DistrictEntry = {
    district_id: string;
    district_name: string;
};

function DistrictFilter({ sqlFields, onFilterChange, values }: DistrictFilterProps): JSX.Element {
    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const sqlField = sqlFields[0];
    const value = values[sqlField] || [];

    const [validDistrictIds, setValidDistrictIds] = useState<string[]>([]);

    const fetchDistricts = () => {
        let uri = `${serverUrl}districts/`;

        // Check if values['region_ids'] is defined and non-empty, then append it to the URI
        if (values['region_ids'] && values['region_ids'].length > 0) {
            const regionIdsParam = values['region_ids'].join(',');
            uri += `?region_ids=${regionIdsParam}`;
        }

        fetch(uri)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch data from the server');
                }
                return response.json();
            })
            .then((data) => {
                const transformedEntries = data.map((entry: DistrictEntry) => {
                    return {
                        id: entry.district_id,
                        displayName: entry.district_name,
                    };
                });

                setDropdownEntries(transformedEntries);

                setValidDistrictIds(data.map((entry: DistrictEntry) => entry.district_id));
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => { // Remove invalid district_ids from values['district_ids'] (e.g. if region_ids is changed)
        const currentDistrictIds = values['district_ids'] || [];
        const newDistrictIds = currentDistrictIds.filter(id => validDistrictIds.includes(id));
        if (newDistrictIds.length !== currentDistrictIds.length) {
            onFilterChange([['district_ids', newDistrictIds]]);
        }
    }, [validDistrictIds]);

    useEffect(() => {
        fetchDistricts();
    }, [values['region_ids']]); // Re-fetch districts when region_ids change

    const handleFilterChange = (item: DropdownEntry[] | null) => {
        const selectedDistricts = item?.map(entry => entry.id) || [];
        onFilterChange([[sqlField, selectedDistricts]]);
    };

    return (
        <div>
            {!isLoading && (
                <FilterAutocompleteMulti
                    options={dropdownEntries}
                    value={value}
                    onChange={(event, newValue) => handleFilterChange(newValue)}
                    placeholder={t('elements.tablefetch.filter-labels.district')}
                />
            )}
        </div>
    );
}

export default DistrictFilter;
