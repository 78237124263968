import React, { useState, useEffect } from 'react';
import { t } from 'i18next';
import { FilterAutocomplete, DropdownEntry } from './FilterAutocomplete';

import 'src/styles/filters.css';

type StartsWithFilterProps = {
    sqlFields: string[];
    onFilterChange: (updates: [string, string[]][]) => void;
    values: { [key: string]: string[] };
};

function StartsWithFilter({ sqlFields, onFilterChange, values }: StartsWithFilterProps): JSX.Element {
    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    const sqlField = sqlFields[0];

    const valueArray = values[sqlField];
    const value = valueArray ? valueArray[0] : null;

    useEffect(() => {
        // Generate an array of upper-case alphanumeric symbols
        const alphanumeric = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789'.split('');

        const transformedEntries = alphanumeric.map(symbol => ({
            id: symbol.toLowerCase(),
            displayName: symbol
        }));

        setDropdownEntries(transformedEntries);
        setIsLoading(false);
    }, []);

    const handleFilterChange = (item: DropdownEntry | null) => {
        const selectedLetter = item ? [item.id] : [];
        onFilterChange([[sqlField, selectedLetter]]);
    };

    return (
        <div>
            {!isLoading && (
                <FilterAutocomplete
                    options={dropdownEntries}
                    value={dropdownEntries.find(entry => entry.id === value) || null}
                    onChange={(event, newValue) => handleFilterChange(newValue)}
                    placeholder={t('elements.tablefetch.filter-labels.starts-with')}
                />
            )}
        </div>
    );
}

export default StartsWithFilter;
