import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TextField, Container, Typography, Button, Grid } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import CircularProgress from '@mui/material/CircularProgress';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import BandAutocomplete, {Band} from './page_elements/BandAutocomplete';

import ConfirmDialog from './page_elements/ConfirmDialog';

import { axiosInstance } from 'src/utils/tokenManagement';

import { useAuth } from 'src/utils/useAuth'; // Import the useAuth hook

import { isValidTime, isISODate, formatDate } from 'src/utils/dates';

import 'src/styles/form.css';
import { use } from 'i18next';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export type DropdownEntry = {
    id: string;
    displayName: string;
};

type ConcertTranslationObject = {
    id: number;
    id_action_meta: number;
    name: string;
    hash?: string;
    date: string;
    ignore_action: number;
    bands_ids: number[];
};

type band = {
    id: string;
    displayName: string;
};

type bandRecord = {
    id_band: {
        id_kapela: number;
        naz_kapela: string;
        state_code: string;
    };
};

type ErrorState = {
    name: string;
    global: string;
};

const initialErrorState = {
    name: '',
    global: ''
};

const initialState: ConcertTranslationObject = {
    id: 0,
    id_action_meta: 0,
    name: '',
    hash: '',
    date: '',
    bands_ids: [],
    ignore_action: 0
};

function ConcertTranslationManagementContent(): JSX.Element {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);
    const { isLoggedIn, error, login, logout } = useAuth();

    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);
    const [selectedConcertTranslation, setSelectedConcertTranslation] = useState<ConcertTranslationObject | null>(null);
    const [selectedDropdownEntry, setSelectedDropdownEntry] = useState<DropdownEntry | null>(null);

    const [formErrors, setFormErrors] = useState(initialErrorState);
    const [formValues, setFormValues] = useState(initialState);
    const [concertTranslationId, setConcertTranslationId] = useState<number>(0);

    const [selectedBands, setSelectedBands] = useState<band[]>([]);
    const [bands, setBands] = useState<Band[]>([]);

    const [isSaveSuccessDialogOpen, setIsSaveSuccessDialogOpen] = useState(false);
    const [isSaveErrorDialogOpen, setIsSaveErrorDialogOpen] = useState(false);
    const [saveDialogMessage, setSaveDialogMessage] = useState('');

    const [isSaveConfirmDialogOpen, setIsSaveConfirmDialogOpen] = useState(false);
    const [isDeleteConfirmDialogOpen, setIsDeleteConfirmDialogOpen] = useState(false);

    // Get parameter id_action_meta from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const id_action_meta_id = urlParams.get('id_action_meta');
    const id_concert = urlParams.get('id_concert');
    const action_name = urlParams.get('action_name');

    if (id_concert) {
        setConcertTranslationId(parseInt(id_concert));
        formValues.id = parseInt(id_concert);
    }

    const fetchConcertTranslation = (selectedConcertTranslation?: ConcertTranslationObject) => {
        fetch(`${serverUrl}concerts_translation/list/${id_action_meta_id}/`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to fetch concert_translation data from the server');
                }
                return response.json();
            })
            .then((data) => {
                const dataArray = Array.isArray(data) ? data : [data];
                const transformedEntries = dataArray.map((entry: ConcertTranslationObject) => {
                    return {
                        id: entry.id.toString(), // Ensure id is a string as expected by DropdownEntry type
                        displayName: `${entry.name} (${formatDate(entry.date)})`
                    };
                });
                setDropdownEntries(transformedEntries);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                }
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const fetchConcertTranslationById = (id: string) => {
        axiosInstance.get(`${serverUrl}concerts_translation/${id}/`)
            .then((response) => {
                setSelectedConcertTranslation(response.data);
                formValues.name = response.data.name;

                const bands = response.data.concert_bands;
                formValues.bands_ids = bands.map((band: bandRecord) => band.id_band.id_kapela);
                setSelectedBands(bands.map((band: bandRecord) => {
                    return {
                        id: band.id_band.id_kapela.toString(),
                        displayName: `${band.id_band.naz_kapela} (${band.id_band.state_code})`
                    };
                }));
                setBands(bands.map((band: bandRecord) => {
                    return {
                        id: band.id_band.id_kapela.toString(),
                        displayName: `${band.id_band.naz_kapela} (${band.id_band.state_code})`
                    };
                }));
                setConcertTranslationId(response.data.id);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching action details:', error);
                }
            });
    };

    // Save record
    const saveConcertTranslation = () => {

        formValues.ignore_action = 0;

        // Compute md5 hash from formValues.name and id_action_meta_id
        const hash = 0;

        // Delete formValues.date
        const { date, ...submitValues } = formValues;

        if (formValues.id) {
            submitValues.id = formValues.id;
        } else {
            submitValues.id = 0;
        }

        // Remove hash if it is not set
        if (hash === 0) {
            delete submitValues.hash;
        }

        const newRecord = formValues.id === 0;

        const endpoint = newRecord ? 'concerts_translation/save/' : `concerts_translation/update/${submitValues.id}/`;
        const method = newRecord ? 'post' : 'put';
        const errors = { ...initialErrorState };

        axiosInstance[method](`${serverUrl}${endpoint}`, submitValues)
            .then((response) => {
                formValues.id = response.data.id;
                setIsSaveSuccessDialogOpen(true);
                fetchConcertTranslation();
            })
            .catch((error) => {
                // Set global error message from response if available, otherwise use a default error message
                const fieldErrors = error.response?.data || {};
                for (const field in fieldErrors) {
                    if (Object.prototype.hasOwnProperty.call(fieldErrors, field)) {
                        // Use a type assertion to tell TypeScript that field is a key of ErrorState
                        const key = field as keyof ErrorState;
                        errors[key] = fieldErrors[field][0];
                    }
                }
                //errors.global = errors.non_field_errors;
                if(errors === initialErrorState) {
                    errors.global = t('elements.forms.save-error-title');
                }
                setFormErrors(errors);
                setIsSaveErrorDialogOpen(true);
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error saving concert translation:', error);
                }
            });
    };

    const deleteRecord = () => {
        axiosInstance.delete(`${serverUrl}concerts_translation/delete/${concertTranslationId}/`)
            .then(() => {
                fetchConcertTranslation();
                clearForm();
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error deleting concert translation:', error);
                }
                setIsDeleteConfirmDialogOpen(false);
                setIsSaveErrorDialogOpen(true);
            });
        setIsDeleteConfirmDialogOpen(false);
    };

    useEffect(() => {
        fetchConcertTranslation();
    }, []);

    useEffect(() => {
        if (id_action_meta_id) {
            formValues.id_action_meta = parseInt(id_action_meta_id);
        } else {
            formValues.id_action_meta = 0;
        }

        if (action_name) {
            formValues.name = action_name;
        } else {
            formValues.name = '';
        }
        setFormValues(formValues);
    }, [id_action_meta_id, action_name]);

    const handleConcertAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownEntry | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry>
    ) => {
        if (reason === 'clear') {
            setSelectedDropdownEntry(null);
        } else if (value) {
            setSelectedDropdownEntry(value);
            fetchConcertTranslationById(value.id);
            formValues.id = parseInt(value.id);
        }
    };

    if (!id_action_meta_id) {
        return (
            <Container className="centered">
                <Typography variant="h6" className="centered">
                    {t('elements.concerts-translation.missing-id-action-meta')}
                </Typography>
            </Container>
        );
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = () => {

        if (!formValues.name) {
            setFormErrors(prevState => ({
                ...prevState,
                name: t('elements.forms.required')
            }));
            setIsSaveConfirmDialogOpen(false);
            return;
        }

        if (selectedBands.length === 0) {
            setFormErrors(prevState => ({
                ...prevState,
                global: t('elements.concerts-translation.no-bands')
            }));
            setIsSaveConfirmDialogOpen(false);
            return;
        }

        saveConcertTranslation();
        setIsSaveConfirmDialogOpen(false);
    };

    const clearForm = () => {
        formValues.name = '';
        setFormValues(initialState);
        setFormErrors({ ...initialErrorState });
        setSelectedDropdownEntry(null);
        setSelectedBands([]);
        setConcertTranslationId(0);
    };

    const clearBands = () => {
        setBands([]);
    };

    const updateBandIds = (newBandIds: number[]) => {
        setFormValues(prevState => ({
            ...prevState,
            bands_ids: newBandIds
        }));
    };

    const updateBands = (newBands: Band[]) => {
        setBands(newBands);
    };

    return (
        <Container className="centered">
        <Container className="column-down">
            <Typography variant="h6" className="centered">
                {t('elements.concerts-translation.headline')}
            </Typography>
            <Grid container direction="column">
                { !action_name &&
                    <Autocomplete
                        className="dropdown"
                        size="small"
                        options={dropdownEntries}
                        getOptionLabel={(option) => option.displayName}
                        value={selectedDropdownEntry}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('elements.concerts-translation.choose-concert')}
                                size="small"
                                variant="outlined"
                            />
                        )}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    <Typography variant="body1">{option.displayName}</Typography>
                                </li>
                            );
                        }}
                        noOptionsText={t('elements.concerts-translation.no-options')}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={handleConcertAutocompleteChange}
                    />
                }
                <Container className="centered form">
                    {formErrors.global && <h6><div className="form-error-message">{formErrors.global}</div></h6>}
                    <form noValidate>
                        <div className='form-item'>
                            <TextField
                                    fullWidth
                                    size="small"
                                    variant="outlined"
                                    label={t('elements.concerts-translation.concert-name')}
                                    name="name"
                                    disabled={action_name ? true : false}
                                    value={formValues.name}
                                    onChange={handleInputChange}
                                    error={formErrors.name === '' ? false : true}
                                    helperText={formErrors.name || ' '}
                                    inputProps={{ maxLength: 200 }}
                            />
                            <BandAutocomplete
                                bands={bands}
                                selectedBands={selectedBands}
                                bandIds={formValues.bands_ids}
                                onSelectedBandsChange={(newBands: band[]) => {
                                    setSelectedBands(newBands);
                                    setFormValues(prevState => ({
                                        ...prevState,
                                        bands_ids: newBands.map(band => Number(band.id))
                                    }));
                                    setBands(newBands);
                                }}
                                onClearBands={clearBands}
                                updateBandIds={updateBandIds}
                                updateBands={updateBands}
                            />
                        </div>
                        <div>
                        <Button onClick={() => setIsSaveConfirmDialogOpen(true)} type="button" formNoValidate>
                            {t('elements.forms.save')}
                        </Button>
                        { concertTranslationId > 0 &&
                            <Button onClick={() => setIsDeleteConfirmDialogOpen(true)} type="button" formNoValidate>
                                {t('elements.forms.delete')}
                            </Button>
                        }
                        <Button onClick={clearForm} color="warning">
                            {t('elements.forms.clear-form')}
                        </Button>
                        </div>
                    </form>
                </Container>
            </Grid>
            <Dialog
                open={isSaveSuccessDialogOpen}
                    onClose={() => setIsSaveSuccessDialogOpen(false)}
                    aria-labelledby="save-success-dialog-title"
                    aria-describedby="save-success-dialog-description"
                >
                    <DialogTitle id="save-success-dialog-title">
                        {t('elements.forms.save-success-title')}
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveSuccessDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
            </Dialog>
            <Dialog
                open={isSaveErrorDialogOpen}
                onClose={() => setIsSaveErrorDialogOpen(false)}
                aria-labelledby="error-dialog-title"
                aria-describedby="error-dialog-description"
            >
                    <DialogTitle id="save-error-dialog-title">
                        <div className="error-message">
                        { t('elements.forms.save-error-title')  }
                        </div>
                    </DialogTitle>
                    <DialogActions>
                        <Button onClick={() => setIsSaveErrorDialogOpen(false)}>
                            {t('elements.forms.ok')}
                        </Button>
                    </DialogActions>
            </Dialog>
            <ConfirmDialog
                open={isSaveConfirmDialogOpen}
                message={t('elements.concerts-translation.modify-confirm')}
                onClose={() => setIsSaveConfirmDialogOpen(false)}
                onConfirmAction={() => handleSave()} />
            <ConfirmDialog
                open={isDeleteConfirmDialogOpen}
                message={t('elements.concerts-translation.delete-confirm')}
                onClose={() => setIsDeleteConfirmDialogOpen(false)}
                onConfirmAction={() => deleteRecord()} />
            </Container>
        </Container>
    );
}

function ConcertTranslationManagement(): JSX.Element {

    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <ConcertTranslationManagementContent />
            <Footer />
        </div>
    );
}

export default ConcertTranslationManagement;
