import React from 'react';
import { Container, Typography } from '@mui/material';
import 'src/styles/footer.css';

function Footer(): JSX.Element {
    const currentYear = new Date().getFullYear();
    const encodedEmail = '&#105;&#110;&#102;&#111;&#64;&#114;&#111;&#99;&#107;&#108;&#105;&#110;&#97;&#46;&#99;&#122;';

    return (
        <Container component="footer" className="footer" maxWidth={false}>
            <Typography variant="body1" align="center">
                &copy; {currentYear} Rocklina
            </Typography>
            <Typography variant="body2" align="center" dangerouslySetInnerHTML={{ __html: `Contact: ${encodedEmail}` }}>
            </Typography>
        </Container>
    );
}

export default Footer;
