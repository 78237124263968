import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from 'src/pages/Home';
import Openairs from 'src/pages/Openairs';
import Actions from 'src/pages/Actions';
import Bands from 'src/pages/Bands';
import Band from 'src/pages/Band';
import Action from 'src/pages/Action';
import Settings from 'src/pages/Settings';
import Styles from 'src/pages/Styles';
import StylesGroups from 'src/pages/StylesGroups';
import ActionManagement from 'src/pages/ActionManagement';
import ConcertTranslationManagement from 'src/pages/ConcertTranslationManagement';
import VenueManagement from 'src/pages/VenueManagement';
import BandManagement from 'src/pages/BandManagement';
import ActionMeta from './pages/ActionMeta';
import PrivateRoute from 'src/libs/PrivateRouter';

import 'src/styles/default.css';

function App(): JSX.Element {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/bands" element={<Bands />} />
                <Route path="/openairs" element={<Openairs />} />
                <Route path="/actions" element={<Actions />} />
                <Route path="/band" element={<Band />} />
                <Route path="/action" element={<Action />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/styles" element={
                    <PrivateRoute component={Styles} />
                } />  
                <Route path="/styles_groups" element={
                    <PrivateRoute forStaff={true} component={StylesGroups} />
                } />
                <Route path="/action_management" element={
                    <PrivateRoute forStaff={true} component={ActionManagement} />
                } />
                <Route path="/concert_management" element={
                    <PrivateRoute forStaff={true} component={ConcertTranslationManagement} />
                } />
                <Route path="/venue_management" element={
                    <PrivateRoute forStaff={true} component={VenueManagement} />
                } />
                <Route path="/band_management" element={
                    <PrivateRoute forStaff={true} component={BandManagement} />
                } />
                <Route path="/action_meta" element={
                    <PrivateRoute forStaff={true} component={ActionMeta} />
                } />
            </Routes>
        </Router>
    );
}

export default App;
