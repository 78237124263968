import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { TextField, Container, Typography, Button, Grid, CircularProgress, Box } from '@mui/material';
import Autocomplete, { AutocompleteChangeReason, AutocompleteChangeDetails } from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

import BackgroundHandler from 'src/pages/page_elements/BackgroundHandler';
import NavBar from 'src/pages/page_elements/Navbar';
import Footer from 'src/pages/page_elements/Footer';
import ConfirmDialog from './page_elements/ConfirmDialog';

import { axiosInstance } from 'src/utils/tokenManagement';

import { useAuth } from 'src/utils/useAuth'; // Import the useAuth hook

import { isValidTime, isISODate, formatDate } from 'src/utils/dates';

import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/en';
import 'dayjs/locale/pl';

import 'src/styles/form.css';
import 'src/styles/centeredelement.css';

import { use } from 'i18next';

const serverUrl = process.env.REACT_APP_SERVER_URL;

export type DropdownEntry = {
    id: string;
    displayName: string;
};

type ActionMetaEntry = {
    id_action_meta: number;
    name: string;
};

type ActionMetaObject = {
    id_action_meta: number;
    code_name: string;
    id_misto: number;
    id_zamer: number;
    id_prod: number;
    name: string;
    start_date: string;
    end_date: string;
    start_time: string;
    end_time: string;
    price: number;
    price_currency: string;
    place_name: string;
    url: string;
    search_url: string;
    band_tiles_class: string;
    band_name_class: string;
};

type ActionEntry = {
    id_akce: string;
    naz_akce: string;
    location: string;
    dat_zac: string;
};

type ActionObject = {
    id_akce: number;
    id_misto: number;
    id_zamer: number;
    id_typ: number;
    naz_akce: string;
    dat_zac: string;
    cas_zac: string;
    dat_kon: string;
    cas_kon: string | null;
    cena: number | null;
    id_mena: number;
    id_prod: number;
    web_akce: string | null;
    dalkap_akce: string | null;
    popis_akce: string | null;
    pockap_akce: number | null;
    pockap2_akce: number | null;
    bands_ids: number[];
    status: number;
    dalkap: string;
};

const initialState: ActionObject = {
    id_akce: 0,
    id_misto: 0,
    id_zamer: 0,
    id_typ: 0,
    naz_akce: '',
    dat_zac: '',
    cas_zac: '',
    dat_kon: '',
    cas_kon: '',
    cena: null,
    id_mena: 0,
    id_prod: 0,
    web_akce: '',
    dalkap_akce: '',
    popis_akce: '',
    pockap_akce: 0,
    pockap2_akce: 0,
    bands_ids: [0],
    status: 0,
    dalkap: ''
};

type bandEntry = {
    id_kapela: string;
    naz_kapela: string;
};

type band = {
    id: string;
    name: string;
    displayName: string;
};

function ActionMetaContent(): JSX.Element {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const navigate = useNavigate();

    const actionMetasHash: { [key: string]: ActionMetaObject } = {};

    const [dropdownEntries, setDropdownEntries] = useState<DropdownEntry[]>([]);

    const [selectedActionMeta, setSelectedActionMeta] = useState<ActionMetaObject | null>(null);

    const [actionMetas, setActionMetas] = useState<{ [key: string]: ActionMetaObject } >();
    const [selectedAction, setSelectedAction] = useState<ActionObject | null>(null);
    const [bands, setBands] = useState<band[]>([]);
    const [addedBands, setAddedBands] = useState<band[]>([]);
    const [unknownBands, setUnknownBands] = useState<band[]>([]);
    const [removedBandsIds, setRemovedBandsIds] = useState<number[]>([]);
    const [bandsIds, setBandsIds] = useState<number[]>([]);
    const [bandsLoading, setBandsLoading] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [actionNotKnown, setActionNotKnown] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { isLoggedIn, error, login, logout } = useAuth();
    const [selectedDropdownEntry, setSelectedDropdownEntry] = useState<DropdownEntry | null>(null);

    const [ignoredConcertsUnknown, setIgnoredConcertsUnknown] = useState<string[]>([]);
    const [ambiguousBands, setAmbiguousBands] = useState<string[]>([]);
    const [otherBands, setOtherBands] = useState<string[]>([]);

    const [ignoredConcertsKnown, setIgnoredConcertsKnown] = useState<string[]>([]);

    const [showSaveButton, setShowSaveButton] = useState(false);

    const [addedBandsVisible, setAddedBandsVisible] = useState(false);
    const [unknownBandsVisible, setUnknownBandsVisible] = useState(false);
    const [ignoredBandsVisible, setIgnoredBandsVisible] = useState(false);
    const [ambiguousBandsVisible, setAmbiguousBandsVisible] = useState(false);
    const [otherBandsVisible, setOtherBandsVisible] = useState(false);

    const [errorMessage, setErrorMessage] = useState<string>('');
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

    const fetchActionMetas = () => {
        axiosInstance.get(`${serverUrl}action_meta/list/`)
            .then((response) => {
                const receivedData = response.data;
                const transformedEntries = receivedData.map((entry: ActionMetaEntry) => ({
                    id: entry.id_action_meta.toString(),
                    displayName: entry.name
                }));

                // Convert received data array to hash, where the key is the action meta ID
                receivedData.forEach((entry: ActionMetaObject) => {
                    actionMetasHash[entry.id_action_meta.toString()] = entry;
                });

                setDropdownEntries(transformedEntries);
                setActionMetas(actionMetasHash);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching action metas:', error);
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        fetchActionMetas();
    }, []);

    useEffect(() => {
        if (selectedActionMeta) {
            fetchActionByName(selectedActionMeta.name);
        }
    }, [selectedActionMeta]);

    useEffect(() => {
        if(addedBands && addedBands.length > 0) {
            // If there are added bands, set the addedBandsVisible state to true
            setAddedBandsVisible(true);            
        } else 
            setAddedBandsVisible(false);
    }, [addedBands]);

    useEffect(() => {
        if (unknownBands && unknownBands.length > 0) {
            setUnknownBandsVisible(true);
        } else {
            setUnknownBandsVisible(false);
        }
    }, [unknownBands]);

    useEffect(() => {
        if (ignoredConcertsUnknown && ignoredConcertsUnknown.length > 0) {
            setIgnoredBandsVisible(true);
        } else {
            setIgnoredBandsVisible(false);
        }
    }, [ignoredConcertsUnknown]);

    useEffect(() => {
        if(ambiguousBands && ambiguousBands.length > 0) {
            setAmbiguousBandsVisible(true);
        } else {
            setAmbiguousBandsVisible(false);
        }
    }, [ambiguousBands]);

    useEffect(() => {
        if (otherBands && otherBands.length > 0) {
            setOtherBandsVisible(true);
        } else {
            setOtherBandsVisible(false);
        }
    }, [otherBands]);

    useEffect(() => {
        if (unknownBands && unknownBands.length > 0 || ambiguousBands && ambiguousBands.length > 0) {
            setShowSaveButton(false);
        } else if (addedBands && addedBands.length > 0 || removedBandsIds && removedBandsIds.length > 0 || otherBands && otherBands.length > 0 || ignoredConcertsUnknown && ignoredConcertsUnknown.length > 0) {
            setShowSaveButton(true);
        } else {
            setShowSaveButton(false);
        }
    }, [unknownBands, addedBands, ignoredConcertsUnknown, otherBands, removedBandsIds, ambiguousBands]);

    useEffect(() => {

        // If ignored concerts are present, set the ignoredBandsVisible state to true
        if (ignoredConcertsKnown && ignoredConcertsKnown.length > 0) {
            setIgnoredBandsVisible(true);
        } else {
            setIgnoredBandsVisible(false);
        }
    }, [ignoredConcertsKnown]);

    const handleActionMetaAutocompleteChange = (
        event: React.SyntheticEvent<Element, Event>,
        value: DropdownEntry | null,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<DropdownEntry>
    ) => {
        if (reason === 'clear') {
            setSelectedDropdownEntry(null);
        } else if (value) {
            setSelectedDropdownEntry(value);
            if (actionMetas) {
                setSelectedActionMeta(actionMetas[value.id]);
            }
        }
    };

    const fetchActionByName = (name: string) => {
        const venueId = selectedActionMeta?.id_misto || 0;
        axiosInstance.get(`${serverUrl}actions/?name=${name}&venues_ids=[${venueId}]&return_all=true&action_meta=true`)
            .then((response) => {
                const receivedData = response.data.results;
                if (receivedData.length === 0) {
                    setActionNotKnown(true);
                    return;
                }
                if (receivedData[0].dalkap_akce !== null) {
                    setOtherBandsVisible(true);
                }             
                setSelectedAction(receivedData[0]);
                fetchActionBands(receivedData[0].id_akce.toString());
                if (selectedActionMeta) {
                    fetchIgnoredConcerts(selectedActionMeta.id_action_meta.toString());
                }
            })
            .catch((error) => {
                console.error('Error fetching action meta:', error);
            });
    };

    const fetchActionBands = (actionId: string) => {
        setBandsLoading(true);
        axiosInstance.get(`${serverUrl}bands/?action_id=${actionId}`)
            .then((response) => {
                const mappedBands: band[] = response.data.results.map((band: bandEntry) => ({
                    id: band.id_kapela, // TypeScript now knows band has an 'id' of type string
                    displayName: band.naz_kapela // and a 'name' (mapped to displayName) of type string
                }));
                setBands(mappedBands);
                setBandsIds(mappedBands.map((band) => Number(band.id)));
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching action bands:', error);
                }
            })
            .finally(() => {
                setBandsLoading(false);
            });
    };

    const fetchIgnoredConcerts = (actionMetaId: string) => {
        axiosInstance.get(`${serverUrl}concerts_translation/list/${actionMetaId}/?ignored=true`)
            .then((response) => {
                const ignoredConcerts = response.data;
                const ignoredConcertsNames = [];
                for (let i = 0; i < ignoredConcerts.length; i++) {
                    ignoredConcertsNames[i] = ignoredConcerts[i].name;
                }
                setIgnoredConcertsKnown(ignoredConcertsNames);

            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    console.error('Error fetching ignored actions:', error);
                }
            });
    };

    const openNewTab = (bandName: string) => {
        // Using window.open to open a new tab and keep a reference to it

        const newTab = window.open(`/band_management?createNewBand&bandName=${bandName}`, '_blank');
    };

    const addToIgnored = (bandName: string) => {
        setIgnoredConcertsUnknown((currentIgnoredBands) => [...currentIgnoredBands, bandName]);

        // Remove the band from the list of unknown bands
        setUnknownBands((currentUnknownBands) =>
            currentUnknownBands.filter((band) => band.name !== bandName)
        );
    };

    const addToOther = (bandName: string) => {
        setOtherBands((currentOtherBands) => [...currentOtherBands, bandName]);

        // Remove the band from the list of unknown bands
        setUnknownBands((currentUnknownBands) =>
            currentUnknownBands.filter((band) => band.name !== bandName)
        );
    };

    const bandField = (bandName: string) => {
        return (
            <TextField
                fullWidth
                size="small"
                variant="outlined"
                key={bandName}
                value={bandName}
                onChange={(event) => {}}
                margin="dense"
                disabled
            />
        );
    };

    // Generates a list of bands as TextFields from the selectedBands state
    const generateBandList = (type: string) => {
        if (type === 'known' && bands.length) {
            return bands.map((band) => {
                // Determine if the band's id is in the list of removed band ids to apply a strikethrough style
                let strikeThroughStyle;
                if (removedBandsIds) {
                    strikeThroughStyle = removedBandsIds.includes(Number(band.id)) ? 'line-through' : 'none';
                }

                return (
                    <TextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        key={band.id}
                        value={band.displayName}
                        onChange={(event) => {}}
                        error={strikeThroughStyle === 'line-through'} 
                        helperText={strikeThroughStyle === 'line-through' ? t('elements.action-meta-admin.removed') : ''}
                        margin="normal"
                        disabled
                        InputProps={{
                            style: {
                                textDecoration: strikeThroughStyle, // Apply strikethrough style based on condition
                            }
                        }}
                    />
                );
            });
        } else if (type === 'added' && addedBands.length) {
            return addedBands.map((band) => {
                return bandField(band.name);
            });
        } else if (type === 'unknown' && unknownBands.length) {
            return unknownBands.map((band) => {
                return (
                    <>
                        { bandField(band.name) }
                        <a className='link' onClick={() => openNewTab(band.name)}>{t('elements.action-meta-admin.add-band')}</a><br/>
                        <a className='link' onClick={() => addToOther(band.name)}>{t('elements.action-meta-admin.add-to-other')}</a><br/>
                        <a className='link' onClick={() => addToIgnored(band.name)}>{t('elements.action-meta-admin.add-to-ignored')}</a><br/>
                        <a className='link' onClick={() => openNewTab(band.name)}>{t('elements.action-meta-admin.manual-fix')}</a><br/>
                    </>
                );
            });
        } else if (type === 'ignored_unknown') {
            if (ignoredConcertsUnknown.length) {
                return ignoredConcertsUnknown.map((band) => {
                    return (
                        bandField(band)
                    );
                });     
            } else {
                return (
                    t('elements.forms.empty')
                );
            } 
        } else if (type === 'ignored_known') {
            if (ignoredConcertsKnown.length) {
                return ignoredConcertsKnown.map((band) => {
                    return (
                        bandField(band)
                    );
                });
            } else {
                return (
                    t('elements.forms.empty')
                );
            }
        } else if (type === 'ambiguous' && ambiguousBands.length) {
            return ambiguousBands.map((band) => {
                return (
                    bandField(band)
                );
            });
        } else if (type === 'other') {
            if (otherBands.length) {
                return otherBands.map((band) => {
                    return (
                        bandField(band)
                    );
                });
            } else {
                return (
                    t('elements.forms.empty')
                );
            }
        }
    };

    const loadNewData = () => {
        setDataLoading(true);
        axiosInstance.get(`${serverUrl}/crawler/fest/${selectedActionMeta?.code_name}/`)
        .then((response) => {

            const data = response.data;
            setUnknownBands(data.unknown_bands);
            setAddedBands(data.added_bands);
            setRemovedBandsIds(data.removed_bands_ids);
            setAmbiguousBands(data.ambiguous_bands);
            setBandsIds(data.fetched_bands_ids);
        })
        .catch((error) => {
            if (error.response && error.response.status === 401) {
                logout();
            } else {
                setErrorMessage(error.response.data.error);
                setIsErrorDialogOpen(true);
                console.error('Error fetching data:', error);
            }
        })
        .finally(() => {
            setDataLoading(false);
        });
    };

    const openTranslations = () => {
        window.open(`/concert_management/?id_action_meta=${selectedActionMeta?.id_action_meta}`, '_blank');
    };

    const saveForm = () => {
        const savedata = {
            id_akce: selectedAction?.id_akce,
            id_action_meta: selectedActionMeta?.id_action_meta,
            knownbands: bandsIds,
            addedbands: addedBands,
            removedbands: removedBandsIds,
            ignoredconcerts_unknown: ignoredConcertsUnknown,
            otherbands_unknown: otherBands,
            otherbands_known: selectedAction?.dalkap_akce,
        };

        // Save data
        axiosInstance.post(`${serverUrl}action_meta/save/`, savedata)
            .then((response) => {
                console.log('Data saved:', response.data);
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    logout();
                } else {
                    setErrorMessage(error.response.data.error);
                    setIsErrorDialogOpen(true);
                    console.error('Error saving data:', error);
                }
            });
    };

    useEffect(() => {
        if (errorMessage && errorMessage.length) {
            setIsErrorDialogOpen(true);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (ambiguousBands.length > 0) {
            setErrorMessage(t('elements.action-meta-admin.ambiguous-bands'));
            setIsErrorDialogOpen(true);
        }
    }, [ambiguousBands]);

    useEffect(() => {
        const handleMessage = (event: MessageEvent) => {
            // Check for a specific message to ensure it's the correct event
            if (event.data.type === 'band-saved') {
                // Assuming event.data.band contains {id_kapela, naz_kapela, ...}
    
                // Process the event to add the new band to 'addedBands' and remove it from 'unknownBands'
                setAddedBands((currentAddedBands) => [
                    ...currentAddedBands,
                    {
                        id: event.data.band.id_kapela,
                        name: event.data.band.naz_kapela,
                        displayName: event.data.band.naz_kapela, // Adjust according to your data structure
                    },
                ]);
    
                setUnknownBands((currentUnknownBands) =>
                    currentUnknownBands.filter((band) => band.name.toLowerCase() !== event.data.band.naz_kapela.toLowerCase())
                );
                setBandsIds((currentBandsIds) => [...currentBandsIds, event.data.band.id_kapela]);
            }
        };
    
        // Add event listener
        window.addEventListener('message', handleMessage);
    
        // Cleanup function to remove event listener
        return () => window.removeEventListener('message', handleMessage);
    }, []);
    
    return (
        <Container className="centered">
            <Container className="column-down">
                <Typography variant="h6" className="centered">
                    {t('elements.action-meta-admin.headline')}
                </Typography>
                    <Autocomplete
                        className="dropdown"
                        size="small"
                        options={dropdownEntries}
                        getOptionLabel={(option) => option.displayName}
                        value={selectedDropdownEntry}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t('elements.action-meta-admin.choose-action-meta')}
                                size="small"
                                variant="outlined"
                            />
                        )}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={option.id}>
                                    <Typography variant="body1">{option.displayName}</Typography>
                                </li>
                            );
                        }}
                        noOptionsText={t('elements.action-meta-admin.no-options')}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={handleActionMetaAutocompleteChange}
                    />
                        {selectedDropdownEntry && selectedActionMeta && (
                            <>
                            <Container className="component-container">
                                <Typography variant="h6" className="centered">
                                    {t('elements.action-meta-admin.action-meta-details')}
                                </Typography>
                                <Container className="column-down">
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.name'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedActionMeta.name}</span>
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.place'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedActionMeta.place_name}</span>
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.start-date'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedActionMeta.start_date}</span>
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.start-time'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedActionMeta.start_time}</span>
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.end-date'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedActionMeta.end_date}</span>
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.end-time'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedActionMeta.end_time}</span>
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.price'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedActionMeta.price_currency}</span>
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.url'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedActionMeta.url}</span>
                                    </div>
                                </Container>

                            </Container>
                            { actionNotKnown && (
                                <Container className="component-container">
                                    <Typography variant="h6">
                                        {t('elements.action-meta-admin.action-not-known')}
                                    </Typography>
                                </Container>
                            ) }
                            { !actionNotKnown && (
                                <Container className="component-container">
                                    <Typography variant="h6" className="centered">
                                        {t('elements.action-meta-admin.saved-bands')}
                                    </Typography>
                                    { generateBandList('known') }

                                </Container>
                            )}
                            { addedBandsVisible && (
                                <Container className="component-container">
                                    <Typography variant="h6" className="centered">
                                        {t('elements.action-meta-admin.added-bands')}
                                    </Typography>
                                    { generateBandList('added') }

                                </Container>
                            )}
                            { ignoredBandsVisible && (
                                <Container className="component-container">
                                    <Typography variant="h6" className="centered">
                                        {t('elements.action-meta-admin.ignored-concerts')}
                                    </Typography>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.ignored-concerts-known'
                                                )}
                                                :
                                            </strong>
                                        { generateBandList('ignored_known') }
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.ignored-concerts-unknown'
                                                )}
                                                :
                                            </strong>
                                        { generateBandList('ignored_unknown') }
                                    </div>
                                </Container>
                            )}
                            { otherBandsVisible && (
                                <Container className="component-container">
                                    <Typography variant="h6" className="centered">
                                        {t('elements.action-meta-admin.other-bands')}
                                    </Typography>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.other-bands-known'
                                                )}
                                                :
                                            </strong>{' '}
                                            <span>{selectedAction?.dalkap_akce || t('elements.forms.empty')}</span>
                                    </div>
                                    <div className="data-row">
                                            <strong>
                                                {t(
                                                    'elements.action-meta-admin.other-bands-unknown'
                                                )}
                                                :
                                            </strong>
                                    </div>
                                    { generateBandList('other') }
                                </Container>
                            )}
                            { unknownBandsVisible && (
                                <Container className="component-container">
                                    <Typography variant="h6" className="centered">
                                        {t('elements.action-meta-admin.unknown-bands')}
                                    </Typography>
                                    { generateBandList('unknown') }

                                </Container>
                            )}
                            <Button onClick={() => loadNewData()} type="button" formNoValidate>
                                { actionNotKnown ? (
                                    t('elements.action-meta-admin.load-data')
                                ) : (
                                    t('elements.action-meta-admin.load-new-data')
                                )}
                            </Button>
                            <Button onClick={() => openTranslations()} type="button" formNoValidate>
                                { t('elements.action-meta-admin.translations') }
                            </Button>
                            { showSaveButton && (
                                <Button onClick={() => saveForm()} type="button" formNoValidate>
                                    { t('elements.forms.save') }
                                </Button>)
                            }
                            </>
                        )}
                    <Dialog
                        open={dataLoading}
                        aria-labelledby="dataLoading"
                    >
                        <DialogTitle id="dataLoading">
                            {t('elements.action-meta-admin.loading')}...
                        </DialogTitle>
                        <Box sx={{ width: '100%', height: '75px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress />
                        </Box>
                    </Dialog>
                    <Dialog
                        open={isErrorDialogOpen}
                        onClose={() => setIsErrorDialogOpen(false)}
                        aria-labelledby="error-dialog-title"
                        aria-describedby="error-dialog-description"
                    >
                        <DialogTitle id="error-dialog-title">
                            <div className="error-message">
                            { t('elements.forms.error-title')  }
                            </div>
                            <div>
                                {errorMessage }
                            </div>
                        </DialogTitle>
                        <DialogActions>
                        <Button onClick={() => {
                            setIsErrorDialogOpen(false);  // Close the error dialog
                            setErrorMessage('');        // Reset the error message
                            }}>
                                 {t('elements.forms.ok')}
                            </Button>
                        </DialogActions>
                </Dialog>
            </Container>
        </Container>
    );   
}

function ActionMeta(): JSX.Element {

    return (
        <div className="page-content">
            <BackgroundHandler />
            <NavBar />
            <ActionMetaContent />
            <Footer />
        </div>
    );
}

export default ActionMeta;
