import i18next from 'i18next';

// Helper function to check if a string is an ISO date
export function isISODate(str: string): boolean {
    const isoDatePattern = /^\d{4}-\d{2}-\d{2}$/;
    return isoDatePattern.test(str);
}

// Helper function to format dates
export function formatDate(isoDateString: string): string {
    const date = new Date(isoDateString);
    const locale = i18next.language;
    return date.toLocaleDateString(locale, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    });
}

export function isValidTime(time : string): boolean {
    const regex = /^(?:[01]\d|2[0-3]):[0-5]\d:[0-5]\d$/;
    return regex.test(time);
}
